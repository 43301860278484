import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Button,
  Row,
  Col,
  InputGroup,
  FormGroup,
} from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import InputMask from "react-input-mask";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUtensils } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';


const backendUri = process.env.REACT_APP_BACKEND_DOMAIN;
const AddModal = (props) => {
  const [adminName, setName] = useState("");
  const [adminContact, setContact] = useState("");
  const [adminEmail, setEmail] = useState("");
  const [adminPassword, setPassword] = useState("");
  const [selectedRestaurant, setSelectedRestaurant] = useState(null);
  const [restaurantList, setRestaurantList] = useState([]);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handlePhoneNoChange = (e) => {
    const inputPhoneNo = e.target.value;
    const cleanedPhoneNo = inputPhoneNo.replace(/[ -]/g, "");
    setContact(cleanedPhoneNo);
  };

  useEffect(() => {
    fetchRestaurantDetails();
  }, [props.showForm]);

  const fetchRestaurantDetails = () => {
    fetch(`${backendUri}/api/v0/super-admin/restaurants`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${props.Token}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data && data.length > 0) {
          setRestaurantList(data);
        } else {
          setRestaurantList([]);
        }
      })
      .catch((err) => {
        console.error(err.message);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    let isTimedOut = false;

    const timeout = setTimeout(() => {
      isTimedOut = true;
      setIsSubmitting(false);
      toast.error("Request timed out. Please try again later.", {
        containerId: "error-container",
      });
      props.handleCloseForm();
    }, 5000);

    fetch(`${backendUri}/api/v0/super-admin/admins`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${props.Token}`,
      },
      body: JSON.stringify({
        adminName,
        adminContact,
        adminEmail,
        adminPassword,
        restaurantId: `${selectedRestaurant}`,
        isSuper: isSuperAdmin,
      }),
    })
      .then((res) => {
        clearTimeout(timeout);
        return res.json();
      })
      .then((res) => {
        console.log(res.error);
        if (res.error) {
          toast.error(res.message, {
            containerId: "error-container",
          });
          props.handleCloseForm();
        } else {
          toast.success(res.message, {
            containerId: "success-container",
          });

          // Clear variable after successful created
          setName("");
          setContact("");
          setEmail("");
          setPassword("");
          setSelectedRestaurant("");
          setIsSuperAdmin(false);

          props.fetchData(props.Token);
          props.handleCloseForm();
        }
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleRestaurantChange = (selectedOption) => {
    const cleanedRestaurantId = selectedOption.value._id;
    setSelectedRestaurant(cleanedRestaurantId);
  };

  return (
    <>
      <Modal show={props.showForm} onHide={props.handleCloseForm} size="lg">
        <Modal.Header closeButton>
          <Modal.Title> Create New Restaurant Admin </Modal.Title>
        </Modal.Header>
        <Form style={{ margin: 3 }} onSubmit={(e) => handleSubmit(e)}>
          <Modal.Body>
            <Row className="mb-3">
              <Form.Group controlId="addAdminName">
                <Form.Label>Admin Name*</Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <i className="fa fa-user" />
                  </InputGroup.Text>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Enter Restaurant Admin Name"
                    value={adminName}
                    onChange={(e) => {
                      if (e.target.value.length <= 10) {
                        setName(e.target.value);
                      }
                    }}
                  />
                </InputGroup>
                <div className="text-muted" style={{ fontSize: '10px' }}>
                  Maximum 10 characters for restaurant admin name
                </div>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group controlId="addAdminEmail">
                <Form.Label>Admin Email*</Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <i className="fa fa-inbox" />
                  </InputGroup.Text>
                  <Form.Control
                    required
                    type="email"
                    placeholder="Enter Restaurant Admin Email"
                    value={adminEmail}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </InputGroup>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Admin Contact Number</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>+60</InputGroup.Text>
                    <InputMask
                      id="addAdminContactNumber"
                      className="form-control"
                      placeholder="Enter Restaurant Admin Contact"
                      mask="99-99999999999999999999999"
                      maskChar={null}
                      value={adminContact}
                      onChange={handlePhoneNoChange}
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Row>
                  <Form.Group controlId="AddRestaurant">
                    {isSuperAdmin ? (
                      <Form.Label>Restaurant Name</Form.Label>
                    ) : (
                      <Form.Label>Restaurant Name*</Form.Label>
                    )}
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUtensils} />
                      </InputGroup.Text>
                      <Select
                        id="AddRestaurant"
                        type="text"
                        required={!isSuperAdmin}
                        value={selectedRestaurant  ? { _id: selectedRestaurant, label: restaurantList.find(r => r._id === selectedRestaurant)?.restaurantName } : null}
                        options={restaurantList.map((restaurant) => ({
                          value: { _id: restaurant._id },
                          label: restaurant.restaurantName,
                        }))}
                        onChange={(selectedOption) => {
                          handleRestaurantChange(selectedOption);
                        }}
                        isDisabled={isSuperAdmin}
                        placeholder="Choose..."
                        isSearchable
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: '0 5px 5px 0',
                          colors: {
                            ...theme.colors,
                            primary: '#d18615',
                          },
                        })}
                        styles={{
                          control: (provided, state) => ({
                            ...provided,
                            width: 324,
                            border: state.isFocused ? '#d18615' : '',
                            boxShadow: state.isFocused ? '0 0 0 0.075rem #d18615' : 'none',
                            transition: 'border-color 0.2s ease',
                            '&:hover': {
                              borderColor: '#d18615',
                            },
                          }),
                          menu: (provided) => ({
                            ...provided,
                            borderBottomLeftRadius: '5px',
                            borderBottomRightRadius: '5px',
                          }),
                          input: (provided) => ({
                            ...provided,
                            cursor: 'text',
                          }),
                        }}
                      />
                    </InputGroup>
                  </Form.Group>
                </Row>
              </Col>
            </Row>
            <Row className="mb-4">
              <Form.Group controlId="addAdminPassword">
                <Form.Label>Admin Password*</Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <i className="fa fa-lock" />
                  </InputGroup.Text>

                  <Form.Control
                    required
                    type="text"
                    placeholder="Enter Restaurant Admin Password"
                    value={adminPassword}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                  />
                </InputGroup>
              </Form.Group>
            </Row>
            <Row className="mb-4">
              <FormGroup controlId="isSuperCheck">
                <Form.Check
                  label="Is Super Admin?"
                  checked={isSuperAdmin}
                  onChange={() => setIsSuperAdmin(!isSuperAdmin)}
                />
              </FormGroup>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="soft-primary" type="submit" id="addSubmitBtn" disabled={isSubmitting}>
              {isSubmitting ? (
                <>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  <span className="visually-hidden">Submitting...</span>
                </>
              ) : (
                "Submit"
              )}
            </Button>
            <Button
              variant="soft-secondary"
              onClick={props.handleCloseForm}
              id="closeCreateFormBtn"
            >
              Close
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default AddModal;
