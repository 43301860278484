import React, { useState, useEffect } from "react";
import { Button, Col, Form, Row, Spinner, InputGroup } from "react-bootstrap";
import InputMask from "react-input-mask";
import { ToastContainer } from "react-toastify";

import useAdminService from "../../../services/adminService";


const AdminInfo = () => {
  const [adminName, setName] = useState("");
  const [adminContact, setContact] = useState("");
  const [adminEmail, setEmail] = useState("");
  const [isSetPassword, setIsSetPassword] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const {
    fetchProfileInfo,
    adminInfo,
    updateProfileInfo,
    updateAdminProfilePassword,
    success,
    isSubmitting,
    clearIsSubmitting,
    clearSuccess,
  } = useAdminService();

  useEffect(() => {
    fetchProfileInfo();
  }, []);

  const resetPasswordForm = () => {
    setOldPassword("");
    setNewPassword("");
    setConfirmPassword("");
  };

  const handlePhoneNoChange = (e) => {
    const inputPhoneNo = e.target.value;
    const cleanedPhoneNo = inputPhoneNo.replace(/[ -]/g, "");
    setContact(cleanedPhoneNo);
  };

  // Function to handle password change
  const handleChangePassword = () => {
    // Add functionality for changing password
    setIsSetPassword((prevState) => !prevState);
    resetPasswordForm();
  };

  const handleSubmitProfileInfo = (e) => {
    e.preventDefault();

    const submitData = {
      adminName,
      adminContact,
      adminEmail,
    };

    updateProfileInfo(submitData);
  };

  const handleSubmitEditPassword = (e) => {
    e.preventDefault();
    handleChangePassword();
    const submitData = {
      oldPassword,
      newPassword,
      confirmPassword,
    };

    updateAdminProfilePassword(submitData);
  };

  useEffect(() => {
    setName(adminInfo.adminName);
    setContact(adminInfo.adminContact);
    setEmail(adminInfo.adminEmail);
  }, [adminInfo]);

  useEffect(() => {
    clearIsSubmitting();
    clearSuccess();
    resetPasswordForm();
  }, [success]);
  return (
    <>
      <div className="title-lg fs-4">
        <span>Admin Profile Settings</span>
      </div>
      {adminInfo ? (
        <>
          <Form onSubmit={handleSubmitProfileInfo}>
            <Row className="gx-3">
              <Col sm={12}>
                <Form.Group className="mb-3">
                  <Form.Label>Username</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <i className="fa fa-user" />
                    </InputGroup.Text>
                    <Form.Control
                      id="username"
                      type="text"
                      value={adminName}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      required
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
            <Row className="gx-3">
              <Col sm={12}>
                <Form.Group className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <i className="fa fa-inbox" />
                    </InputGroup.Text>
                    <Form.Control
                      id="email"
                      type="text"
                      value={adminEmail}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      required
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
            <Row className="gx-3">
              <Col sm={12}>
                <Form.Group className="mb-3">
                  <Form.Label>Contact Number</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>+60</InputGroup.Text>
                    <InputMask
                      id="contactNumber"
                      className="form-control"
                      mask="99-99999999999999999999999"
                      maskChar={null}
                      value={adminContact}
                      onChange={handlePhoneNoChange}
                      required
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>

            <Row className="mt-3 mb-3">
              <Col>
                <Button type="submit" variant="primary" disabled={isSubmitting} id="saveProfileBtn">
                  {isSubmitting ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />{" "}
                      Saving Changes...
                    </>
                  ) : (
                    "Save Profile Info"
                  )}
                </Button>
                <span
                  // className="mt-3 mb-3"
                  style={{
                    display: "inline-block", // Ensure the element is displayed inline
                    width: "175px", // Set the width
                    padding: "10px", // Set the padding
                    color: "#808080", // Set the text color to the primary color
                    cursor: "pointer", // Set cursor to indicate it's clickable
                  }}
                  onClick={handleChangePassword} // Add onClick handler
                  id="changePassword"
                >
                  {isSetPassword ? "Dismiss" : "Change Password ?"}
                </span>
              </Col>
            </Row>
          </Form>
          <Form onSubmit={handleSubmitEditPassword}>
            {isSetPassword && (
              <>
                <Row className="gx-3">
                  <Col sm={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Enter Current Password</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <i className="fa fa-lock" />
                        </InputGroup.Text>
                        <Form.Control
                          type="password"
                          value={oldPassword}
                          placeholder="Enter Current Password"
                          onChange={(e) => {
                            setOldPassword(e.target.value);
                          }}
                          required
                          id="currentPassword"
                        />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="gx-3">
                  <Col sm={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Enter New Password</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <i className="fa fa-lock" />
                        </InputGroup.Text>
                        <Form.Control
                          type="password"
                          value={newPassword}
                          placeholder="Enter New Password"
                          onChange={(e) => {
                            setNewPassword(e.target.value);
                          }}
                          required
                          id="newPassword"
                        />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="gx-3">
                  <Col sm={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Confirm Password</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <i className="fa fa-lock" />
                        </InputGroup.Text>
                        <Form.Control
                          type="password"
                          value={confirmPassword}
                          placeholder="Confirm Password"
                          onChange={(e) => {
                            setConfirmPassword(e.target.value);
                          }}
                          required
                          id="confirmPassword"
                        />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mt-3 mb-3">
                  <Col>
                    <Button
                      variant="primary"
                      type="submit"
                      disabled={isSubmitting}
                      id="savePasswordBtn"
                    >
                      {isSubmitting ? (
                        <>
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />{" "}
                          Saving Changes...
                        </>
                      ) : (
                        "Save Password"
                      )}
                    </Button>
                  </Col>
                </Row>
              </>
            )}
          </Form>
        </>
      ) : (
        <div className="text-center py-30">
          <Spinner />
        </div>
      )}

      <ToastContainer
        position="top-right"
        style={{ top: "70px", right: "20px" }}
        enableMultiContainer
        containerId="toast-container-admin"
      />
    </>
  );
};

export default AdminInfo;
