import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, InputGroup, Spinner } from "react-bootstrap";
import InputMask from "react-input-mask";
import { ToastContainer, toast } from "react-toastify";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";

import useClientServices from "../../services/clientService";
import { useAuth } from "../../services/authContext";
import { auth } from "../../services/firebaseService";
import {EmailAuthProvider} from "firebase/auth"
import {
  linkWithCredential
} from "firebase/auth";


const backendUri = process.env.REACT_APP_BACKEND_DOMAIN;
const ClientInfo = () => {
  const [token, setToken] = useState(Cookies.get("token"));
  const [decodedToken, setDecodedToken] = useState(null);

  const { fetchClientInfo, clientInfo } = useClientServices();

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSetPassword, setIsSetPassword] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [emailPassword, setEmailPassword] = useState("");
  const [googleSignIn, setGoogleSignIn] = useState("");

  const { loginMethod } = useAuth();

  const handlePhoneNoChange = (e) => {
    const inputPhoneNo = e.target.value;
    const cleanedPhoneNo = inputPhoneNo.replace(/[ -]/g, "");
    setContact(cleanedPhoneNo);
  };

  const handleChangePassword = () => {
    setIsSetPassword((prevState) => !prevState);
    resetPasswordForm();
  };

  const resetPasswordForm = () => {
    setOldPassword("");
    setNewPassword("");
    setConfirmPassword("");
  };

  const handleSubmitProfileInfo = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${backendUri}/api/v0/client/clientProfile/${decodedToken.userId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          clientName: username,
          clientEmail: email,
          clientContact: contact,
        })
      })
  
      const data = await response.json();
  
      if (response.ok) {
        toast.success(data.message, {
          containerId: "toast-container-client",
        });
      } else {
        toast.error(data.message, {
          containerId: "toast-container-client",
        });
      }
          
    } catch (error) {
      console.error("Error updating admin profile information:", error);
    }
  }

  const handleSubmitEditPassword = async (e) => {
    e.preventDefault();

    try {
      auth.onAuthStateChanged(user => {
        if (user) {
          const isGoogleLinked = user.providerData.some(provider => provider.providerId === 'google.com');
          const isEmailPassword = user.providerData.some(provider => provider.providerId === 'password');
          if (isGoogleLinked === true && isEmailPassword === false) {
            const credential = EmailAuthProvider.credential(user.email, newPassword);
            linkWithCredential(user, credential)
              .then(() => {
                console.log("Email linked to Google account successfully");
              })
              .catch(error => {
                console.log("Error linking email", error);
              });
          }
        } else {
          console.log("No user is signed in.");
        }
      });
      const response = await fetch(`${backendUri}/api/v0/client/clientProfile/password/${decodedToken.userId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          oldPassword: oldPassword,
          newPassword: newPassword,
          confirmPassword: confirmPassword,
        })
      })

      const data = await response.json();
  
      if (response.ok) {
        toast.success(data.message, {
          containerId: "toast-container-client",
        });
      } else {
        toast.error(data.message, {
          containerId: "toast-container-client",
        });
      }
    } catch (error) {
      console.error("Error updating admin profile password:", error);
    }
  };

  useEffect(() => {
    if (token) {
      const decoded = jwtDecode(token);
      setDecodedToken(decoded);
    }
  }, [token]);

  useEffect(() => {
    if (decodedToken) {
      fetchClientInfo(decodedToken.userId);
    }
  }, [decodedToken]);

  useEffect(() => {
    if (clientInfo) {
      setUsername(clientInfo.clientName);
      setEmail(clientInfo.clientEmail);
      setContact(clientInfo.clientContact);
    }
  }, [clientInfo])

  useEffect(() => {
    auth.onAuthStateChanged(user => {
      if (user) {
        const isEmailPassword = user.providerData.some(provider => provider.providerId === 'password');
        setEmailPassword(isEmailPassword);
        const isGoogleSignIn = user.providerData.some(provider => provider.providerId === 'google.com');
        setGoogleSignIn(isGoogleSignIn);
      }
    });
  }, [])

  return (
    <>
      <div className="title-lg fs-4">
        <span>Client Profile</span>
      </div>
      <>
        <Form onSubmit={handleSubmitProfileInfo}>
          <Row className="gx-3">
            <Col sm={12}>
              <Form.Group className="mb-3">
                <Form.Label>Username</Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <i className="fa fa-user" />
                  </InputGroup.Text>
                  <Form.Control
                    id="username"
                    type="text"
                    value={username}
                    placeholder="Enter Username"
                    onChange={(e) => {
                      if (e.target.value.length <= 10) {
                        setUsername(e.target.value);
                      }
                    }}
                    required
                  />
                </InputGroup>
                <div className="text-muted" style={{ fontSize: '10px' }}>
                  Maximum 10 characters for username
                </div>
              </Form.Group> 
            </Col>
          </Row>
          <Row className="gx-3">
            <Col sm={12}>
              <Form.Group className="mb-3">
                <Form.Label>Email</Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <i className="fa fa-inbox" />
                  </InputGroup.Text>
                  <Form.Control
                    id="email"
                    type="text"
                    value={email}
                    placeholder="Enter Email"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    required
                    readOnly
                  />
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
          <Row className="gx-3">
            <Col sm={12}>
              <Form.Group className="mb-3">
                <Form.Label>Contact Number</Form.Label>
                <InputGroup>
                  <InputGroup.Text>+60</InputGroup.Text>
                  <InputMask
                    id="contactNumber"
                    className="form-control"
                    mask="99-99999999999999999999999"
                    maskChar={null}
                    value={contact}
                    placeholder="Enter Contact Number"
                    onChange={handlePhoneNoChange}
                    required
                  />
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mt-3 mb-3">
            <Col>
              <Button type="submit" variant="primary" disabled={isSubmitting} id="saveProfileBtn">
                {isSubmitting ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />{" "}
                      Saving Changes...
                  </>
                ) : (
                  "Save Profile Info"
                )}
              </Button>
              <span
                style={{
                  display: "inline-block",
                  width: "175px",
                  padding: "10px",
                  color: "#808080",
                  cursor: "pointer",
                }}
                onClick={handleChangePassword}
                id="changePassword"
              >
                {isSetPassword ? "Dismiss" : "Change Password ?"}
              </span>
            </Col>
          </Row>
        </Form>
        <Form onSubmit={handleSubmitEditPassword}>
          {isSetPassword && (
            <>
              {emailPassword === true && (
                <Row className="gx-3">
                  <Col sm={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Enter Current Password</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <i className="fa fa-lock" />
                        </InputGroup.Text>
                        <Form.Control
                          type="password"
                          value={oldPassword}
                          placeholder="Enter Current Password"
                          onChange={(e) => {
                            setOldPassword(e.target.value);
                          }}
                          required
                          id="currentPassword"
                        />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Row>
              )}
              <Row className="gx-3">
                <Col sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Enter New Password</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <i className="fa fa-lock" />
                      </InputGroup.Text>
                      <Form.Control
                        type="password"
                        value={newPassword}
                        placeholder="Enter New Password"
                        onChange={(e) => {
                          setNewPassword(e.target.value);
                        }}
                        required
                        id="newPassword"
                      />
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="gx-3">
                <Col sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Confirm Password</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <i className="fa fa-lock" />
                      </InputGroup.Text>
                      <Form.Control
                        type="password"
                        value={confirmPassword}
                        placeholder="Confirm Password"
                        onChange={(e) => {
                          setConfirmPassword(e.target.value);
                        }}
                        required
                        id="confirmPassword"
                      />
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mt-3 mb-3">
                <Col>
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={isSubmitting}
                    id="savePasswordBtn"
                  >
                    {isSubmitting ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />{" "}
                          Saving Changes...
                      </>
                    ) : (
                      "Save Password"
                    )}
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </Form>
      </>
      <ToastContainer
        position="top-right"
        style={{ top: "70px", right: "20px" }}
        enableMultiContainer
        containerId="toast-container-client"
      />
    </>
  )
};

export default ClientInfo;