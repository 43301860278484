import { useState, useEffect } from "react";
import { Modal, Button, Form, Row, Col, InputGroup } from "react-bootstrap";
import { toast } from "react-toastify";
import InputMask from "react-input-mask";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUtensils } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';


const backendUri = process.env.REACT_APP_BACKEND_DOMAIN;
const EditFormModal = (props) => {
  const [restaurantList, setRestaurantList] = useState([]);
  const [updateRestaurantAdmin, setRestaurantAdmin] = useState({
    adminName: "",
    adminEmail: "",
    adminContact: "",
    selectedRestaurant: "",
    isSuper: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [adminPassword, setAdminPassword] = useState("");

  const handlePhoneNoChange = (e) => {
    const inputPhoneNo = e.target.value;
    const cleanedPhoneNo = inputPhoneNo.replace(/[ -]/g, "");
    handleInputChange({
      target: {
        name: e.target.name,
        value: cleanedPhoneNo,
      },
    });
  };

  useEffect(() => {
    fetchRestaurantDetails();
    fetchAdminData(props.adminId);
    setAdminPassword("");
  }, [props.showForm]);

  // GET restaurant detials
  const fetchRestaurantDetails = () => {
    fetch(`${backendUri}/api/v0/super-admin/restaurants`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${props.Token}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data && data.length > 0) {
          setRestaurantList(data);
        } else {
          setRestaurantList([]);
        }
      })
      .catch((err) => {
        console.error(err.message);
      });
  };

  // GET Restaurant Admin Data
  const fetchAdminData = async (id) => {
    try {
      const response = await fetch(
        `${backendUri}/api/v0/super-admin/admins/${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${props.Token}`,
          },
        }
      );
      if (response.ok) {
        const data = await response.json();

        setRestaurantAdmin({
          adminName: data.adminName || "",
          adminEmail: data.adminEmail || "",
          adminContact: data.adminContact || "",
          selectedRestaurant: data.restaurantId || "",
          isSuper: data.isSuper,
        });
      } else {
        console.error("Fail to fetch restaurant admin data");
      }
    } catch (error) {
      console.error("Error fetching restaurant admin data ");
    }
  };

  // Update Restaurant Admin Data
  const handleUpdate = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    let isTimedOut = false;

    const timeout = setTimeout(() => {
      isTimedOut = true;
      setIsSubmitting(false);
      toast.error("Request timed out. Please try again later.", {
        containerId: "error-container",
      });
      props.handleCloseForm();
    }, 5000);

    fetch(
      `${backendUri}/api/v0/super-admin/admins/${props.adminId}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.Token}`,
        },
        body: JSON.stringify({
          adminName: updateRestaurantAdmin.adminName,
          adminContact: updateRestaurantAdmin.adminContact,
          adminEmail: updateRestaurantAdmin.adminEmail,
          adminPassword: adminPassword,
          restaurantId: updateRestaurantAdmin.selectedRestaurant,
          isSuper: updateRestaurantAdmin.isSuper,
        }),
      }
    )
      .then((res) => {
        clearTimeout(timeout);
        return res.json();
      })
      .then((res) => {
        if (res.error) {
          toast.error(`${res.message}`, {
            containerId: "error-container",
          });
        } else {
          toast.success(res.message, {
            containerId: "success-container",
          });
          props.handleCloseForm();
        }
        props.fetchData(props.Token);
      })
      .catch((error) => {
        console.error("Error updating restaurant admin : ", error.message);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleInputChange = (e) => {
    setRestaurantAdmin({
      ...updateRestaurantAdmin,
      [e.target.name]: e.target.value,
    });
  };

  const handleRestaurantChange = (selectedRestaurant) => {
    setRestaurantAdmin((prevState) => ({
      ...prevState,
      selectedRestaurant: selectedRestaurant.value._id,
    }));
  };

  return (
    <>
      <Modal show={props.showForm} onHide={props.handleCloseForm} size="lg">
        <Modal.Header closeButton>
          <Modal.Title> Edit Restaurant Admin </Modal.Title>
        </Modal.Header>
        <Form style={{ margin: 3 }} onSubmit={(e) => handleUpdate(e)}>
          <Modal.Body>
            <Row className="mb-3">
              <Form.Group controlId="addAdminName">
                <Form.Label>Admin Name*</Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <i className="fa fa-user" />
                  </InputGroup.Text>
                  <Form.Control
                    required
                    type="text"
                    name="adminName"
                    value={updateRestaurantAdmin.adminName || ""}
                    onChange={(e) => {
                      if (e.target.value.length <= 10) {
                        handleInputChange(e);
                      }
                    }}
                  />
                </InputGroup>
                <div className="text-muted" style={{ fontSize: '10px' }}>
                  Maximum 10 characters for restaurant admin name
                </div>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group controlId="addAdminEmail">
                <Form.Label>Admin Email*</Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <i className="fa fa-inbox" />
                  </InputGroup.Text>
                  <Form.Control
                    required
                    type="email"
                    name="adminEmail"
                    value={updateRestaurantAdmin.adminEmail || ""}
                    onChange={handleInputChange}
                  />
                </InputGroup>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Admin Contact Number</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>+60</InputGroup.Text>
                    <InputMask
                      id="addAdminContactNumber"
                      className="form-control"
                      name="adminContact"
                      mask="99-99999999999999999999999"
                      maskChar={null}
                      value={updateRestaurantAdmin.adminContact || ""}
                      onChange={handlePhoneNoChange}
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Row>
                  <Form.Group controlId="AddRestaurant">
                    {updateRestaurantAdmin.isSuper === true ? (
                      <Form.Label>Restaurant Name</Form.Label>
                    ) : (
                      <Form.Label>Restaurant Name*</Form.Label>
                    )}
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUtensils} />
                      </InputGroup.Text>
                      <Select
                        id="#AddRestaurant"
                        type="text"
                        required
                        value={updateRestaurantAdmin.selectedRestaurant ? { _id: updateRestaurantAdmin.selectedRestaurant, label: restaurantList.find(r => r._id === updateRestaurantAdmin.selectedRestaurant)?.restaurantName } : null}
                        options={restaurantList.map((restaurant) => ({
                          value: { _id: restaurant._id },
                          label: restaurant.restaurantName,
                        }))}
                        onChange={(selectedRestaurant) => {
                          handleRestaurantChange(selectedRestaurant);
                        }}
                        isDisabled={updateRestaurantAdmin.isSuper}
                        placeholder="Choose..."
                        isSearchable
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: '0 5px 5px 0',
                          colors: {
                            ...theme.colors,
                            primary: '#d18615',
                          },
                        })}
                        styles={{
                          control: (provided, state) => ({
                            ...provided,
                            width: 324,
                            border: state.isFocused ? '#d18615' : '',
                            boxShadow: state.isFocused ? '0 0 0 0.075rem #d18615' : 'none',
                            transition: 'border-color 0.2s ease',
                            '&:hover': {
                              borderColor: '#d18615',
                            },
                          }),
                          menu: (provided) => ({
                            ...provided,
                            borderBottomLeftRadius: '5px',
                            borderBottomRightRadius: '5px',
                          }),
                          input: (provided) => ({
                            ...provided,
                            cursor: 'text',
                          }),
                        }}
                      />
                    </InputGroup>
                  </Form.Group>
                </Row>
              </Col>
            </Row>
            <Row className="mb-3">
              <Form.Group controlId="addAdminPassword">
                <Form.Label>
                  Admin Password (Leave it blank to remain unchanged)
                </Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <i className="fa fa-lock" />
                  </InputGroup.Text>

                  <Form.Control
                    type="text"
                    value={adminPassword}
                    onChange={(e) => {
                      setAdminPassword(e.target.value);
                    }}
                  />
                </InputGroup>
              </Form.Group>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="soft-primary"
              id="addSubmitBtn"
              type="submit"
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  <span className="visually-hidden">Submitting...</span>
                </>
              ) : (
                "Update"
              )}
            </Button>
            <Button
              variant="soft-secondary"
              onClick={props.handleCloseForm}
              id="closeCreateFormBtn"
            >
              Close
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default EditFormModal;
