import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import { auth } from "../../services/firebaseService";
import { linkWithPopup, GoogleAuthProvider, unlink } from "firebase/auth";


const AccountManagement = () => {
  const [emailPassword, setEmailPassword] = useState("");
  const [googleSignIn, setGoogleSignIn] = useState("");
  const provider = new GoogleAuthProvider();
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const buttonStyle = {
    padding: '5px 20px',
    border: '0px',
    display: 'block',
    gap: '10px',
    borderRadius: '60px',
    color: '#2d3748',
    backgroundColor: '#edf2f7',
    transition: 'all 0.15s ease-in-out',
    width: '25%'
  };
  
  const imgStyle = {width: '24px', height: '24px', marginRight: '10px'};

  const handleLinkWithGoogle = async () => {
    try {
      const user = auth.currentUser;
  
      if (!user) {
        console.log("No user is signed in.");
        return;
      }
  
      if (emailPassword === true && googleSignIn === false) {
        await linkWithPopup(user, provider);
        toast.success("Google account linked successfully", {
          containerId: "toast-container",
        });
        await delay(3000);
        window.location.reload();
      } else {
        toast.error("User already linked with Google or not signed in with email or password.", {
          containerId: "toast-container",
        });
      }
    } catch (error) {
      toast.error(`Error linking Google account. ${error}`, {
        containerId: "toast-container",
      });
    }
  };

  const handleUnlinkWithGoogle = async () => {
    try {
      const user = auth.currentUser;

      if (!user) {
        console.log("No user is signed in.");
        return;
      }

      if (emailPassword === true && googleSignIn === true) {
        await unlink(user, 'google.com');
        toast.success("Google account unlinked successfully", {
          containerId: "toast-container",
        });
        await delay(3000);
        window.location.reload();
      } else {
        toast.error("User already unlinked with Google or not signed in with email or password.", {
          containerId: "toast-container",
        });
      }
    } catch (error) {
      toast.error(`Error unlinking Google account. ${error}`, {
        containerId: "toast-container",
      });
    }
  };

  useEffect(() => {
    auth.onAuthStateChanged(user => {
      if (user) {
        const isEmailPassword = user.providerData.some(provider => provider.providerId === 'password');
        setEmailPassword(isEmailPassword);
        const isGoogleSignIn = user.providerData.some(provider => provider.providerId === 'google.com');
        setGoogleSignIn(isGoogleSignIn);
      }
    });
  }, [])

  return (
    <>
      <div className="title-lg fs-4">
        <span>Account Management</span>
      </div>
      <>
        <div>
          <Row className="gx-3 mt-4">
            <Col sm={12}>
              <Form.Group className="mb-3">
                <Form.Label>Google</Form.Label>
                <div>
                  {googleSignIn === true ? (
                    <button style={buttonStyle} onClick={handleUnlinkWithGoogle}>
                      <img style={imgStyle} src="https://www.svgrepo.com/show/475656/google-color.svg" loading="lazy" alt="google logo" />
                      <span>Unlink with Google</span>
                    </button>
                  ) : (
                    <button style={buttonStyle} onClick={handleLinkWithGoogle}>
                      <img style={imgStyle} src="https://www.svgrepo.com/show/475656/google-color.svg" loading="lazy" alt="google logo" />
                      <span>Link with Google</span>
                    </button>
                  )}
                </div>
              </Form.Group>
            </Col>
          </Row>
        </div>
      </>
      <ToastContainer
        position="top-right"
        style={{ top: "70px", right: "20px" }}
        enableMultiContainer
        containerId="toast-container"
      />
    </>
  )
};

export default AccountManagement;