import React from "react";
import {
  AlignLeft,
  LogOut,
} from "react-feather";
import {
  Button,
  Container,
  Dropdown,
  Nav,
  Navbar,
} from "react-bootstrap";
import { connect } from "react-redux";
import { useHistory, Link, useRouteMatch } from "react-router-dom";
import * as Icons from "tabler-icons-react";
import { toggleCollapsedNav } from "../../redux/action/Theme";

import termsNConditionsClient from "../../assets/konfem_file/KONFEM USER TERMS OF USE.pdf";
import termsNConditionsPartner from "../../assets/konfem_file/KONFEM PARTNER Terms of Service.pdf";
import logo from "../../assets/dist/img/logo-nav.png";
import { useAuth } from "../../services/authContext";
import Notifications from "./Notifications";


const konfemHPNo = process.env.REACT_APP_KONFEM_HP_NO;

const TopNav = ({ navCollapsed, toggleCollapsedNav }) => {
  const history = useHistory();
  const { authenticated, logout, user } = useAuth();
  const appRoute = useRouteMatch("/app");

  const handleLogout = async () => {
    await logout();
    history.push("/");
  };

  return (
    <Navbar className="hk-navbar navbar-light fixed-top">
      <Container className="container-fluid">
        {!appRoute && (
          <Navbar.Brand href="/">
            <img className="img-fluid" width={"140px"} src={logo} alt="logo" />
          </Navbar.Brand>
        )}
        {appRoute && (
          <div className="nav-start-wrap">
            <Button
              variant="flush-dark"
              onClick={() => toggleCollapsedNav(!navCollapsed)}
              className="btn-icon btn-rounded flush-soft-hover navbar-toggle d-xl-none"
            >
              <span className="icon">
                <span className="feather-icon">
                  <AlignLeft />
                </span>
              </span>
            </Button>
          </div>
        )}

        <Nav className="navbar-nav flex-row me-1">
          <Notifications />

          {authenticated ? (
            <>
              <Nav.Item className="w-100">
                {user.role === 'client' && (
                  <Nav.Link as={Link} to="/app/favourites" className="avatar avatar-rounded avatar-xs font-monospace d-flex flex-column justify-content-center w-100">
                    <span className="d-flex justify-content-center w-100"><Icons.ClipboardHeart /></span>
                    <span className="d-flex justify-content-center w-100" style={{ fontSize: '0.56rem' }}>Favourites</span>
                  </Nav.Link>
                )}
              </Nav.Item>

              <Nav.Item className="w-100">
                <Nav.Link as={Link} to={!appRoute ? "/app/dashboard" : "/"} className="avatar avatar-rounded avatar-xs font-monospace d-flex flex-column justify-content-center w-100">
                  <span className="d-flex justify-content-center w-100">{!appRoute ? < Icons.FileDescription /> : < Icons.Home />}</span>
                  <span className="d-flex justify-content-center w-100" style={{ fontSize: '0.56rem' }}>{!appRoute ? "Manage" : "Home"}</span>
                </Nav.Link>
              </Nav.Item>

              <Nav.Item className="w-100">
                <Dropdown className="ps-2">
                  <Dropdown.Toggle as={Link} to="#" className="no-caret avatar avatar-rounded avatar-xs font-monospace d-flex flex-column justify-content-center w-100">
                    <span className="d-flex justify-content-center w-100"><Icons.UserCircle /></span>
                    <span className="d-flex justify-content-center w-100" style={{ fontSize: '0.56rem' }}>{user.name}</span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu align="end" className="font-monospace">
                    <div className="d-flex flex-column p-2 media media-body">
                      <div className="d-block fw-medium text-dark fs-7">{user.name}</div>
                      <div className="fs-8">{user.email}</div>
                    </div>
                    <Dropdown.Divider as="div" />
                    {user.role === 'client' && (
                      <>
                        <Dropdown.Item as={Link} to={"/app/profile"}>
                          <span id="ClientProfile" className="d-block fw-medium text-dark fs-7">Edit Profile</span>
                        </Dropdown.Item>
                        <Dropdown.Divider as="div" />
                      </>
                    )}
                    <Dropdown.Item className="d-block fw-medium text-dark fs-7" onClick={() => window.open(user.role === 'client' ? termsNConditionsClient : termsNConditionsPartner)}>
                      Terms &amp; Conditions
                    </Dropdown.Item>
                    <Dropdown.Item className="d-block fw-medium text-dark fs-7" onClick={() => window.open(`https://wa.me/+6${konfemHPNo}`, "_blank")} >
                      Help &amp; Support
                    </Dropdown.Item>
                    <Dropdown.Divider as="div" />
                    <Dropdown.Item className="d-block fw-medium text-dark fs-7" onClick={handleLogout} id="logoutButton">
                      <span className="dropdown-icon feather-icon">
                        <LogOut />
                      </span>
                      <span id="logoutBtn">Logout</span>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                {/* </div> */}
              </Nav.Item>
            </>
          ) : (
            <>
              <Nav.Item className="w-100">
                <Button 
                  className="btn-flush-primary font-monospace p-2 fs-7 text-decoration-underline w-100" 
                  id="signUpButton" 
                  as={Link} 
                  to="/auth/customer-signup"
                >
                  SignUp
                </Button>
              </Nav.Item>
              <Nav.Item className="w-100">
                <Dropdown>
                  <Dropdown.Toggle as={Link} to="#" className="no-caret">
                    <Button className="btn-flush-primary font-monospace p-2 fs-7 text-decoration-underline w-100" id="loginDropDown">
                      Login
                    </Button>
                  </Dropdown.Toggle>
                  <Dropdown.Menu align="end" className="font-monospace fs-7">
                    <Dropdown.Item as={Link} to="/auth/">
                      <span id="userLogin">Login as Foodie</span>
                    </Dropdown.Item>
                    <Dropdown.Item as={Link} to="/auth/partner">
                      <span id="adminLogin">Login as Partner</span>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Nav.Item>
            </>
          )}
        </Nav>
      </Container>
    </Navbar>
  );
};

const mapStateToProps = ({ theme }) => {
  const { navCollapsed } = theme;
  return { navCollapsed };
};

export default connect(mapStateToProps, { toggleCollapsedNav })(TopNav);
