import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import ClassicRoutes from "./routes/Classic";
import AuthRoutes from "./routes/AuthRoutes";
import PublicRoutes from "./routes/PublicRoutes";
import "bootstrap/js/src/collapse";
import ScrollToTop from "./utils/ScrollToTop";
import { AuthProvider, useAuth } from "./services/authContext";

const AuthenticatedRoute = ({ component: Component, ...rest }) => {
  const { authenticated, loading } = useAuth();

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <Spinner animation="border" size="lg" />
      </div>
    );
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        authenticated ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
};

const AppContent = () => {
  return (
    <>
      <ScrollToTop>
        <Switch>
          {/* Auth */}
          <Route path="/auth" render={(props) => <AuthRoutes {...props} />} />
          {/* Layouts */}
          <AuthenticatedRoute path="/app" component={ClassicRoutes} />
          <Route path="/" render={(props) => <PublicRoutes {...props} />} />
        </Switch>
      </ScrollToTop>
    </>
  );
};

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <AppContent />
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
