import Cookies from "js-cookie";

const themeColorCode = "#009B84";
const redThemeColorCode = "#FF0000";
// eslint-disable-next-line no-undef
const backendUri = process.env.REACT_APP_BACKEND_DOMAIN;
export const getEvents = async () => {
  try {
    const accessToken = Cookies.get("token");
    const event = await fetch(`${backendUri}/api/v0/admin/events/get-status/konfemed`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const eventData = await event.json();
    let formattedEvent;
    Array.isArray(eventData)? formattedEvent = eventData.map((data) => ({
      id: data._id,
      backgroundColor: themeColorCode,
      borderColor: themeColorCode,
      adminId: data.adminId,
      start: new Date(`${data.eventDate} ${data.eventTime}`), 
      time: data.eventTime,
      eventTime: data.time,
      closingBefore: data.closingBefore,
      title: data.title,
      eventName: data.eventName,
      noOfPax: data.noOfPax,
      noOfTable: data.noOfTable,
      package: data.package,
      packageId: data.packageId,
      phoneNo: data.phoneNo,
      remark: data.remark,
      status: data.status,
    })) : formattedEvent =  eventData.message;

    return formattedEvent;
  } catch (err) {
    console.error("Error: ", err.message);
  }
};

export const getRejectedEvents = async () => {
  try {
    const accessToken = Cookies.get("token");
    const event = await fetch(`${backendUri}/api/v0/admin/events/get-status/rejected`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const eventData = await event.json();
    let formattedEvent;

    
    Array.isArray(eventData)? formattedEvent = eventData.map((data) => ({
      id: data._id,
      backgroundColor: redThemeColorCode,
      borderColor: redThemeColorCode,
      adminId: data.adminId,
      start: new Date(`${data.eventDate} ${data.eventTime}`), 
      time: data.eventTime,
      closingBefore: data.closingBefore,
      title: data.title,
      eventName: data.eventName,
      eventTime: data.time,
      noOfPax: data.noOfPax,
      noOfTable: data.noOfTable,
      package: data.package,
      packageId: data.packageId,
      phoneNo: data.phoneNo,
      remark: data.remark,
      status: data.status
    })) : formattedEvent = eventData.message;

    return formattedEvent;
  } catch (err) {
    console.error("Error: ", err.message);
  }
};
