import React, { useState, useEffect } from "react";
import { Modal } from 'react-bootstrap';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-thumbnail.css';


const Photos = ({ restaurantInfo }) => {
  const [photoFiles, setPhotoFiles] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);

  useEffect(() => {
    setPhotoFiles(restaurantInfo.photoFiles);
  }, [restaurantInfo]);

  const openModal = (index) => {
    setCurrentPhotoIndex(index);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const nextPhoto = () => {
    setCurrentPhotoIndex((prevIndex) => (prevIndex + 1) % photoFiles.length);
  };

  const prevPhoto = () => {
    setCurrentPhotoIndex((prevIndex) => (prevIndex - 1 + photoFiles.length) % photoFiles.length);
  };


  return (
    <div>
      {Array.isArray(photoFiles) && photoFiles.length > 0 ? (
        <div style={{ height: '200px', overflow: 'auto', marginTop: '20px' }}>
          <div className="photo-grid" style={{ display: 'flex', overflowX: 'auto', gap: '10px' }}>
            {photoFiles.map((photo, index) => (
              <img
                key={index}
                className="img-fluid rounded-5"
                src={photo}
                alt={`Restaurant Photo ${index + 1}`}
                style={{ width: '200px', height: '200px', objectFit: 'cover', cursor: 'pointer' }}
                onClick={() => openModal(index)}
              />
            ))}
          </div>
        </div>
      ) : null}

      <Modal show={modalIsOpen} onHide={closeModal} centered>
        <Modal.Body>
          {Array.isArray(photoFiles) && photoFiles.length > 0 && (
            <div style={{ position: 'relative', width: '100%', height: '100%' }}>
              <button
                onClick={closeModal}
                style={{
                  position: 'absolute',
                  top: '10px',
                  right: '10px',
                  background: 'none',
                  border: 'none',
                  fontSize: '1.5rem',
                  cursor: 'pointer',
                  color: 'black',
                }}
              >
                &times; {/* Close icon */}
              </button>
              <img
                src={photoFiles[currentPhotoIndex]}
                alt={`Restaurant Photo ${currentPhotoIndex + 1}`}
                style={{ width: '100%', height: '100%', objectFit: 'contain' }}
              />
              <button
                onClick={prevPhoto}
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '10px',
                  transform: 'translateY(-50%)',
                  background: 'none',
                  border: 'none',
                  fontSize: '2rem',
                  cursor: 'pointer',
                  color: 'white',
                }}
              >
                &#9664; {/* Left arrow */}
              </button>
              <button
                onClick={nextPhoto}
                style={{
                  position: 'absolute',
                  top: '50%',
                  right: '10px',
                  transform: 'translateY(-50%)',
                  background: 'none',
                  border: 'none',
                  fontSize: '2rem',
                  cursor: 'pointer',
                  color: 'white',
                }}
              >
                &#9654; {/* Right arrow */}
              </button>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Photos;
