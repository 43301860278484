import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import CommanFooter1 from "../../../layout/Footer/PageFooter";
import useAuthServices from "../../../services/authService";
import { ToastContainer } from "react-toastify";
import LinkExpired from "../LinkExpired/LinkExpired";

//image
import logo from "../../../assets/dist/img/logo.png";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { jwtDecode } from "jwt-decode"; // Import jwt_decode

const ResetPassword = () => {
  const [newPassword, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [tokenExpired, setTokenExpired] = useState(false);
  const {
    resetPassword,
    success,
    clearSuccess,
    isSubmitting,
    clearIsSubmitting,
    getUser,
    userData,
  } = useAuthServices();
  const { resetToken } = useParams();
  const history = useHistory();

  const resetForm = () => {
    setPassword("");
    setConfirmPassword("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const submitData = {
      newPassword,
      confirmPassword,
      resetToken,
    };

    await resetPassword(submitData);
  };

  useEffect(() => {
    // Check if reset token is expired
    // resetTokenExpired();

    // console.log("token expired", resetTokenExpired())
    // setTokenExpired(resetTokenExpired())
    // resetTokenExpired();
    if (resetTokenExpired()){
      setTokenExpired(true)
    }

    // Redirect to login page upon successful password reset
    if (success) {
      resetForm();
      history.push("/auth/");
    }

    clearIsSubmitting();
    clearSuccess();
  }, [success]);

  const resetTokenExpired = () => {
    try {
      const decodedToken = jwtDecode(resetToken);
      getUser(decodedToken.resetUserId);

      const currentTime = Date.now() / 1000; // Convert milliseconds to seconds

      if (
        decodedToken.exp < currentTime
      ) {
        return true; // Token is expired
      } else {
        return false; // Token is not expired
      }
    } catch (error) {
      console.log("Error decoding token:", error);
      return true; // Error decoding token, consider it expired
    }
  };
  return (
    <div className="hk-pg-wrapper pt-0 pb-xl-0 pb-5">
      <div>
        <Container>
          <Row>
            <Col sm={10} className="position-relative mx-auto">
              <div className="auth-content py-8">
                {tokenExpired  ? (
                  <LinkExpired />
                ) : (
                  userData? ((userData.user?.resetPasswordToken && userData.user?.resetPasswordToken === resetToken) ?( <LinkExpired />): (<Form onSubmit={handleSubmit}>
                    <Row>
                      <Col lg={5} md={7} sm={10} className="mx-auto">
                        <div className="text-center mb-7">
                          <Link to="/" className="navbar-brand me-0">
                            <img
                              className="brand-img d-inline-block"
                              src={logo}
                              alt="brand"
                              width="100px"
                              height="100px"
                            />
                          </Link>
                        </div>
                        <Card className="card-flush">
                          <Card.Body className="text-center">
                            <h4>Reset New Password</h4>
                            <Row className="gx-3">
                              <Col lg={12} as={Form.Group} className="mb-3">
                                <div className="form-label-group">
                                  <Form.Label htmlFor="userName">
                                    New Password
                                  </Form.Label>
                                </div>
                                <Form.Control
                                  placeholder="New Password"
                                  type="password"
                                  onChange={(e) => {
                                    setPassword(e.target.value);
                                  }}
                                  id="newPassword"
                                />
                                <br />
                                <div className="form-label-group">
                                  <Form.Label htmlFor="userName">
                                    Confirm Password
                                  </Form.Label>
                                </div>

                                <Form.Control
                                  placeholder="Confirm Password"
                                  type="password"
                                  onChange={(e) => {
                                    setConfirmPassword(e.target.value);
                                  }}
                                  id="confirmPassword"
                                />
                              </Col>
                            </Row>
                            <Button
                              type="submit"
                              variant="primary"
                              className="btn-uppercase btn-block"
                              disabled={isSubmitting} // Disable the button when success is true
                              id="resetBtn"
                            >
                              {/* {isSubmitting
                                ? "Reseting Password..."
                                : "Set New Password"} */}
                              {isSubmitting
                                ? "Reseting Password..."
                                : "Set New Password"}
                            </Button>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  </Form>)):(<div className="text-center py-50">
                    
                    <Spinner /> </div>)

                )}
              </div>
            </Col>
          </Row>
        </Container>
        <ToastContainer
          position="top-right"
          style={{ top: "70px", right: "20px" }}
          enableMultiContainer
          containerId="toast-container"
          autoClose={800}
        />
      </div>
      {/* Page Footer */}
      <CommanFooter1 />
    </div>
  );
};

export default ResetPassword;
