import { useState, useEffect } from "react";
import { Modal, Button, Form, Row, InputGroup } from "react-bootstrap";
import { toast } from "react-toastify";
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-thumbnail.css';

const backendUri = process.env.REACT_APP_BACKEND_DOMAIN;
const EditMenuModal = (props) => {
  const [updateMenuName, setMenuName] = useState("");
  const [updateMenuFile, setMenuFile] = useState(null);
  const [updateGotFile, setGotFile] = useState(false);
  const [fileName, setFileName] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    fetchMenuData(props.menuId);
  }, [props.showForm]);

  const fetchMenuData = async (id) => {
    try {
      const response = await fetch(
        `${backendUri}/api/v0/admin/menu/${id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${props.Token}`,
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        setMenuName(data.menuName || "");
        setFileName(data.menuFile || "");
      } else {
        console.error("Fail to fetch menu data");
      }
    } catch (error) {
      console.error("Error fetching menu data");
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const formData = new FormData();

    formData.append('menuName', updateMenuName);
    formData.append('gotFile', updateGotFile);
    if (updateMenuFile) {
      formData.append('file', updateMenuFile[0]);
    }

    let isTimedOut = false;

    const timeout = setTimeout(() => {
      isTimedOut = true;
      setIsSubmitting(false);
      toast.error("Request timed out. Please try again later.", {
        containerId: "error-container",
      });
      props.handleCloseForm();
    }, 5000);

    fetch(`${backendUri}/api/v0/admin/menu/${props.menuId}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${props.Token}`,
      },
      body: formData,
    })
      .then((res) => {
        clearTimeout(timeout);
        return res.json();
      })
      .then((res) => {
        if (res.error) {
          toast.error(`${res.message}`, {
            containerId: "error-container",
          });
        } else {
          toast.success(res.message, {
            containerId: "success-container",
          });
          props.handleCloseForm();
        }
        props.fetchData(props.Token);
      })
      .catch((error) => {
        console.error("Error updating menu : ", error.message);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <>
      <Modal show={props.showForm} onHide={props.handleCloseForm} size="lg">
        <Modal.Header closeButton>
          <Modal.Title> Edit Menu </Modal.Title>
        </Modal.Header>
        <Form style={{ margin: 3 }} onSubmit={(e) => handleUpdate(e)}>
          <Modal.Body>
            <Row className="mb-3">
              <Form.Group controlId="editMenuName">
                <Form.Label> Menu Name* </Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <i className="fa fa-tag" />
                  </InputGroup.Text>
                  <Form.Control
                    required
                    type="text"
                    name="menuName"
                    value={updateMenuName}
                    onChange={(e) => {
                      setMenuName(e.target.value);
                    }}
                  />
                </InputGroup>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group controlId="editMenuFile">
                <Form.Label> Menu Image (Accepted file format : .pdf .jpeg .jpg .png .svg .gif) </Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <i className="fa fa-file" />
                  </InputGroup.Text>
                  <Form.Control
                    type="file"
                    accept="image/*, pdf"
                    name="menuFile"
                    onChange={(e) => {
                      if (e.target.files.length > 0) {
                        setMenuFile(e.target.files);
                        setGotFile(true);
                      } else {
                        setGotFile(false);
                      }
                    }}
                  />
                </InputGroup>
                <span id="uploadedFileText">
                    Uploaded File: {fileName && typeof fileName === 'string'
                    ? fileName.split('/').pop()
                    : 'No file uploaded'}
                </span>
              </Form.Group>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="soft-primary"
              id="editMenuSubmitBtn"
              type="submit"
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  <span className="visually-hidden">Submitting...</span>
                </>
              ) : (
                "Update"
              )}
            </Button>
            <Button
              variant="soft-secondary"
              onClick={props.handleCloseForm}
              id="editCloseBtn"
            >
              Close
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
};

export default EditMenuModal;