import { Modal, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { useState } from "react";


const backendUri = process.env.REACT_APP_BACKEND_DOMAIN;
const DeleteModal = (props) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleConfirmDeletion = () => {
    setIsSubmitting(true);

    let isTimedOut = false;

    const timeout = setTimeout(() => {
      isTimedOut = true;
      setIsSubmitting(false);
      toast.error("Request timed out. Please try again later.", {
        containerId: "error-container",
      });
      props.handleCloseForm();
    }, 5000);

    fetch(
      `${backendUri}/api/v0/super-admin/admins/${props.adminId}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.Token}`,
        },
      }
    )
      .then((res) => {
        clearTimeout(timeout);
        return res.json();
      })
      .then((res) => {
        if (res.error) {
          toast.error(res.message, {
            containerId: "error-container",
          })
          props.handleCloseForm();
        } else {
          props.fetchData(props.Token);
          toast.success(res.message, {
            containerId: "success-container",
          });
          props.handleCloseForm();
        }
      })
      .catch((err) => {
        console.error("Error deleting item", err.message);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <>
      <Modal show={props.showForm} onHide={props.handleCloseForm}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this data?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="soft-light"
            onClick={props.handleCloseForm}
            id="closeDeletModalBtn"
          >
            Close
          </Button>
          <Button id="confirmDeleteAdminBtn" variant="soft-danger" onClick={handleConfirmDeletion} disabled={isSubmitting}>
            {isSubmitting ? (
              <>
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span className="visually-hidden">Submitting...</span>
              </>
            ) : (
              "Confirm Delete"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteModal;
