import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Card, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";
import invitationBackground from "../../assets/dist/img/rsvp.svg";
import invitationCard from "../../assets/dist/img/Terracotta Calligraphy RSVP Postcard.svg";
import useClientServices from "../../services/clientService";
import useEventServices from "../../services/eventService";
import useRestaurantServices from "../../services/restaurantService";
import "../../assets/dist/css/rsvp.css";
import { useAuth } from "../../services/authContext";
// eslint-disable-next-line no-undef
const backendUri = process.env.REACT_APP_BACKEND_DOMAIN;
const MyCardPage = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [event, setEvent] = useState({});
  const [restaurant, setRestaurant] = useState({});
  const { user } = useAuth();
  const { fetchClientInfo, clientInfo } = useClientServices();
  const { fetchRestaurantInfo, restaurantInfo } = useRestaurantServices();
  //Restaurant Name, restaurant address, client name

  const getQRCode = async (id, token) => {
    try {
      if (id.toString() === "undefined") {
        return;
      }
      const response = await fetch(
        `${backendUri}/api/v0/admin/events/qr/${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const blob = await response.blob();
        const qrCodeUrl = URL.createObjectURL(blob);
        return qrCodeUrl;
      }
    } catch (error) {
      console.error("Error fetching QR code:", error);
    }
  };

  const getEvent = async () => {
    try {
      if (!user.role || id.toString() === "undefined") {
        return;
      }
      setLoading(true);
      const token = Cookies.get("token");

      const response = await fetch(
        `${backendUri}/api/v0/${user.role}/events/${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const qrCode = await getQRCode(id, token);

      const responseData = await response.json();
      setEvent({
        ...responseData,
        qrCode: qrCode,
      });
    } catch (error) {
      console.error("Error fetching event:", error);
    } finally {
      setLoading(false);
    }
  };

  // const getRestaurant = async () => {
  //   const response = await fetch(
  //     `${backendUri}/api/v0/public/restaurants/${event.restaurantId}`,
  //     {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //     }
  //   );
  //   const responseData = await response.json();
  //   setRestaurant({
  //     ...responseData,
  //   });
  //   console.log("Restaurant", restaurant)
  // };

  const getDayOfEvent = (dateString) => {
    const date = new Date(dateString);
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    return days[date.getDay()];
  };

  function getMonthFromDate(dateString) {
    const date = new Date(dateString);
    return date.toLocaleString("en-us", { month: "long" });
  }

  function getDayFromDate(dateString) {
    const date = new Date(dateString);
    return date.getDate();
  }

  function getYearFromDate(dateString) {
    const date = new Date(dateString);
    return date.getFullYear();
  }

  useEffect(() => {
    if (user && id) {
      getEvent(id);
    }
  }, [user, id]);

  useEffect(() => {
    if (event) {
      fetchClientInfo(event.clientId);
      fetchRestaurantInfo(event.restaurantId);
    }
  }, [event]);

  return (
    <Container className="vh-100 d-flex justify-content-center align-items-center">
      <Row className="h-100 d-flex justify-content-center align-items-center">
        <Col xs={12} className="d-flex align-items-center">
          {!event || !restaurantInfo || !clientInfo ? (
            <Spinner animation="border" size="lg" />
          ) : (
            <div
              className="custom-card"
              style={{ position: "relative", width: "100%", height: "100%" }}
            >
              <img
                src={invitationCard}
                alt="Card"
                className="card-img"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
              <div id="cardDetails" className="card-overlay">
                <div className="card-details">
                  <p style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)" }}>
                    {getMonthFromDate(event.eventDate)}
                  </p>
                  <p
                    style={{
                      fontSize: "250%",
                      textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
                    }}
                  >
                    {getDayFromDate(event.eventDate)}
                  </p>
                  <p style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)" }}>
                    {getYearFromDate(event.eventDate)}
                  </p>
                  <p style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)" }}>
                    {event.eventTime}
                  </p>
                  <br />
                  <p>{restaurantInfo.restaurantName}</p>
                  <p>{restaurantInfo.restaurantAddress}</p>
                  <p>
                    Reserved By:{" "}
                    {clientInfo.clientName
                      ? clientInfo.clientName
                      : `0${clientInfo.clientContact}`}
                  </p>
                </div>
              </div>
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default MyCardPage;
