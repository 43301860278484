import { Modal, Button } from "react-bootstrap";
import { useState } from "react";
import { toast } from "react-toastify";


const backendUri = process.env.REACT_APP_BACKEND_DOMAIN;
const DeletePackageModal = (props) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleConfirmDelete = () => {
    setIsSubmitting(true);

    let isTimedOut = false;

    const timeout = setTimeout(() => {
      isTimedOut = true;
      setIsSubmitting(false);
      toast.error("Request timed out. Please try again later.", {
        containerId: "error-container",
      });
      props.handleCloseForm();
    }, 5000);

    fetch(
      `${backendUri}/api/v0/admin/packages/${props.packageId}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.Token}`,
        },
      }
    )
      .then((res) => {
        clearTimeout(timeout);
        return res.json();
      })
      .then((res) => {
        if (res.error) {
          toast.error(res.message, {
            containerId: "error-container",
          })
        } else {
          props.fetchData(props.Token);
          toast.success(res.message, {
            containerId: "success-container",
          });
          props.handleCloseForm();
        }
      })
      .catch((err) => {
        console.error("Error deleting item", err);
        toast.error(err, {
          containerId: "error-container",
        })
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  }

  return (
    <>
      <Modal show={props.showForm} onHide={props.handleCloseForm}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this data?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="soft-light"
            onClick={props.handleCloseForm}
            id="deleteCloseBtn"
          >
            Close
          </Button>
          <Button
            id="confirmDeleteBtn"
            variant="soft-danger"
            onClick={handleConfirmDelete}
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <>
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span className="visually-hidden">Submitting...</span>
              </>
            ) : (
              "Confirm Delete"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default DeletePackageModal;