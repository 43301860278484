import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { ArrowBarToLeft } from "tabler-icons-react";
import { toggleCollapsedNav } from "../../redux/action/Theme";

import logo from "../../assets/dist/img/logo.png";
import { useAuth } from "../../services/authContext";


const SidebarHeader = ({ navCollapsed, toggleCollapsedNav }) => {
  const { user } = useAuth();

  const toggleSidebar = () => {
    toggleCollapsedNav(!navCollapsed);
    document.getElementById("tggl-btn").blur();
  };

  return (
    <div className="menu-header">
      <span>
        <Link className="navbar-brand" to="/">
          <div style={{ display: "flex", alignItems: "center" }}>
            <img className="brand-img img-fluid mt-1" src={logo} alt="brand" width={"40px"}/>
            <div className="brand-img" style={{ width:"140px", marginTop:"5px", marginLeft: "15px"}}>
              <div style={{fontSize: "21px", marginBottom: "-5px", fontWeight: "700", color: "#232b2b", textOverflow: "ellipsis", overflow: "hidden"}}>{user.restaurant ? user.restaurant : "Reservation"}</div>
              <div style={{fontSize: "12px", marginTop: "-5px"}}>powered by Konfem</div>
            </div>
          </div>
        </Link>
        <Button
          id="tggl-btn"
          variant="flush-dark"
          onClick={toggleSidebar}
          className="btn-icon btn-rounded flush-soft-hover navbar-toggle"
        >
          <span className="icon">
            <span className="svg-icon fs-5">
              <ArrowBarToLeft />
            </span>
          </span>
        </Button>
      </span>
    </div>
  );
};

const mapStateToProps = ({ theme }) => {
  const { navCollapsed } = theme;
  return { navCollapsed };
};

export default connect(mapStateToProps, { toggleCollapsedNav })(SidebarHeader);
