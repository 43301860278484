import React, { useEffect, useState } from "react";
import {
  Card,
  Col,
  Table,
  Row,
} from "react-bootstrap";
import { Bar } from 'react-chartjs-2';
import { Chart, CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend } from 'chart.js';
import useRestaurantServices from "../../../services/restaurantService";
import useEventServices from "../../../services/eventService";
import * as Icons from "tabler-icons-react";

// Register the necessary components
Chart.register(CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend);

const RestaurantAnalysis = () => {
  const { restaurantsData } = useRestaurantServices();
  const [boardedList, setBoardedList] = useState([]);
  const [nonBoardedList, setNonBoardedList] = useState([]);
  const [restaurantStateMap, setRestaurantStateMap] = useState({});
  const [restaurantNameMap, setRestaurantNameMap] = useState({});
  const { eventsData } = useEventServices();
  const [monthlyEventsLabels, setMonthlyEventsLabels] = useState([]);
  const [monthlyEventsChartData, setMonthlyEventsChartData] = useState([]);
  const [eventsByStateLabels, setEventsByStateLabels] = useState([]);
  const [eventsByStateChartData, setEventsByStateChartData] = useState([]);
  const [monthlyEventsByState, setMonthlyEventsByState] = useState([]);
  const [monthlyEventsPerRestaurantLabels, setMonthlyEventsPerRestaurantLabels] = useState([]);
  const [monthlyEventsPerRestaurantTableData, setMonthlyEventsPerRestaurantTableData] = useState({});

  useEffect(() => {
    if (restaurantsData.length > 0) {
      setBoardedList(restaurantsData.filter((restaurant) => restaurant.isBoarded));
      setNonBoardedList(restaurantsData.filter((restaurant) => !restaurant.isBoarded));

      const restaurantStateMap = restaurantsData.reduce((acc, restaurant) => {
        acc[restaurant._id] = restaurant.restaurantState;
        return acc;
      }, {});
      setRestaurantStateMap(restaurantStateMap);

      const restaurantNameMap = restaurantsData.reduce((acc, restaurant) => {
        acc[restaurant._id] = restaurant.restaurantName;
        return acc;
      }, {});
      setRestaurantNameMap(restaurantNameMap);
    }
  }, [restaurantsData]);

  useEffect(() => {
    if (eventsData.length > 0) {
      categorizeMonthlyEventsData();
      categorizeEventsByState();
      categorizeEventsMonthlyByState();
      categorizeEventsMonthlyPerRestaurant();
    }
  }, [eventsData, restaurantStateMap, restaurantNameMap]);

  const categorizeMonthlyEventsData = () => {
    const groupEventsByMonth = () => {
      return eventsData.reduce((acc, event) => {
        const date = new Date(event.timestamp);
        const monthYear = `${date.getMonth() + 1}-${date.getFullYear()}`;

        if (!acc[monthYear]) {
          acc[monthYear] = [];
        }
        acc[monthYear].push(event);
        return acc;
      }, {});
    };

    const grouped = groupEventsByMonth();
    const labels = Object.keys(grouped);
    setMonthlyEventsLabels(labels);
    setMonthlyEventsChartData(labels.map(label => grouped[label].length));
  }

  const categorizeEventsByState = () => {
    const categorizedEvents = eventsData.reduce((acc, event) => {
      const state = restaurantStateMap[event.restaurant];

      if (state) {
        if (!acc[state]) {
          acc[state] = [];
        }
        acc[state].push(event);
      }
      return acc;
    }, {});

    setEventsByStateLabels(Object.keys(categorizedEvents));
    setEventsByStateChartData(Object.values(categorizedEvents).map(events => events.length));
  }

  const categorizeEventsMonthlyByState = () => {
    const groupedByMonthAndState = eventsData.reduce((acc, event) => {
      const date = new Date(event.timestamp);
      const monthYear = `${date.getMonth() + 1}-${date.getFullYear()}`;
      const state = restaurantStateMap[event.restaurant];

      if (state) {
        if (!acc[monthYear]) {
          acc[monthYear] = {};
        }
        if (!acc[monthYear][state]) {
          acc[monthYear][state] = [];
        }
        acc[monthYear][state]++;
      }
      return acc;
    }, {});

    setMonthlyEventsByState(groupedByMonthAndState);
  }

  const prepareMonthlyEventsByStateChartData = (monthlyEventsByState) => {
    const labels = Object.keys(monthlyEventsByState);
    const states = [...new Set(Object.values(restaurantsData).map(restaurant => restaurant.restaurantState))];
    const color = getRandomSimilarColor();
  
    const datasets = states.map(state => {
      return {
        label: state,
        data: labels.map(label => monthlyEventsByState[label][state] || 0),
        backgroundColor: color[0],
        borderColor: color[1],
        borderWidth: 1,
      };
    });
  
    return {
      labels,
      datasets,
    };
  };
  
  const getRandomSimilarColor = () => {
    const baseColor = [75, 192, 192]; // Base color components (R, G, B)
    const alpha = 0.2; // Fixed alpha value
    const alphaBackground = 1; // Fixed alpha value for background
  
    // Function to generate a random number within a range
    const getRandomInRange = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;
  
    // Generate random color components within a range around the base color
    const r = getRandomInRange(baseColor[0] - 20, baseColor[0] + 20);
    const g = getRandomInRange(baseColor[1] - 20, baseColor[1] + 20);
    const b = getRandomInRange(baseColor[2] - 20, baseColor[2] + 20);
  
    return [`rgba(${r}, ${g}, ${b}, ${alpha})`, `rgba(${r}, ${g}, ${b}, ${alphaBackground})`];
  };

  const barChartMonthlyEventsData = {
    labels: monthlyEventsLabels,
    datasets: [
      {
        label: '# of Reservations',
        data: monthlyEventsChartData,
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };

  const barChartStateEventsData = {
    labels: eventsByStateLabels,
    datasets: [
      {
        label: '# of Reservations',
        data: eventsByStateChartData,
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };

  const barChartMonthlyEventsByStateDate = prepareMonthlyEventsByStateChartData(monthlyEventsByState);

  const categorizeEventsMonthlyPerRestaurant = () => {
    const groupEventsByMonthAndRestaurant = () => {
      const allMonths = [...new Set(eventsData.map(event => {
        const date = new Date(event.timestamp);
        return `${date.getMonth() + 1}-${date.getFullYear()}`;
      }))];

      const result = eventsData.reduce((acc, event) => {
        const date = new Date(event.timestamp);
        const monthYear = `${date.getMonth() + 1}-${date.getFullYear()}`;
        const restaurantName = restaurantNameMap[event.restaurant];

        if (!acc[restaurantName]) {
          acc[restaurantName] = {};
        }
        if (!acc[restaurantName][monthYear]) {
          acc[restaurantName][monthYear] = 0;
        }
        acc[restaurantName][monthYear]++;

        return acc;
      }, {});

      // Ensure all restaurants are included in the data
      Object.keys(restaurantNameMap).forEach(restaurant => {
        if (!result[restaurantNameMap[restaurant]]) {
          result[restaurantNameMap[restaurant]] = {};
          allMonths.forEach(month => {
            result[restaurantNameMap[restaurant]][month] = 0;
          });
        }
      });

      return { result, allMonths };
    };
      
    const { result, allMonths } = groupEventsByMonthAndRestaurant();

    setMonthlyEventsPerRestaurantLabels(allMonths);
    setMonthlyEventsPerRestaurantTableData(result);
  };

  // Calculate total reservations for each restaurant
  const totalReservationsPerRestaurant = {};
  Object.keys(monthlyEventsPerRestaurantTableData).forEach(restaurant => {
    totalReservationsPerRestaurant[restaurant] = Object.values(monthlyEventsPerRestaurantTableData[restaurant]).reduce((sum, value) => sum + value, 0);
  });

  // Calculate total reservations for each month
  const totalReservationsPerMonth = {};
  monthlyEventsPerRestaurantLabels.forEach(month => {
    totalReservationsPerMonth[month] = Object.keys(monthlyEventsPerRestaurantTableData).reduce((sum, restaurant) => sum + monthlyEventsPerRestaurantTableData[restaurant][month], 0);
  });


  return (
    <>
      <Row className="mt-3">
        <Col md={3}>
          <Card className="mb-4 text-center">
            <Card.Header className="d-flex justify-content-center align-items-center">
              <Icons.Users/><strong>On Boarded Restaurants</strong>
            </Card.Header>
            <Card.Body>
              <Card.Text style={{ fontSize: '2rem' }}> {boardedList.length} </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Card className="mb-4 text-center">
            <Card.Header className="d-flex justify-content-center align-items-center">
              <Icons.Users/><strong>Non Boarded Restaurants</strong>
            </Card.Header>
            <Card.Body>
              <Card.Text style={{ fontSize: '2rem' }}> {nonBoardedList.length} </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md={6}>
          <Card className="mb-4">
            <Card.Header>Total # of Reservations (Monthly basis)</Card.Header>
            <Card.Body>
              <Bar data={barChartMonthlyEventsData} />
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card className="mb-4">
            <Card.Header> Total # of Reservations per State</Card.Header>
            <Card.Body>
              <Bar data={barChartStateEventsData} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md={12}>
          <Card className="mb-4">
            <Card.Header># of Reservations Monthly per State</Card.Header>
            <Card.Body>
              <Bar data={barChartMonthlyEventsByStateDate} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col md={12} style={{ marginBottom: '20px' }}>
          <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
            <h6><strong>Restaurant's Reservations Data</strong></h6>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th><strong>Month</strong></th>
                  {Object.keys(monthlyEventsPerRestaurantTableData).map((restaurant, index) => (
                    <th key={index}><strong>{restaurant}</strong></th>
                  ))}
                  <th><strong>Total</strong></th>
                </tr>
              </thead>
              <tbody>
                {monthlyEventsPerRestaurantLabels.map(month => (
                  <tr key={month}>
                    <td>{month}</td>
                    {Object.keys(monthlyEventsPerRestaurantTableData).map(restaurant => (
                      <td key={restaurant + month}>{monthlyEventsPerRestaurantTableData[restaurant][month]}</td>
                    ))}
                    <td>{totalReservationsPerMonth[month]}</td>
                  </tr>
                ))}
                <tr>
                  <td><strong>Total</strong></td>
                  {Object.keys(monthlyEventsPerRestaurantTableData).map((restaurant, index) => (
                    <td key={index}><strong>{totalReservationsPerRestaurant[restaurant]}</strong></td>
                  ))}
                  <td></td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default RestaurantAnalysis;