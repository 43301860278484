import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { ToastContainer } from "react-toastify";

import useRestaurantServices from "../../services/restaurantService";


const DeleteCompanyModal = (props) => {
  const [restaurantId, setRestaurantId] = useState("");
  const { manageRestaurant, success } = useRestaurantServices();
  const [requestType, setRequestType] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    setRequestType(props.request);
    setRestaurantId(props.restaurantId);
  }, [props.restaurantId, props.request]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const submitData = {
      restaurantId,
      requestType,
    };

    // Call the createRestaurant function from the hook and pass the form data
    manageRestaurant(submitData);
    setRequestType("");
    setIsSubmitting(false);
    // Close the modal
    props.handleCloseForm();
  };

  useEffect(() => {
    props.getRestaurants()
  }, [success]);

  return (
    <>
      <Modal show={props.showForm} onHide={props.handleCloseForm}>
        <Modal.Header closeButton>
          <Modal.Title>Comfirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this data?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="soft-light"
            onClick={props.handleCloseForm}
            id="closeDeleteModalBtn"
          >
            Close
          </Button>
          <Button
            variant="soft-danger"
            onClick={handleSubmit}
            id="confirmDeleteBtn"
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <>
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span className="visually-hidden">Submitting...</span>
              </>
            ) : (
              "Confirm Delete"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer
        position="top-right"
        style={{ top: "70px", right: "20px" }}
        enableMultiContainer
        containerId="error-container"
      />
    </>
  );
};

export default DeleteCompanyModal;
