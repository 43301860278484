import React from "react";
import { Nav } from "react-bootstrap";


const Header = ({switchTab, activeTab}) => {
  return (
    <header className="profile-header">
      <Nav
        variant="tabs"
        className="nav-line nav-light"
        defaultActiveKey="link-2"
        activeKey={activeTab}
        onSelect={(selectedKey) => switchTab(selectedKey)}
      >
        <Nav.Item>
          <Nav.Link eventKey="Packages">
            <span className="nav-link-text" id="package">Packages</span>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="Menus">
            <span className="nav-link-text" id="menu">Menus</span>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="Reviews">
            <span className="nav-link-text" id="menu">Reviews</span>
          </Nav.Link>
        </Nav.Item>
      </Nav>
    </header>
  );
};

export default Header;
