import React, { useState, useEffect } from "react";
import * as Icon from "tabler-icons-react";
import * as Icons from "react-feather";
import "animate.css";
import "@sweetalert2/theme-bootstrap-4/bootstrap-4.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { useAuth } from "../../../services/authContext";
import { faPhoneVolume } from "@fortawesome/free-solid-svg-icons"; // Envelope icon for email message

import "../../../assets/dist/css/live-search.css";
import HkBadge from "../../../components/@hk-badge/@hk-badge";
import { Spinner } from "react-bootstrap";


const ViewEvents = ({ eventDetails, motherEvent, adminNotification, isBoarded }) => {
  const [iframeKey, setIframeKey] = useState(0);
  const whatsAppMsgTemplate = "Default Message Template";
  const [contactNumber, setContactNumber] = useState("");
  const [compareId, setCompareId] = useState("");
  const { user } = useAuth();

  useEffect(() => {
    if (user.role === "client") {
      setContactNumber(motherEvent?.restaurantContact);
    } else if (
      user.role === "admin" &&
      motherEvent?._def?.extendedProps?.phoneNo
    ) {
      setContactNumber(motherEvent?._def?.extendedProps?.phoneNo);
    } else if (user.role === "admin" && motherEvent?.phoneNo) {
      setContactNumber(motherEvent?.phoneNo);
    }

    motherEvent?._def?.publicId
      ? setCompareId(motherEvent._def.publicId)
      : setCompareId(motherEvent?._id);
  }, [motherEvent, eventDetails]);

  return (
    <>
      {" "}
      {eventDetails.id && eventDetails.id === compareId ? (
        <>
          {" "}
          <div className="event-head mb-4">
            <HkBadge
              bg="violet"
              indicator
              className="badge-indicator-xl flex-shrink-0 me-2"
            />
            <span className="event-name fs-6">{eventDetails.title}</span>
          </div>
          <ul className="event-detail fs-7">
            {user.role === "client" ? (
              <li>
                <span className="ev-icon-wrap" title="Restaurant Name">
                  <span className="feather-icon fs-6">
                    <Icons.Home />
                  </span>
                </span>
                {motherEvent?.restaurant?.restaurantName}
              </li>
            ) : null}

            {/* <li>
              <span className="ev-icon-wrap" title="Event Name">
                <span className="feather-icon">
                  <Icons.AlignCenter />
                </span>
              </span>
              {eventDetails.eventName ? eventDetails.eventName : ""}
            </li> */}
            <li className="d-flex justify-content-between fs-8">
              <div className="d-flex flex-row">
                <span className="ev-icon-wrap" title="Client's Phone No.">
                  <span className="feather-icon fs-6">
                    <Icons.Phone />
                  </span>
                </span>
                +60{contactNumber}
              </div>
              <div>
                <a
                  href={`tel:+60${contactNumber}`} // WhatsApp call link
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon
                    className="fs-5 me-3"
                    icon={faPhoneVolume}
                  />
                </a>
                <a
                  href={`https://wa.me/+60${contactNumber}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon
                    className="fs-4 me-2"
                    icon={faWhatsapp}
                  />
                </a>
              </div>
            </li>
            <li className="fs-8">
              <span className="ev-icon-wrap" title="Date">
                <span className="feather-icon fs-6">
                  <Icons.Calendar />
                </span>
              </span>
              {eventDetails.showDate ? eventDetails.showDate : ""}
            </li>
            <li className="fs-8">
              <span className="ev-icon-wrap" title="Time">
                <span className="feather-icon fs-6">
                  <Icons.Clock />
                </span>
              </span>
              {eventDetails.showTime ? eventDetails.showTime : ""}
            </li>
            {/* <li>
              <span className="ev-icon-wrap" title="Konfem Before">
                <span className="feather-icon">
                  <Icons.BellOff />
                </span>
              </span>
              {eventDetails.closingBefore
                ? eventDetails.closingBefore + " day(s) before"
                : ""}
            </li> */}
            <li className="fs-8">
              <span className="ev-icon-wrap" title="Package">
                <span className="feather-icon fs-6">
                  <Icon.PaperBag />
                </span>
              </span>
              {eventDetails.packageName ? eventDetails.packageName : "NA"}
            </li>
            <li className="fs-8">
              <span className="ev-icon-wrap" title="No. of Table">
                <span className="feather-icon fs-6">
                  <Icons.Hash />
                </span>
              </span>
              {eventDetails.noOfTable ? eventDetails.noOfTable : "-"} tables
            </li>
            <li className="fs-8">
              <span className="ev-icon-wrap" title="No. of Pax">
                <span className="feather-icon fs-6">
                  <Icons.Users />
                </span>
              </span>
              {eventDetails.noOfPax ? eventDetails.noOfPax : "-"} pax
            </li>
            <li className="fs-8">
              <span className="ev-icon-wrap" title="Status">
                <span className="feather-icon fs-6">
                  <Icons.Coffee />
                </span>
              </span>
              {isBoarded ? eventDetails.status : "Unknown (Please check with restaurant)"}
            </li>
            <li className="fs-7">
              <span className="ev-icon-wrap" title="Remark">
                <span className="feather-icon fs-6">
                  <Icons.AlignJustify />
                </span>
              </span>
              {eventDetails.remark}
            </li>
            <li>
              <a
                id="iframe"
                href={`/auth/rsvp/${eventDetails.id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div
                  style={{
                    position: "absolute",
                    height: "390px",
                    width: "100%",
                  }}
                ></div>
                <iframe
                  key={iframeKey}
                  src={`/auth/rsvp/${eventDetails.id}`}
                  title="RSVP Card"
                  width="100%"
                  height="400"
                  border="0"
                ></iframe>
              </a>
            </li>
          </ul>
        </>
      ) : (
        <div className="text-center mt-50">
          <Spinner />
        </div>
      )}
    </>
  );
};

export default ViewEvents;
