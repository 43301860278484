import React, { useState, useEffect } from "react";
import {
  Card,
  Row,
  Container,
  Col,
  Button,
} from "react-bootstrap";
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-thumbnail.css';

import defaultImage from "../../../assets/dist/img/default_photo.png";
import ImagesModal from "./ImagesModal";


const MenuDetails = ({ restaurantsMenuData, restaurantContactNumber }) => {
  const [restaurantMenusData, setRestaurantMenusData] = useState([]);
  const [imageMenus, setImageMenus] = useState([]);
  const [pdfMenus, setPdfMenus] = useState([]);

  useEffect(() => {
    if (Array.isArray(restaurantsMenuData)) {
      setRestaurantMenusData(restaurantsMenuData);
    }
  }, [restaurantsMenuData]);

  useEffect(() => {
    if (restaurantMenusData.length > 0) {
      const imageMenus = restaurantMenusData.filter(menu => menu.menuFile && menu.menuFile.split('.').pop().toLowerCase() !== 'pdf');
      const pdfMenus = restaurantMenusData.filter(menu => menu.menuFile && menu.menuFile.split('.').pop().toLowerCase() === 'pdf');
      setImageMenus(imageMenus);
      setPdfMenus(pdfMenus);
    }
  }, [restaurantMenusData]);

  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const openModal = (index) => {
    setPhotoIndex(index);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const nextImage = () => {
    setPhotoIndex((photoIndex + 1) % imageMenus.length);
  };

  const prevImage = () => {
    setPhotoIndex((photoIndex + imageMenus.length - 1) % imageMenus.length);
  };
  

  return (
    <>
      <div>
        <Container>
          <div>
            {restaurantsMenuData.message === "No menus at the moment" ? (
              <>
                <h5>No menus at the moment</h5>
                <div className="d-flex align-items-center justify-content-center mb-10">
                  <a
                    href={`https://wa.me/+60${restaurantContactNumber}?text=Hi, I’m interested to dine at your restaurant and would like to learn more about your menu. Could you please share it with me?`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button style={{ width: "250px" }}>
                      Click to find out more
                    </Button>
                  </a>
                </div>
              </>
            ) : (
              <>
                {restaurantMenusData.length > 0 ? (
                  <>
                    <Row>
                      <h6 className="mb-4 text-center">Our Menu</h6>
                      <div>
                        {pdfMenus.map((pdfItem, index) => {
                          return (
                            <Col key={index} xl={4} sm={6}>
                              <Card>
                                <Card.Body style={{ textAlign: 'center' }}>
                                  <Card.Title style={{ fontSize: '12px' }}>{pdfItem.menuName}</Card.Title>
                                  <Button
                                    id="viewMenuBtn"
                                    size="sm"
                                    variant="outline-success"
                                    style={{ margin: 5, fontSize: '12px' }}
                                    className="btn-rounded"
                                    onClick={() => window.open(pdfItem.menuFile)}
                                    disabled={!pdfItem.menuFile}
                                  >
                                    View Menu
                                  </Button>
                                </Card.Body>
                              </Card>
                            </Col>
                          );
                        })}
                      </div>
                    </Row>
                    <Row>
                      <div style={{ overflowX: 'scroll', whiteSpace: 'nowrap' }}>
                        {imageMenus.map((item, index) => {
                          return (
                            <Col xl={2} sm={4} key={index} style={{ display: 'inline-block', margin: '5px' }}>
                              <Card className="card-border" onClick={() => openModal(index)}>
                                <div className="image-container">
                                  {item.menuFile ? (
                                    <Card.Img
                                      src={item.menuFile}
                                      alt={`Restaurant Menu ${index + 1}`}
                                      style={{
                                        width: "100%",
                                        height: "200px",
                                        objectFit: "contain",
                                      }}
                                    />
                                  ) : (
                                    <Card.Img
                                      src={defaultImage}
                                      alt={`Restaurant Menu ${index + 1}`}
                                      style={{
                                        width: "100%",
                                        height: "200px",
                                        objectFit: "cover",
                                      }}
                                    />
                                  )}
                                </div>
                              </Card>
                              <div className="mb-0 text-center fw-bold" style={{ fontSize: '12px' }}>{item.menuName}</div>
                            </Col>
                          );
                        })}
                      </div>
                    </Row>
                    <ImagesModal
                      show={isOpen}
                      onHide={closeModal}
                      imageSrc={
                        imageMenus && imageMenus[photoIndex] && imageMenus[photoIndex].menuFile
                          ? imageMenus[photoIndex].menuFile
                          : defaultImage
                      }
                      onNext={nextImage}
                      onPrev={prevImage}
                      images={imageMenus}
                    />
                  </>
                ) : null}
              </>
            )}
          </div>
        </Container>
      </div>
    </>
  );
};

export default MenuDetails;
