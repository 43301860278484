import React from "react";
import FavRestaurantCardsBody from "./FavRestaurantCardsBody";


const FavRestaurantCards = () => {
  return (
    <>
      <div className="hk-pg-body p-3">
        <FavRestaurantCardsBody />
      </div>
    </>
  );
};

export default FavRestaurantCards;
