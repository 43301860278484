import React, { useEffect, useState } from "react";
import "bootstrap-daterangepicker/daterangepicker.css";
import { connect } from "react-redux";
import { toggleCollapsedNav } from "../../redux/action/Theme";
import ClientDashboard from "./ClientDashboard";
import AdminDashboard from "./AdminDashboard";
import SuperAdminDashboard from "../RestaurantManagement";
import { useAuth } from "../../services/authContext";

const Dashboard = ({ navCollapsed, toggleCollapsedNav }) => {
  const { user } = useAuth();

  useEffect(() => {
    toggleCollapsedNav(false);
  }, [toggleCollapsedNav]);

  return (
    <>
      {user.role === "client" ? <ClientDashboard /> : null}
      {user.role === "admin" ? <AdminDashboard /> : null}
      {user.role === "superAdmin" ? <SuperAdminDashboard /> : null}
    </>
  );
};

// export default Dashboard
const mapStateToProps = ({ theme }) => {
  const { navCollapsed } = theme;
  return { navCollapsed };
};

export default connect(mapStateToProps, { toggleCollapsedNav })(Dashboard);
