import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import CommanFooter1 from "../../../layout/Footer/PageFooter";
import useAuthServices from "../../../services/authService";
import { ToastContainer } from "react-toastify";
//image
import logo from "../../../assets/dist/img/logo.png";

const ForgotPassword = () => {
  const [userEmail, setUserEmail] = useState("");
  const [userType, setUserType] = useState("client"); // Default to "user" type
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [countdown, setCountdown] = useState(60); // Initial countdown value in seconds
  const { sendResetPasswordLink, success } = useAuthServices();

  const handleSubmit = (e) => {
    e.preventDefault();
    setButtonDisabled(true);
    sendResetPasswordLink(userEmail, userType); // Pass userType to sendResetPasswordLink
  };

  useEffect(() => {
    if (success) {
      setButtonDisabled(true);
    } else {
      setButtonDisabled(false);
    }
  }, [success]);

  useEffect(() => {
    let timer;
    if (buttonDisabled) {
      timer = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown === 0) {
            clearInterval(timer);
            setButtonDisabled(false); // Enable the button after the countdown finishes
            return 60; // Reset countdown value
          }
          return prevCountdown - 1;
        });
      }, 1000); // Update countdown every second
    }
    return () => clearInterval(timer); // Clean up timer on unmount or re-render
  }, [buttonDisabled]);

  return (
    <div className="hk-pg-wrapper pt-0 pb-xl-0 pb-5">
      <div className="hk-pg-body pt-0 pb-xl-0">
        <Container>
          <Row>
            <Col sm={10} className="position-relative mx-auto">
              <div className="auth-content py-8">
                <Form className="w-100" onSubmit={handleSubmit}>
                  <Row>
                    <Col lg={5} md={7} sm={10} className="mx-auto">
                      <div className="text-center mb-7">
                        <Link to="/" className="navbar-brand me-0">
                          <img
                            className="brand-img d-inline-block"
                            src={logo}
                            alt="brand"
                            width="100px"
                            height="100px"
                          />
                        </Link>
                      </div>
                      <Card className="card-flush">
                        <Card.Body className="text-center">
                          <h4>Forgot Password ?</h4>
                          <p className="mb-4" style={{ color: "#6F6F6F" }}>
                            No worries we will send you a link to your recovery
                            email address to reset your password.
                          </p>
                          <Row className="gx-3">
                            <Row className="gx-3">
                              <Col lg={6} md={6} className="mb-3">
                                <div className="mb-3">
                                  <Form.Check
                                    type="radio"
                                    label="I am an User"
                                    name="userType"
                                    id="client"
                                    checked={userType === "client"}
                                    onChange={() => setUserType("client")}
                                  />
                                </div>
                              </Col>
                              <Col lg={6} md={6} className="mb-3">
                                <div className="mb-3">
                                  <Form.Check
                                    type="radio"
                                    label="I am an Admin"
                                    name="userType"
                                    id="admin"
                                    checked={userType === "admin"}
                                    onChange={() => setUserType("admin")}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Col lg={12} as={Form.Group} className="mb-3">
                              <div className="form-label-group">
                                <Form.Label htmlFor="userName">
                                  Email
                                </Form.Label>
                              </div>
                              <Form.Control
                                placeholder="Recovery email"
                                type="email"
                                onChange={(e) => {
                                  setUserEmail(e.target.value);
                                }}
                                required
                              />
                            </Col>
                          </Row>
                          <Button
                            type="submit"
                            variant="primary"
                            className="btn-uppercase btn-block"
                            disabled={buttonDisabled}
                          >
                            {buttonDisabled
                              ? `Link Sent (${countdown})`
                              : "Send"}
                          </Button>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
        <ToastContainer
          position="top-right"
          style={{ top: "70px", right: "20px" }}
          enableMultiContainer
          containerId="toast-container"
        />
      </div>
      {/* Page Footer */}
      <CommanFooter1 />
    </div>
  );
};

export default ForgotPassword;
