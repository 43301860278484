import React, { createContext, useContext, useState, useEffect } from "react";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";


const AuthContext = createContext();
// eslint-disable-next-line no-undef
const backendUri = process.env.REACT_APP_BACKEND_DOMAIN;
const AuthProvider = ({ children }) => {
  const [authenticated, setAuthenticated] = useState(false);
  const [user, setUser] = useState({});
  const [loginMethod, setLoginMethod] = useState("")
  const [loading, setLoading] = useState(true);

  const checkTokenExpiration = () => {
    const token = Cookies.get("token");
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        if (decodedToken && decodedToken.exp < Date.now() / 1000) {
          Cookies.remove("token");
          setAuthenticated(false);
          setUser({});
          setLoading(false);
          return false;
        }
      } catch (error) {
        console.error("Error decoding token:", error);
      }
    }
    return true;
  };

  const fetchUserInfo = async () => {
    const token = Cookies.get("token");
    try {
      const response = await fetch(`${backendUri}/api/v0/auth/verify`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const userInfo = await response.json();
        setAuthenticated(true);
        setUser(userInfo);
        setLoading(false);
      } else {
        Cookies.remove("token");
        setAuthenticated(false);
        setUser({});
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching user information:", error);
      setAuthenticated(false);
      setUser({});
      setLoading(false);
    }
  };

  const login = async () => {
    fetchUserInfo();
  };

  const logout = async () => {
    Cookies.remove("token");
    setAuthenticated(false);
    setLoginMethod("")
    setUser({});
  };

  useEffect(() => {
    const token = Cookies.get("token");
    if (token) {
      if (checkTokenExpiration()) {
        fetchUserInfo();
      }
    } else{
      setAuthenticated(false);
      setUser({});
      setLoading(false);
    }
  }, []);

  return (
    <AuthContext.Provider value={{ authenticated, user, login, logout, loading, loginMethod, setLoginMethod }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export { AuthProvider, useAuth };
