import React from 'react';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleArrowLeft, faCircleArrowRight, faTimes } from '@fortawesome/free-solid-svg-icons';


const ImagesModal = ({ show, onHide, imageSrc, onNext, onPrev, images }) => {
  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Body>
        <img src={imageSrc} alt="Modal Content" style={{ width: '100%', height: 'auto' }} />
        {images.length > 1 && (
          <>
            <FontAwesomeIcon
              icon={faCircleArrowLeft}
              onClick={onPrev}
              style={{
                position: 'absolute',
                top: '50%',
                left: '10px',
                transform: 'translateY(-50%)',
                cursor: 'pointer',
                fontSize: '28px',
                color: '#ed9c00',
              }}
            />
            <FontAwesomeIcon
              icon={faCircleArrowRight}
              onClick={onNext}
              style={{
                position: 'absolute',
                top: '50%',
                right: '10px',
                transform: 'translateY(-50%)',
                cursor: 'pointer',
                fontSize: '28px',
                color: '#ed9c00',
              }}
            />
          </>
        )}
        <FontAwesomeIcon
          icon={faTimes}
          onClick={onHide}
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            cursor: 'pointer',
            fontSize: '16px',
            color: 'black',
          }}
        />
      </Modal.Body>
    </Modal>
  );
};

export default ImagesModal;