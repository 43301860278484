import React, { useEffect, useState } from "react";
import {
  Card,
  Col,
  Row,
} from "react-bootstrap";
import { Bar } from 'react-chartjs-2';
import { Chart, CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import useClientServices from "../../../services/clientService";
import useRestaurantServices from "../../../services/restaurantService";
import useEventServices from "../../../services/eventService";
import * as Icons from "tabler-icons-react";

// Register the necessary components
Chart.register(CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend);

const RestaurantAnalysis = () => {
  const { clientsData } = useClientServices();
  const [validUserList, setValidUserList] = useState([]);
  const [userNameMap, setUserNameMap] = useState({});
  const { restaurantsData } = useRestaurantServices();
  const [restaurantNameMap, setRestaurantNameMap] = useState({});
  const [monthlyUserSignUpLabels, setMonthlyUserSignUpLabels] = useState([]);
  const [monthlyUserSignUp, setMonthlyUserSignUp] = useState([]);
  const { eventsData } = useEventServices();
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    if (clientsData) {
      const validUsers = clientsData.clients.filter(client => client.clientEmail);
      setValidUserList(validUsers);

      const clientNameMap = validUsers.reduce((acc, user) => {
        acc[user._id] = user.clientName;
        return acc;
      }, {});
      setUserNameMap(clientNameMap);
    }
  }, [clientsData]);

  useEffect(() => {
    if (restaurantsData.length > 0) {
      const restaurantNameMap = restaurantsData.reduce((acc, restaurant) => {
        acc[restaurant._id] = restaurant.restaurantName;
        return acc;
      }, {});
      setRestaurantNameMap(restaurantNameMap);
    }
  }, [restaurantsData]);

  useEffect(() => {
    categorizeMonthlyUsersSignUp();
  }, [validUserList]);

  useEffect(() => {
    if (eventsData) {
      categorizeEventsByClient();
    }
  }, [eventsData, userNameMap, restaurantNameMap]);

  const categorizeMonthlyUsersSignUp = () => {
    const groupUsersByMonth = () => {
      return validUserList.reduce((acc, user) => {
        const date = new Date(user.signUpDate);
        const monthYear = `${date.getMonth() + 1}-${date.getFullYear()}`;

        if (!acc[monthYear]) {
          acc[monthYear] = [];
        }

        acc[monthYear].push(user);
        return acc;
      }, {});
    };

    const grouped = groupUsersByMonth();
    const labels = Object.keys(grouped);
    setMonthlyUserSignUpLabels(labels);
    setMonthlyUserSignUp(labels.map(label => grouped[label].length));
  };

  const categorizeEventsByClient = () => {
    const groupedData = eventsData.reduce((acc, event) => {
      const clientName = userNameMap[event.clientId];
      const restaurantName = restaurantNameMap[event.restaurant];
      const visitDate = new Date(event.eventDate).toLocaleDateString();

      if (!acc[clientName]) {
        acc[clientName] = [];
      }

      acc[clientName].push({ restaurantName, visitDate });
      return acc;
    }, {});

    const transformedData = Object.keys(groupedData).flatMap(clientName => {
      return groupedData[clientName].map((item, index) => ({
        clientName: index === 0 ? clientName : '',
        restaurantName: item.restaurantName,
        visitDate: item.visitDate,
        rowSpan: index === 0 ? groupedData[clientName].length : 0,
      }));
    });

    setTableData(transformedData);
  };

  const barChartMonthlyUserSignUpData = {
    labels: monthlyUserSignUpLabels,
    datasets: [
      {
        label: '# of Users Signed Up (Monthly)',
        data: monthlyUserSignUp,
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };

  return (
    <>
      <Row className="mt-3">
        <Col md={3}>
          <Card className="mb-4 text-center">
            <Card.Header className="d-flex justify-content-center align-items-center">
              <Icons.Users/><strong>Total signed up users</strong>
            </Card.Header>
            <Card.Body>
              <Card.Text style={{ fontSize: '2rem' }}> {validUserList.length} </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md={6}>
          <Card className="mb-4">
            <Card.Header className="d-flex justify-content-center align-items-center">
              <Icons.Calendar/><strong className="ml-2">Total # of Users Signed Up Monthly</strong>
            </Card.Header>
            <Card.Body>
              <Bar data={barChartMonthlyUserSignUpData} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mt-4" style={{ marginBottom: '20px'}}>
        <Col md={8}>
          <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
            <h6><strong>Client's Reservations Data</strong></h6>
            <TableContainer component={Paper} style={{ maxHeight: 400, overflowY: 'auto' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell><strong>Client Name</strong></TableCell>
                    <TableCell><strong>Restaurant Visited</strong></TableCell>
                    <TableCell><strong>Visit Date</strong></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData.map((row, index) => (
                    <TableRow key={index}>
                      {row.clientName && (
                        <TableCell rowSpan={row.rowSpan}>{row.clientName}</TableCell>
                      )}
                      <TableCell style={{ padding: '8px' }}>{row.restaurantName}</TableCell>
                      <TableCell style={{ padding: '8px' }}>{row.visitDate}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default RestaurantAnalysis;