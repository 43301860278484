import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";

import useClientServices from "../../../services/clientService";
import RestaurantCardsBody from "./RestaurantCardsBody";


const RestaurantCards = () => {
  const [token, setToken] = useState(Cookies.get("token"));
  const [decodedToken, setDecodedToken] = useState(null);
  const { fetchClientInfo, clientInfo } = useClientServices();
  const [popUpdateProfile, setPopUpdateProfile] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (token) {
      const decoded = jwtDecode(token);
      setDecodedToken(decoded);
    }
  }, [token]);

  useEffect(() => {
    if (decodedToken) {
      fetchClientInfo(decodedToken.userId);
    }
  }, [decodedToken, fetchClientInfo]);

  useEffect(() => {
    if (clientInfo && (!clientInfo.clientName || !clientInfo.clientContact)) {
      setPopUpdateProfile(true);
    }
  }, [clientInfo]);

  const handleAgreeUpdateProfile = () => {
    history.push('/app/profile');
  };

  return (
    <div className="hk-pg-body p-3">
      <RestaurantCardsBody />

      <Modal show={popUpdateProfile} onHide={() => setPopUpdateProfile(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Welcome Foodie!</Modal.Title>
        </Modal.Header>
        <Modal.Body>You are almost there! Please complete updating your profile.</Modal.Body>
        <Modal.Footer>
          <Button id="yes" variant="primary" onClick={handleAgreeUpdateProfile}>
            Next
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default RestaurantCards;
