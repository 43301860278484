import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
  Alert,
} from "react-bootstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import InputMask from "react-input-mask";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";

import logo from "../../../../assets/dist/img/logo.png";
import signupBg from "../../../../assets/dist/img/signup-bg.jpg";
import slide1 from "../../../../assets/developImg/clientSlide1.jpg";
import termsNConditions from "../../../../assets/konfem_file/KONFEM USER TERMS OF USE.pdf";
import privacyPolicy from "../../../../assets/konfem_file/KONFEM PRIVACY POLICY.pdf";
import { useAuth } from "../../../../services/authContext";
import {
  linkWithPopup,
  signInWithPopup,
  GoogleAuthProvider,
  unlink,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { auth } from "../../../../services/firebaseService";


const CustomerSignup = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [messageShow, setMessageShow] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [clientContact, setClientContact] = useState("");
  const history = useHistory();
  const role = "client";
  const location = useLocation();
  const { login, setLoginMethod, loginMethod } = useAuth();
  const restaurantId = location.state ? location.state.restaurantId : null;
  // eslint-disable-next-line no-undef
  const backendUri = process.env.REACT_APP_BACKEND_DOMAIN;
  const provider = new GoogleAuthProvider();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [navigateRestaurant, setNavigateRestaurant] = useState("/");

  const buttonStyle = {
    padding: '5px 20px',
    border: '0px',
    display: 'block',
    gap: '10px',
    borderRadius: '60px',
    color: '#2d3748',
    backgroundColor: '#edf2f7',
    transition: 'all 0.15s ease-in-out',
    width: '100%'
  };
  const imgStyle = {width: '24px', height: '24px', marginRight: '10px'};

  useEffect(() => {
    const token = Cookies.get("token");

    if (restaurantId) {
      setNavigateRestaurant(`/restaurants/${restaurantId}`);
    }

    if (token) {
      history.push("/");
    }
  }, [restaurantId]);

  const loginUser = async (
    email,
    password,
    role,
    userFirebaseUUID,
    loginMethod, userUID
  ) => {
    setLoginMethod(loginMethod)
    const response = await fetch(`${backendUri}/api/v0/auth/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        password,
        role,
        userFirebaseUUID,
        loginMethod,
        userUID
      }),
    });
    if (!response.ok) {
      const errorResponse = await response.json();
      setLoading(false);
      setError(errorResponse.error);
      setShowAlert(true);
      return;
    }
 

    const { token } = await response.json();

    if (token) {
      Cookies.set("token", token);
      await login();
      // const user = auth.currentUser;
      // await linkWithPopup(user, provider)
      
      setTimeout(() => {
        history.push("/");
      }, 500);
    } else {
      setError("Token not found");
      setShowAlert(true);
    }
  };

  const handlePhoneNoChange = (e) => {
    const inputPhoneNo = e.target.value;
    const cleanedPhoneNo = inputPhoneNo.replace(/[ -]/g, "");
    setClientContact(cleanedPhoneNo);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      toast.error("Password did not match");
    } else {
      fetch(`${backendUri}/api/v0/auth/signup`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ role, name, clientContact, email, password }),
      })
        .then((res) => {
          return res.json();
        })
        .then(async (res) => {
          if (res.token) {
  
            // if (!res.isGoogleLinked) {
            //   await signInWithEmailAndPassword(auth, email, password);
            //   const user = auth.currentUser;
            //   await linkWithPopup(user, provider);
            // }

            setMessageShow("Successfully registration"); // Clear previous error if any
            setAlertType(true);
            setShowAlert(true);

            // Wait for 1000 milliseconds (1 second) and then redirect
            setTimeout(() => {
              history.push({
                pathname: "/auth/",
                state: { restaurantId: restaurantId },
              });
            }, 1500);
          } else {
            setMessageShow(res.error);
            setShowAlert(true);
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("fetch api fail");
        });
    }
  };

  const handleGoogleSignIn = async () => {
    signInWithPopup(auth, provider)
      .then(async (result) => {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const userFirebaseUUID = result._tokenResponse.idToken;
        const loginMethod = credential.signInMethod;
        const googleEmail = result.user.reloadUserInfo.email;
        const userUID = result.user.uid
        await loginUser(googleEmail, password, role, userFirebaseUUID, loginMethod, userUID);
      })
      .catch((error) => {
        console.error("Error when sign up with Google:", error);
      });
  };

  return (
    <div className="hk-pg-wrapper py-0">
      <ToastContainer />
      <div className="hk-pg-body py-0">
        <Container fluid>
          <Row className="auth-split">
            <Col
              xl={5}
              lg={6}
              md={5}
              className="d-md-block d-none bg-primary-dark-3 bg-opacity-85 position-relative"
            >
              <img className="bg-img" src={signupBg} alt="bg-img" />
              <div className="auth-content py-8">
                <Row>
                  <Col xxl={8} className="mx-auto">
                    <div className="text-center">
                      <h3 className="text-white mb-7">
                        This page belongs to the guest registration page!
                      </h3>
                    </div>
                    <div className=" text-white ">
                      <p>What can our service bring to you?</p>
                    </div>
                    <ul className="list-icon text-white mt-3">
                      <li className="mb-1">
                        <p>
                          <i className="ri-check-fill" />
                          <span>
                            Reducing invitation distribution time by sending
                            invite links.
                          </span>
                        </p>
                      </li>
                      <li className="mb-1">
                        <p>
                          <i className="ri-check-fill" />
                          <span>
                            Effective communication with restaurants through the
                            system's operation for seamless reservations.
                          </span>
                        </p>
                      </li>
                      <li className="mb-1">
                        <p>
                          <i className="ri-check-fill" />
                          <span>
                            Accurate calculation of attendance to reduce
                            unnecessary expenses and waste.
                          </span>
                        </p>
                      </li>
                    </ul>
                    <Row className="gx-3 mt-7">
                      <Col lg={12}>
                        <Card className="card-shadow">
                          <Card.Img
                            variant="top"
                            src={slide1}
                            alt="Card img cap"
                          />
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col
              xl={7}
              lg={6}
              md={7}
              sm={10}
              className="position-relative mx-auto"
            >
              <div className="auth-content flex-column pt-8 pb-md-8 pb-13">
                <div className="text-center mb-7">
                  <Link to="/" className="navbar-brand me-0">
                    <img
                      className="brand-img d-inline-block img-fluid"
                      width={"85px"}
                      src={logo}
                      alt="brand"
                    />
                  </Link>
                </div>
                <Form className="w-100" onSubmit={(e) => handleSubmit(e)}>
                  <Row>
                    <Col xxl={5} xl={7} lg={10} className="mx-auto">
                      <h5 className="text-center mb-2">
                        Create A New Client Account
                      </h5>

                      <div className="title-sm title-wth-divider  my-4"></div>
                      <Alert
                        variant={alertType ? "success" : "danger"}
                        show={showAlert}
                      >
                        {messageShow}
                      </Alert>
                      <Row className="gx-3">
                        <Col lg={12} as={Form.Group} className="mb-3">
                          <Form.Label>Name</Form.Label>
                          <Form.Control
                            placeholder="Enter your name"
                            value={name}
                            type="text"
                            id="memberName"
                            onChange={(e) => {
                              if (e.target.value.length <= 10) {
                                setName(e.target.value);
                              }
                            }}
                            required
                          />
                          <div className="text-muted" style={{ fontSize: '10px' }}>
                            Maximum 10 characters for username
                          </div>
                        </Col>
                        <Col lg={12} as={Form.Group} className="mb-3">
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            placeholder="Enter your email"
                            value={email}
                            type="email"
                            id="memberEmail"
                            onChange={(e) => setEmail(e.target.value)}
                            required
                          />
                        </Col>
                        <Col lg={12} as={Form.Group} className="mb-3">
                          <Form.Label>Phone No.</Form.Label>
                          <InputGroup>
                            <InputGroup.Text>+60</InputGroup.Text>
                            <InputMask
                              className="form-control"
                              mask="99-99999999999999999999999"
                              maskChar={null}
                              placeholder="Enter your phone number"
                              value={clientContact}
                              type="text"
                              id="memberContact"
                              onChange={handlePhoneNoChange}
                              required
                            />
                          </InputGroup>
                        </Col>
                        <Col lg={12} as={Form.Group} className="mb-3">
                          <Form.Label>Password</Form.Label>
                          <InputGroup className="password-check">
                            <span className="input-affix-wrapper affix-wth-text">
                              <Form.Control
                                placeholder="Enter your password"
                                value={password}
                                id="memberPassword"
                                type={showPassword ? "text" : "password"}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                              />
                              <div style={{ fontSize: '10px', color: '#6c757d', marginTop: '3px' }}>
                                Password must be at least 6 characters long
                              </div>
                              <Link
                                to="#"
                                className="input-suffix text-primary text-uppercase fs-8 fw-medium"
                                onClick={() => setShowPassword(!showPassword)}
                              >
                                {showPassword ? (
                                  <span>Hide</span>
                                ) : (
                                  <span>Show</span>
                                )}
                              </Link>
                            </span>
                          </InputGroup>
                        </Col>
                        <Col lg={12} as={Form.Group} className="mb-3">
                          <Form.Label>Confirm Password</Form.Label>
                          <InputGroup className="password-check">
                            <span className="input-affix-wrapper affix-wth-text">
                              <Form.Control
                                placeholder="Enter your confirm password"
                                value={confirmPassword}
                                type={showConfirmPassword ? "text" : "password"}
                                id="memberConfirmPassword"
                                onChange={(e) =>
                                  setConfirmPassword(e.target.value)
                                }
                                required
                              />
                              <Link
                                to="#"
                                className="input-suffix text-primary text-uppercase fs-8 fw-medium"
                                onClick={() =>
                                  setShowConfirmPassword(!showConfirmPassword)
                                }
                              >
                                {showConfirmPassword ? (
                                  <span>Hide</span>
                                ) : (
                                  <span>Show</span>
                                )}
                              </Link>
                            </span>
                          </InputGroup>
                        </Col>
                      </Row>

                      <Form.Check id="logged_in" className="form-check-sm mb-3">
                        <Form.Check.Input type="checkbox" required />
                        <Form.Check.Label className="text-muted fs-7">
                          By creating an account you specify that you have read
                          and agree with our{" "}
                          <a
                            href={termsNConditions}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Terms & Conditions
                          </a>{" "}
                          and{" "}
                          <a
                            href={privacyPolicy}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Privacy Policy
                          </a>
                          .
                        </Form.Check.Label>
                      </Form.Check>
                      <Button
                        variant="primary"
                        className="btn-rounded btn-uppercase btn-block"
                        type="submit"
                        to="login"
                        id="createAccount"
                      >
                        Create account
                      </Button>
                      <div className="text-center my-2"><strong>OR</strong></div>
                      <div className="mt-3 d-flex justify-content-center">
                        <button id="signInWithGoogle" style={buttonStyle} onClick={handleGoogleSignIn}>
                          <img style={imgStyle} src="https://www.svgrepo.com/show/475656/google-color.svg" loading="lazy" alt="google logo" />
                          <span>Sign Up with Google</span>
                        </button>
                      </div>
                      <p className="p-xs mt-2 text-center">
                        Already a member ?{" "}
                        <Link to="/auth/" id="signinLink">
                          <u>Sign In</u>
                        </Link>
                      </p>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default CustomerSignup;
