import { useState } from "react";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

// eslint-disable-next-line no-undef
const backendUri = process.env.REACT_APP_BACKEND_DOMAIN;
const useAdminService = () => {
  const [adminInfo, setAdminInfo] = useState("");
  const [success, setSuccess] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(null);
  const accessToken = Cookies.get("token");

  const fetchProfileInfo = async () => {
    try {
      const response = await fetch(`${backendUri}/api/v0/admin/profile`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.ok) {
        const eventData = await response.json();
        setAdminInfo(eventData);
      }
    } catch (error) {
      console.error("Error fetching admin profile information:", error);
    }
  };

  const updateProfileInfo = (admin) => {
    try {
      const adminName = admin.adminName;
      const adminContact = admin.adminContact;
      const adminEmail = admin.adminEmail;

      setIsSubmitting(true);
      fetch(`${backendUri}/api/v0/admin/profile`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ adminName, adminContact, adminEmail }),
      })
        .then((res) => {
          return res.json();
        })
        .then((res) => {
          if (res.error) {
            toast.error(res.message, {
              containerId: "toast-container-admin",
            });
            setSuccess(false);
          } else {
            toast.success(res.message, {
              containerId: "toast-container-admin",
            });
            setSuccess(true);
            //props.handleCloseForm();
          }
        })
        .catch((err) => {
          console.log(err);
          console.error("Fetch API fail");
          setSuccess(false);
        });
    } catch (error) {
      console.error("Error updating admin profile information:", error);
    }
  };

  const updateAdminProfilePassword = (admin) => {
    try {
      const oldPassword = admin.oldPassword;
      const newPassword = admin.newPassword;
      const confirmPassword = admin.confirmPassword;

      setIsSubmitting(true);
      fetch(`${backendUri}/api/v0/admin/profile/password`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({oldPassword, newPassword, confirmPassword }),
      })
        .then((res) => {
          return res.json();
        })
        .then((res) => {
          if (res.error) {
            toast.error(res.message, {
              containerId: "toast-container-admin",
            });
            setSuccess(false);
          } else {
            toast.success(res.message, {
              containerId: "toast-container-admin",
            });
            setSuccess(true);
            //props.handleCloseForm();
          }
        })
        .catch((err) => {
          console.log(err);
          console.error("Fetch API fail");
          setSuccess(false);
        });
    } catch (error) {
      console.error("Error updating admin profile information:", error);
    }
  };

  const clearSuccess = () => {
    setSuccess(null);
  };

  const clearIsSubmitting = () => {
    setIsSubmitting(null);
  };

  return {
    fetchProfileInfo,
    adminInfo,
    updateProfileInfo,
    updateAdminProfilePassword,
    success,
    isSubmitting,
    clearSuccess,
    clearIsSubmitting
  };
};

export default useAdminService;
