import React, { useEffect, useState } from "react";
import { Dropdown, Nav, Spinner } from "react-bootstrap";
import SimpleBar from "simplebar-react";
import * as Icons from "tabler-icons-react";
import HkBadge from "../../components/@hk-badge/@hk-badge";
import Cookies from "js-cookie";

import { onMessage } from "firebase/messaging";
import { messaging } from "../../services/firebaseService"
import useEventServices from "../../services/eventService";
import { useAuth } from "../../services/authContext";
import EventsDrawer from "./EventsDrawer/EventsDrawer";


const Notifications = () => {
  const [notificationsEvents, setNotificationsEvents] = useState("");
  const [showSidebar, setShowSidebar] = useState(false);
  const screenWidth = window.innerWidth;

  const { user } = useAuth();
  const {
    pendingEvents,
    fetchEventInfo,
    eventInfo,
    getEventsByStatus,
  } = useEventServices();
  const token = useState(Cookies.get("token"));
  const [showEventInfo, setShowEventInfo] = useState(false);
  const [targetEvent, setTargetEvent] = useState("");
  const [eventId, setEventId] = useState("");

  const getPendingEvents = () => {
    getEventsByStatus("pending");
  };

  useEffect(() => {
    getPendingEvents();
  }, []);

  useEffect(() => {
    if (token && user.role === "admin") {
      getEventsByStatus("pending");
      const interval = setInterval(() => {
        getEventsByStatus("pending");
      }, 10000); // 10000 milliseconds = 10 seconds

      return () => clearInterval(interval);
    }
  }, []);


  useEffect(() => {
    setNotificationsEvents(pendingEvents);
  }, [pendingEvents]);

  const closeSideBar = () => {
   
    setShowEventInfo(!showEventInfo);
    setTargetEvent("");
  };

  useEffect(() => {
    setTargetEvent(eventInfo);
  }, [eventInfo]);


  const readAndEditEvent = (eventsId) => {
    setShowEventInfo(true);
    // setAdminNotification(true)
    fetchEventInfo(eventsId);
  };

  useEffect(() => {
    if (eventId !== "") {
      const fetchEventDetails = async () => {
        try {
          const event = await fetchEventInfo(eventId);
          if (event) {
            setTargetEvent(event);
            setShowEventInfo(true);
            setEventId("");
          }
        } catch (error) {
          console.error("Failed to fetch event details:", error);
        }
      };
      fetchEventDetails();
    }
  }, [eventId]);

  useEffect(() => {
    if (user.role === "admin") {
      onMessage(messaging, (payload) => {
        if (payload && payload.notification) {
          const { title, body } = payload.notification;
          const payloadEventId = payload.data.eventId;
          if (payloadEventId) {
            setEventId(payloadEventId);
          }
          if (body) {
            if (Notification.permission === "granted") {
              new Notification(title, { body });
            }
          } else {
            console.error("Notification body is undefined.");
          }
        } else {
          console.error("Payload notification is undefined.");
        }
      });
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register('/service-worker.js')
          .then((registration) => {
            navigator.serviceWorker.addEventListener('message', (event) => {
              const payloadEventId = event.data.data.eventId;
              if (payloadEventId) {
                setEventId(payloadEventId);
              }
            });
          }).catch((error) => {
            console.error('Service Worker registration failed:', error);
          });
      }
    }
  }, []);

  return (
    <>
      {user.role === "admin" ? (
        <Nav.Item className="w-100 me-2">
          <Dropdown className="dropdown-notifications ps-2">
            <Dropdown.Toggle
              variant="flush-primary"
              className="btn-icon btn-rounded flush-soft-hover no-caret avatar avatar-rounded avatar-xs font-monospace d-flex flex-column justify-content-center w-100"
              id="notification"
            >
              <span className="d-flex justify-content-center w-100"><Icons.Bell/></span>
              {Array.isArray(pendingEvents) && !showEventInfo ? (
                <HkBadge
                  bg="primary"
                  soft
                  pill
                  size="sm"
                  className="position-top-end-overflow-1"
                >
                  {pendingEvents.length}
                </HkBadge>
              ) : null}
              <span className="d-flex justify-content-center w-100" style={{ fontSize: '0.56rem' }}>Notices</span>
            </Dropdown.Toggle>
            <Dropdown.Menu
              align="end"
              className={`p-0 custom-dropdown-menu ${
                showSidebar ? "show-sidebar" : ""
              }`}
              style={{
                whiteSpace: "nowrap", // Ensure text doesn't wrap
                overflowX: "auto", // Enable horizontal scrolling
                maxWidth: "18.75rem", // Conditionally set max width
                transform: "translateX(80px)", // Move the dropdown menu 50px to the right
              }}
            >
              <Dropdown.Header className="px-4 fs-6">
                Notifications
              </Dropdown.Header>
              <SimpleBar className="dropdown-body p-2">
                {!pendingEvents ? (
                  <div className="text-center py-20">
                    <Spinner />
                  </div>
                ) : Array.isArray(notificationsEvents) ? (
                  notificationsEvents.map((item, index) => (
                    <Dropdown.Item
                      key={item._id}
                      onClick={() => readAndEditEvent(item._id)}
                    >
                      <div className="media">
                        <div className="media-body" id={`notification-${item.title}`}>
                          <div>
                            <div className="notifications-text sm">
                              {item.title}
                            </div>
                            <div style={{ fontSize: "0.70rem" }}>
                              Booking on {item.eventDate} at{" "}
                              {item.eventTime}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Dropdown.Item>
                  ))
                ) : (
                  <div className="text-center py-20">
                    <p style={{ fontSize: "0.8rem", wordWrap: "break-all" }}>
                      No notifications at the moment
                    </p>
                  </div>
                )}
              </SimpleBar>
              {/* <div className="dropdown-footer">
                <Link to="#">
                  <u>View all notifications</u>
                </Link>
              </div> */}
            </Dropdown.Menu>
          </Dropdown>
        </Nav.Item>
      ) : null}{" "}
      {showEventInfo ? (
        <EventsDrawer
          show={showEventInfo}
          event={targetEvent}
          onClose={closeSideBar}
          readAndEditEvent={readAndEditEvent}
          token={token}
          adminNotification={true}
          // setAdminNotification={setAdminNotification}
          getPendingEvents={getPendingEvents}
        />
      ) : null}
    </>
  );
};

export default Notifications;
