import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaHeart, FaRegHeart } from 'react-icons/fa';
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";

import useClientServices from '../../../services/clientService';


const backendUri = process.env.REACT_APP_BACKEND_DOMAIN;
const RestaurantCardFooter = ({ item, disableMoreDetails=false }) => {
  const [token, setToken] = useState(Cookies.get("token"));
  const [decodedToken, setDecodedToken] = useState(null);
  const { fetchClientInfo, clientInfo } = useClientServices();
  const [isLiked, setIsLiked] = useState(false);

  useEffect(() => {
    if (token) {
      const decoded = jwtDecode(token);
      setDecodedToken(decoded);
    }
  }, [token]);

  useEffect(() => {
    if (decodedToken) {
      fetchClientInfo(decodedToken.userId);
    }
  }, [decodedToken]);

  useEffect(() => {
    const checkIfInFavRestaurants = async() => {
      if (clientInfo && clientInfo.favRestaurants && clientInfo.favRestaurants.includes(item._id)) {
        setIsLiked(true);
      }
    };
    checkIfInFavRestaurants();
  }, [clientInfo, item]);

  const toggleUpdateFavourites = async () => {
    try {
      const response = await fetch(`${backendUri}/api/v0/client/favRestaurants/${decodedToken.userId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          restaurantId: item._id
        })
      })
      if (response.ok) {
        setIsLiked(!isLiked);
      } else {
        const error = await response.json();
        console.error('Error updating favourite restaurant. ', error.message);
      }
    } catch (error) {
      console.error('Error updating favourite status: ', error);
    }
  };

  const handleClickCounter = async (id) => {
    // Update "More details" click counter for each restaurant
    const timestamp = new Date();
    await fetch(`${backendUri}/api/v0/public/restaurants/counter/${id}?timestamp=${encodeURIComponent(timestamp)}`)
  }

  return (
    <>
      {!disableMoreDetails ? (
        <Link
          onClick = {() => handleClickCounter(item._id)}
          to={`/restaurants/${item._id}`}
          className="d-flex align-items-center"
        >
          <span className="fs-7 lh-1" id={`moreDetails-${item.restaurantName}`}>More details</span>
        </Link>
      ) : null}
      <button
        onClick={toggleUpdateFavourites}
        className="btn btn-link position-absolute end-0 top-0"
        style={{ border: 'none', background: 'none' }}
      >
        {isLiked ? <FaHeart color="red" /> : <FaRegHeart />}
      </button>
    </>
  );
};

export default RestaurantCardFooter;