import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { ExternalLink } from "react-feather";


const konfemHPNo = process.env.REACT_APP_KONFEM_HP_NO;
const PageFooter = () => {
  return (
    <div className='hk-footer' style={{ zIndex: 10 }}>
      <Container as='footer' className='footer'>
        <Row style={{ borderCollapse: 'collapse', marginBottom: '0px', paddingBottom: '0px' }}>
          <Col>
            <p style={{ color: '#999', fontSize: '12px' }}>
              Made Simple to book and reserve your favourite restaurants.
            </p>
            <p style={{ color: '#999', fontSize: '12px' }}>
              Konfem © {new Date().getFullYear()} All rights reserved.
            </p>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs={4} md={2} className='text-center'>
            <a
              href={`https://www.instagram.com/s/aGlnaGxpZ2h0OjE4MDQ3ODUwOTE2NzgwNjE3?story_media_id=3383015910716412782_65231517890&igsh=MTV4emo3cnRzaHB1dA%3D%3D`}
              className="footer-extr-link link-default"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="feather-icon">
                <ExternalLink />
              </span>
              <u>How to use</u>
            </a>
          </Col>

          <Col xs={4} md={2} className='text-center'>
            <a
              href={`https://konfem.com/partner-suggestion/`}
              className="footer-extr-link link-default"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="feather-icon">
                <ExternalLink />
              </span>
              <u>Partner Suggestion</u>
            </a>
          </Col>

          <Col xs={4} md={2} className='text-center'>
            <a
              href={`https://wa.me/+6${konfemHPNo}`}
              className="footer-extr-link link-default"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="feather-icon">
                <ExternalLink />
              </span>
              <u>Send feedback</u>
            </a>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PageFooter;
