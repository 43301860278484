import { useState, useEffect } from "react";
import { Modal, Button, Form, Row, InputGroup } from "react-bootstrap";
import { toast } from "react-toastify";
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-thumbnail.css";


const backendUri = process.env.REACT_APP_BACKEND_DOMAIN;
const EditPackageModal = (props) => {
  const [updatePackageName, setPackageName] = useState("");
  const [updatePackagePrice, setPackagePrice] = useState("");
  const [updatePackageImage, setPackageImage] = useState(null);
  const [updateDepositRequired, setDepositRequired] = useState(false);
  const [updateDepositAmount, setDepositAmount] = useState("");
  const [updateGotFile, setGotFile] = useState(false);
  const [fileName, setFileName] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(null)

  useEffect(() => {
    fetchPackageData(props.packageId);
  }, [props.showForm]);

  const fetchPackageData = async (id) => {
    try {
      const response = await fetch(
        `${backendUri}/api/v0/admin/packages/${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${props.Token}`,
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        setPackageName(data.packageName || "");
        setPackagePrice(data.packagePrice || "");
        setDepositRequired(data.depositRequired || false);
        setDepositAmount(data.depositAmount || "");
        setFileName(data.packageImage || "");
      } else {
        console.error("Fail to fetch package data");
      }
    } catch (error) {
      console.error("Error fetching package data");
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const formData = new FormData();

    formData.append("packageName", updatePackageName);
    formData.append("packagePrice", updatePackagePrice);
    formData.append('depositRequired', updateDepositRequired);
    formData.append('depositAmount', updateDepositAmount);
    formData.append("gotFile", updateGotFile);
    if (updatePackageImage) {
      formData.append("file", updatePackageImage[0]);
    }

    let isTimedOut = false;

    const timeout = setTimeout(() => {
      isTimedOut = true;
      setIsSubmitting(false);
      toast.error("Request timed out. Please try again later.", {
        containerId: "error-container",
      });
      props.handleCloseForm();
    }, 5000);

    fetch(`${backendUri}/api/v0/admin/packages/${props.packageId}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${props.Token}`,
      },
      body: formData,
    })
      .then((res) => {
        clearTimeout(timeout);
        return res.json();
      })
      .then((res) => {
        if (res.error) {
          toast.error(`${res.message}`, {
            containerId: "error-container",
          });
        } else {
          toast.success(res.message, {
            containerId: "success-container",
          });
          props.handleCloseForm();
        }
        props.fetchData(props.Token);
      })
      .catch((error) => {
        console.error("Error updating package : ", error.message);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const closeModal = () => {
    props.handleCloseForm();
    setPreviewUrl("")
  }

  return (
    <>
      <Modal show={props.showForm} onHide={closeModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title> Edit Package </Modal.Title>
        </Modal.Header>
        <Form style={{ margin: 3 }} onSubmit={(e) => handleUpdate(e)}>
          <Modal.Body>
            <Row className="mb-3">
              <Form.Group controlId="editPackageName">
                <Form.Label> Package Name* </Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <i className="fa fa-tag" />
                  </InputGroup.Text>
                  <Form.Control
                    required
                    type="text"
                    name="packageName"
                    value={updatePackageName}
                    onChange={(e) => {
                      setPackageName(e.target.value);
                    }}
                  />
                </InputGroup>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group controlId="editPackagePrice">
                <Form.Label> Package Price* </Form.Label>
                <InputGroup>
                  <InputGroup.Text>RM</InputGroup.Text>
                  <Form.Control
                    required
                    type="text"
                    name="packagePrice"
                    value={updatePackagePrice}
                    onChange={(e) => {
                      setPackagePrice(e.target.value);
                    }}
                  />
                </InputGroup>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group controlId="editPackageImage">
                <Form.Label> Package Image </Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <i className="fa fa-image" />
                  </InputGroup.Text>
                  <Form.Control
                    type="file"
                    accept="image/*"
                    name="packageImage"
                    onChange={(e) => {
                      if (e.target.files.length > 0) {
                        setPackageImage(e.target.files);
                        setPreviewUrl(URL.createObjectURL(e.target.files[0]))
                        setGotFile(true);
                      } else {
                        setGotFile(false);
                      }
                    }}
                  />
                </InputGroup>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group controlId="previewPackageImage">
                <Form.Label> Preview Uploaded Image: </Form.Label>
                <div className="d-flex flex-column">
                  {fileName ? (
                    // <img
                    //   src={`${fileName}?timestamp=${Date.now()}`}
                    //   alt="Preview"
                    //   className="preview-image img-fluid"
                    //   style={{
                    //     maxWidth: '50%', height: 'auto',
                    //   }}
                    // />
                    <img
                      src={
                        previewUrl
                          ? previewUrl
                          : `${fileName}?timestamp=${Date.now()}`
                      }
                      alt="package_image"
                      className="avatar-img"
                    />
                  ) : (
                    <span className="ms-3">No file uploaded</span>
                  )}
                </div>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group controlId="editDepositRequired">
                <Form.Check 
                  type="checkbox"
                  label="Deposit Required"
                  checked={updateDepositRequired}
                  onChange={(e) => setDepositRequired(e.target.checked)}
                />
              </Form.Group>
              <div style={{ marginBottom: '16px' }} />
              {updateDepositRequired && (
                <Form.Group controlId="editDepositAmount">
                  <Form.Label>Deposit Amount</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      RM
                    </InputGroup.Text>
                    <Form.Control 
                      required
                      type="text"
                      placeholder="Enter Package Deposit Amount"
                      value={updateDepositAmount}
                      onChange={(e) => {
                        setDepositAmount(e.target.value);
                      }}
                    />
                  </InputGroup>
                </Form.Group>
              )}
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="soft-primary"
              id="editPackageSubmitBtn"
              type="submit"
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <>
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Submitting...</span>
                </>
              ) : (
                "Update"
              )}
            </Button>
            <Button
              variant="soft-secondary"
              onClick={closeModal}
              id="editCloseBtn"
            >
              Close
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default EditPackageModal;
