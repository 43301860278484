//import { faFacebook, faGoogle } from "@fortawesome/free-brands-svg-icons";
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
  Alert,
} from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import logo from "../../../../assets/dist/img/logo.png";
import signupBg from "../../../../assets/dist/img/signup-bg.jpg";
import slide2 from "../../../../assets/developImg/adminSlide2.jpg";
import CommanFooter1 from "../../CommanFooter1";


const AdminSignup = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [role, setRole] = useState("admin");
  const [messageShow, setMessageShow] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const history = useHistory();
  // eslint-disable-next-line no-undef
  const backendUri = process.env.REACT_APP_BACKEND_DOMAIN;
  const handleSubmit = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      toast.error("Password did not match");
    } else {
      fetch(`${backendUri}/api/v0/auth/signup`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ role, name, email, password }),
      })
        .then((res) => {
          return res.json();
        })
        .then((res) => {
          if (res.token) {
            setMessageShow("Successfully registration"); // Clear previous error if any
            setAlertType(true);
            setShowAlert(true);

            // Wait for 1000 milliseconds (1 second) and then redirect
            setTimeout(() => {
              history.push("/auth/partner");
            }, 1500);
          } else {
            setMessageShow(res.error);
            setShowAlert(true);
          }
        })
        .catch((error) => {
          console.log("fetch api fail");
        });
    }
  };

  return (
    <div className="hk-pg-wrapper py-0">
      <ToastContainer />
      <div className="hk-pg-body py-0">
        <Container fluid>
          <Row className="auth-split">
            <Col
              xl={7}
              lg={6}
              md={7}
              sm={10}
              className="position-relative mx-auto"
            >
              <div className="auth-content flex-column pt-8 pb-md-8 pb-13">
                <div className="text-center mb-7">
                  <Link to="/" className="navbar-brand me-0">
                    <img
                      className="brand-img d-inline-block img-fluid"
                      width={"85px"}
                      src={logo}
                      alt="brand"
                    />
                  </Link>
                </div>
                <Form className="w-100" onSubmit={(e) => handleSubmit(e)}>
                  <Row>
                    <Col xxl={5} xl={7} lg={10} className="mx-auto">
                      <h5 className="text-center mb-2">
                        Create A New Admin Account
                      </h5>
                      <p className="text-center mb-1 p-xs">
                        Click here for create{" "}
                        <Link to="/auth/customer-signup" id="clientSignupLink">
                          <u>client account</u>
                        </Link>
                      </p>
                      <div className="title-sm title-wth-divider  my-4"></div>
                      <Alert
                        variant={alertType ? "success" : "danger"}
                        show={showAlert}
                      >
                        {messageShow}
                      </Alert>
                      <Row className="gx-3">
                        <Col lg={12} as={Form.Group} className="mb-3">
                          <Form.Label>Name</Form.Label>
                          <Form.Control
                            placeholder="Enter your name"
                            value={name}
                            type="text"
                            id="memberName"
                            onChange={(e) => {
                              if (e.target.value.length <= 10) {
                                setName(e.target.value);
                              }
                            }}
                            required
                          />
                          <div className="text-muted" style={{ fontSize: '10px' }}>
                            Maximum 10 characters for username
                          </div>
                        </Col>
                        <Col lg={12} as={Form.Group} className="mb-3">
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            placeholder="Enter your email"
                            value={email}
                            type="email"
                            id="memberEmail"
                            onChange={(e) => setEmail(e.target.value)}
                            required
                          />
                        </Col>
                        <Col lg={12} as={Form.Group} className="mb-3">
                          <Form.Label>Password</Form.Label>
                          <InputGroup className="password-check">
                            <span className="input-affix-wrapper affix-wth-text">
                              <Form.Control
                                placeholder="Enter your password"
                                value={password}
                                id="memberPassword"
                                type={showPassword ? "text" : "password"}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                              />
                              <div style={{ fontSize: '10px', color: '#6c757d', marginTop: '3px' }}>
                                Password must be at least 6 characters long
                              </div>
                              <Link
                                to="#"
                                className="input-suffix text-primary text-uppercase fs-8 fw-medium"
                                onClick={() => setShowPassword(!showPassword)}
                              >
                                {showPassword ? (
                                  <span>Hide</span>
                                ) : (
                                  <span>Show</span>
                                )}
                              </Link>
                            </span>
                          </InputGroup>
                        </Col>
                        <Col lg={12} as={Form.Group} className="mb-3">
                          <Form.Label>Confirm Password</Form.Label>
                          <InputGroup className="password-check">
                            <span className="input-affix-wrapper affix-wth-text">
                              <Form.Control
                                placeholder="Enter your confirm password"
                                value={confirmPassword}
                                type={showConfirmPassword ? "text" : "password"}
                                id="memberConfirmPassword"
                                onChange={(e) =>
                                  setConfirmPassword(e.target.value)
                                }
                                required
                              />
                              <Link
                                to="#"
                                className="input-suffix text-primary text-uppercase fs-8 fw-medium"
                                onClick={() =>
                                  setShowConfirmPassword(!showConfirmPassword)
                                }
                              >
                                {showConfirmPassword ? (
                                  <span>Hide</span>
                                ) : (
                                  <span>Show</span>
                                )}
                              </Link>
                            </span>
                          </InputGroup>
                        </Col>
                      </Row>

                      <Form.Check id="logged_in" className="form-check-sm mb-3">
                        <Form.Check.Input type="checkbox" required />
                        <Form.Check.Label className="text-muted fs-7">
                          By creating an account you specify that you have read
                          and agree with our Terms of use and Privacy policy.
                          We may keep you inform about latest updates through
                          our default notification settings
                        </Form.Check.Label>
                      </Form.Check>
                      <Button
                        variant="primary"
                        className="btn-rounded btn-uppercase btn-block"
                        type="submit"
                        to="login"
                      >
                        Create account
                      </Button>
                      <p className="p-xs mt-2 text-center">
                        Already a member ?{" "}
                        <Link to="/auth/partner" id="signinLink">
                          <u>Sign In</u>
                        </Link>
                      </p>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Col>
            <Col
              xl={5}
              lg={6}
              md={5}
              className="d-md-block d-none bg-primary-dark-3 bg-opacity-85 position-relative"
            >
              <img className="bg-img" src={signupBg} alt="bg-img" />
              <div className="auth-content py-8">
                <Row>
                  <Col xxl={8} className="mx-auto">
                    <div className="text-center">
                      <h3 className="text-white mb-6">
                        This page belongs to the restaurant admin registration
                        page!
                      </h3>
                    </div>
                    <div className=" text-white ">
                      <p>What can our service bring to you?</p>
                    </div>
                    <ul className="list-icon text-white mt-3">
                      <li className="mb-1">
                        <p>
                          <i className="ri-check-fill" />
                          <span>
                            Guests confirm dates easily, minimizing
                            communication gaps with restaurants.
                          </span>
                        </p>
                      </li>
                      <li className="mb-1">
                        <p>
                          <i className="ri-check-fill" />
                          <span>
                            Restaurants optimize seating and streamline food
                            procurement through analysis.
                          </span>
                        </p>
                      </li>
                      <li className="mb-1">
                        <p>
                          <i className="ri-check-fill" />
                          <span>
                            Cost savings achieved by staffing optimization,
                            reduced overstaffing, and improved operational
                            efficiency.
                          </span>
                        </p>
                      </li>
                    </ul>
                    <Row className="gx-3 mt-7">
                      <Col lg={12}>
                        <Card className="card-shadow">
                          <Card.Img
                            variant="top"
                            src={slide2}
                            alt="Card img cap"
                          />
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default AdminSignup;
