import React from 'react';
import { Col, Container, Nav, Row, Tab } from 'react-bootstrap';

import ClientInfo from './clientInfo';
import AccountManagement from './accountManagement';


const ClientProfile = () => {
  return (
    <Container>
      <div className='hk-pg-header pt-7 pb-4'>
        <h1 className='pg-title'>Client Profile</h1>
      </div>
      <div className='hk-pg-body'>
        <Tab.Container defaultActiveKey="tab1">
          <Row className='edit-profile-wrap'>
            <Col xs={4} sm={3} lg={2}>
              <div className="nav-profile mt-4">
                <div className="nav-header">
                  <span>Profiles</span>
                </div>
                <Nav as="ul" variant="tabs" className="nav-light nav-vertical">
                  <Nav.Item as="li">
                    <Nav.Link eventKey="tab1">
                      <span id="tab1" className="nav-link-text">Client Profile</span>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li">
                    <Nav.Link eventKey="tab2">
                      <span id="tab2" className="nav-link-text">Account Management</span>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
            </Col>
            <Col lg={10} sm={9} xs={8}>
              <Tab.Content>
                <Tab.Pane eventKey="tab1">
                  <ClientInfo />
                </Tab.Pane>
                <Tab.Pane eventKey="tab2">
                  <AccountManagement />
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    </Container>
  )
};

export default ClientProfile;