import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
  Alert,
  Spinner,
} from "react-bootstrap";
import { AlertCircle } from "react-feather";
import { Link, useHistory, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Cookies from "js-cookie";

import logo from "../../../../assets/dist/img/logo.png";
import invitationImg from "../../../../assets/developImg/invitation.jpg";
import reservationImg from "../../../../assets/developImg/reservation.jpg";
import { useAuth } from "../../../../services/authContext";
import { auth } from "../../../../services/firebaseService";
import {
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  linkWithPopup,
} from "firebase/auth";


// eslint-disable-next-line no-undef
const backendUri = process.env.REACT_APP_BACKEND_DOMAIN;
const Login = () => {
  const [email, setEmail] = useState("");
  const role = "client";
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const history = useHistory();
  const { login, setLoginMethod, loginMethod } = useAuth();
  const [loading, setLoading] = useState(false);
  const [navigateRestaurant, setNavigateRestaurant] = useState("/");
  const location = useLocation();
  const restaurantId = location.state ? location.state.restaurantId : null; // Accessing restaurantId from location state
  const provider = new GoogleAuthProvider();

  const buttonStyle = {
    padding: '5px 20px',
    border: '0px',
    display: 'block',
    gap: '10px',
    borderRadius: '60px',
    color: '#2d3748',
    backgroundColor: '#edf2f7',
    transition: 'all 0.15s ease-in-out',
    width: '100%'
  };
  
  const imgStyle = {width: '24px', height: '24px', marginRight: '10px'};

  useEffect(() => {
    const token = Cookies.get("token");

    if (restaurantId) {
      setNavigateRestaurant(`/restaurants/${restaurantId}`);
    }

    if (token) {
      history.push("/");
    }
  }, [restaurantId]);

  const loginUser = async (
    email,
    password,
    role,
    userFirebaseUUID,
    loginMethod, userUID
  ) => {
    setLoginMethod(loginMethod)
    const response = await fetch(`${backendUri}/api/v0/auth/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        password,
        role,
        userFirebaseUUID,
        loginMethod,
        userUID
      }),
    });
    if (!response.ok) {
      const errorResponse = await response.json();
      setLoading(false);
      setError(errorResponse.error);
      setShowAlert(true);
      return;
    }
 

    const { token } = await response.json();

    if (token) {
      Cookies.set("token", token);
      await login();
      // const user = auth.currentUser;
      // await linkWithPopup(user, provider)
      
      setTimeout(() => {
        history.push("/");
      }, 500);
    } else {
      setError("Token not found");
      setShowAlert(true);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const userCredentials = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );

      const userFirebaseUUID = userCredentials._tokenResponse.idToken;

      // loginUser(email, password, role, userFirebaseUUID, "accountLogin")
      const loginMethod = "accountLogin";
      await loginUser(email, password, role, userFirebaseUUID, loginMethod, null);

    } catch (error) {
      console.error("Error during login:", error);
      setError("Invalid email or password");
      setShowAlert(true);
      setLoading(false);
    }
  };

  const handleGoogleSignIn = async () => {
    signInWithPopup(auth, provider)
      .then(async (result) => {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const userFirebaseUUID = result._tokenResponse.idToken;
        const loginMethod = credential.signInMethod;
        const googleEmail = result.user.reloadUserInfo.email;
        const userUID = result.user.uid
        await loginUser(googleEmail, password, role, userFirebaseUUID, loginMethod, userUID);
      })
      .catch((error) => {
        console.error("Error when sign in with Google:", error);
      });
  };

  return (
    <div className="hk-pg-wrapper py-0">
      <ToastContainer />
      <div className="hk-pg-body py-0">
        <Container fluid>
          <Row className="auth-split">
            <Col xl={5} lg={6} md={7} className="position-relative mx-auto">
              <div className="auth-content flex-column pt-8 pb-md-8 pb-13">
                <div className="text-center mb-7">
                  <Link to="/" className="navbar-brand me-0">
                    <img
                      className="brand-img d-inline-block img-fluid"
                      width={"120px"}
                      src={logo}
                      alt="brand"
                    />
                  </Link>
                </div>
                <Form className="w-100" onSubmit={(e) => handleSubmit(e)}>
                  <Row>
                    <Col xl={7} sm={10} className="mx-auto">
                      <div className="text-center mb-4">
                        <h4>FOODIE</h4>
                        <h4>Sign in to your account</h4>
                      </div>
                      <p className="p-xs mt-2 text-center mb-2">
                        Login with partner's admin account?{" "}
                        <Link to="partner">
                          <u id="linkAdminLogin">Click here</u>
                        </Link>
                      </p>
                      <Alert variant="danger" show={showAlert}>
                        <span className="feather-icon">
                          <AlertCircle />
                        </span>
                        <span style={{ marginLeft: "5px" }}>{error}</span>
                      </Alert>
                      <Row className="gx-3">
                        <Col as={Form.Group} lg={12} className="mb-3">
                          <div className="form-label-group">
                            <Form.Label>Email</Form.Label>
                          </div>
                          <Form.Control
                            placeholder="Enter email"
                            type="emails"
                            value={email}
                            id="loginEmail"
                            onChange={(e) => setEmail(e.target.value)}
                            required
                          />
                        </Col>
                        <Col as={Form.Group} lg={12} className="mb-6">
                          <div className="form-label-group ">
                            <Form.Label>Password</Form.Label>
                            <Link
                              to="forgot-password"
                              className="fs-7 fw-medium"
                            >
                              <p
                                id="linkForgotPassword"
                                className="text-decoration: none"
                              >
                                Forgot Password?
                              </p>
                            </Link>
                          </div>
                          <InputGroup className="password-check">
                            <span className="input-affix-wrapper affix-wth-text">
                              <Form.Control
                                placeholder="Enter password"
                                value={password}
                                id="loginPassword"
                                onChange={(e) => setPassword(e.target.value)}
                                type={showPassword ? "text" : "password"}
                                required
                              />
                              <div style={{ fontSize: '10px', color: '#6c757d', marginTop: '3px' }}>
                                Password must be at least 6 characters long
                              </div>
                              <Link
                                to="#"
                                className="input-suffix text-primary text-uppercase fs-8 fw-medium"
                                onClick={() => setShowPassword(!showPassword)}
                              >
                                {showPassword ? (
                                  <span>Hide</span>
                                ) : (
                                  <span>Show</span>
                                )}
                              </Link>
                            </span>
                          </InputGroup>
                        </Col>
                      </Row>
                      <Button
                        variant="primary"
                        type="submit"
                        className="btn-uppercase btn-block btn-rounded"
                        id="loginButton"
                        disabled={loading}
                      >
                        {loading ? (
                          <Spinner animation="border" size="sm" />
                        ) : null}
                        {loading ? null : "Login"}
                      </Button>
                      <div className="text-center my-2"><strong>OR</strong></div>
                      <div className="mt-3 d-flex justify-content-center">
                        <button id="signInWithGoogle" style={buttonStyle} onClick={handleGoogleSignIn}>
                          <img style={imgStyle} src="https://www.svgrepo.com/show/475656/google-color.svg" loading="lazy" alt="google logo" />
                          <span>Sign In with Google</span>
                        </button>
                      </div>
                      <p className="p-xs mt-4 text-center">
                        New Member ?{" "}
                        <Link
                          to={{
                            pathname: "/auth/customer-signup",
                            state: { restaurantId: restaurantId },
                          }}
                        >
                          <u id="linkClientSignUp">Create new account</u>
                        </Link>
                      </p>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Col>
            <Col
              xl={7}
              lg={6}
              md={5}
              sm={10}
              className="d-md-block d-none position-relative bg-primary-light-5"
            >
              <div className="auth-content flex-column text-center py-8">
                <Row>
                  <Col xxl={7} xl={8} lg={11} className="mx-auto">
                    <h2 className="mb-5">
                      Reservation has never made this simple!
                    </h2>
                    <p className="mb-3">
                      Clicks away from booking your favorite restaurant and
                      managing RSVP for your important dates
                    </p>

                    <Row>
                      <Col xs lg-2>
                        <Button
                          variant="flush-primary"
                          className="btn-uppercase mt-2"
                          id="clientSignupButton"
                          onClick={() => history.push("/auth/customer-signup")}
                        >
                          lets join us!
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <img
                      src={invitationImg}
                      className="img-fluid w-sm-75 mt-7"
                      alt="login"
                    />
                  </Col>
                  <Col>
                    <img
                      src={reservationImg}
                      className="img-fluid w-sm-75 mt-7"
                      alt="login"
                    />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Login;
