import { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  TabContent,
  Table,
  Form,
  InputGroup,
} from "react-bootstrap";
import { Plus } from "react-feather";
import { ToastContainer } from "react-toastify";
import Cookies from "js-cookie";
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-thumbnail.css';

import image from "../../../src/assets/developImg/default-image.jpg";
import CreateMenuModal from "./CreateMenuModal";
import EditMenuModal from "./EditMenuModal";
import DeleteMenuModal from "./DeleteMenuModal";


const backendUri = process.env.REACT_APP_BACKEND_DOMAIN;
const MenuManagement = () => {
  const [Token, setToken] = useState(Cookies.get("token"));

  const [lists, setList] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredList, setFilteredLists] = useState([...lists]);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentItems = filteredList.slice(indexOfFirstItem, indexOfLastItem);

  const [menuId, setMenuId] = useState([]);

  const [showAddForm, setShowAddForm] = useState(false);
  const [showDeleteForm, setShowDeleteForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    if (searchTerm) {
      const filterItems = lists.filter(
        (item) =>
          item.menuName.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredLists(filterItems);
    } else {
      setFilteredLists([...lists]);
    }
  }, [lists, searchTerm]);

  const handleCreateOpen = () => {
    setShowAddForm(true);
  };
  const handleCreateClose = () => {
    setShowAddForm(false);
  };

  const handleEditOpen = (id) => {
    setMenuId(id);
    setShowEditForm(true);
  };
  const handleEditClose = () => {
    setShowEditForm(false);
  };

  const handleDeleteOpen = (id) => {
    setMenuId(id);
    setShowDeleteForm(true);
  };
  const handleDeleteClose = () => {
    setShowDeleteForm(false);
  };

  useEffect(() => {
    fetchData(Token);
  }, []);

  const fetchData = async (Token) => {
    await fetch(`${backendUri}/api/v0/admin/menu`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data && data.length > 0) {
          setList(data);
        } else {
          setList([]);
        }
      })
      .catch((err) => {
        console.error("Login Failed due to :" + err.message);
      });
  };

  return (
    <>
      <Container>
        <TabContent>
          <Card
            className="card-border mb-0 h-100"
          >
            <Card.Header className="card-header-action">
              <h5>Menu Management</h5>
              <Button
                variant="primary btn-animated"
                size="sm"
                className="ms-3"
                id="addMenuBtn"
                onClick={handleCreateOpen}
              >
                <span>
                  <span className="icon">
                    <span className="feather-icon">
                      <Plus />
                    </span>
                  </span>
                  <span className="bn-text" size="lg">
                    Add new
                  </span>
                </span>
              </Button>
            </Card.Header>
            <Card.Body>
              <InputGroup className="mb-4">
                <Form.Control 
                  placeholder="Search..."
                  className="table-search"
                  type="text"
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </InputGroup>
              <div className="invoice-list-view">
                <div className="table-responsive">
                  <Table hover className="mb-0">
                    <thead>
                      <tr>
                        <th>Menu Name</th>
                        <th>Menu</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody id="dataTable">
                      {currentItems.map((item) => (
                        <tr key={item._id}>
                          <td>{item.menuName}</td>
                          <td>
                            {item.menuFile ? (
                              <Button
                                id="viewMenuBtn"
                                size="sm"
                                variant="outline-success"
                                style={{ margin: 5 }}
                                className="btn-rounded"
                                onClick={() => window.open(item.menuFile)}
                              >
                              View Menu
                              </Button>
                            ) : (
                              <span className="ms-3">
                              No menu uploaded
                              </span>
                            )}
                          </td>
                          <td>
                            <Button
                              id="editBtn"
                              size="sm"
                              variant="soft-primary"
                              style={{ margin: 5 }}
                              className="btn-rounded"
                              onClick={() => {
                                handleEditOpen(item._id);
                              }}
                            >
                            Edit
                            </Button>
                            <Button
                              id="deleteBtn"
                              size="sm"
                              variant="soft-danger"
                              style={{ margin: 5 }}
                              className="btn-rounded"
                              onClick={() => {
                                handleDeleteOpen(item._id);
                              }}
                            >
                            Delete
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <Row>
                    <Col
                      md={{ span: 2, offset: 10 }}
                      xs={{ span: 12, offset: 0 }}
                      className="text-center mt-3"
                    >
                      <Button
                        variant="primary btn-animated"
                        className="btn-icon"
                        disabled={currentPage === 1}
                        onClick={() => paginate(currentPage - 1)}
                      >
                        <span>
                          <i className="ri-arrow-left-s-line" />
                        </span>
                      </Button>
                      <span className="mx-1">{currentPage}</span>
                      <Button
                        variant="primary btn-animated "
                        className="btn-icon"
                        disabled={indexOfLastItem >= lists.length}
                        onClick={() => paginate(currentPage + 1)}
                      >
                        <span>
                          <i className="ri-arrow-right-s-line" />
                        </span>
                      </Button>
                    </Col>
                  </Row>
                </div>
              </div>
            </Card.Body>
          </Card>
        </TabContent>
      </Container>
      <ToastContainer
        position="top-right"
        style={{ top: "701px", right: "20px" }}
        enableMultiContainer
        containerId="error-container"
      />
      <ToastContainer
        position="top-right"
        style={{ top: "70px", right: "20px" }}
        enableMultiContainer
        containerId="success-container"
      />
      <CreateMenuModal
        showForm={showAddForm}
        handleCloseForm={handleCreateClose}
        Token={Token}
        fetchData={fetchData}
      />
      <EditMenuModal
        showForm={showEditForm}
        handleCloseForm={handleEditClose}
        Token={Token}
        menuId={menuId}
        fetchData={fetchData}
      />
      <DeleteMenuModal
        showForm={showDeleteForm}
        handleCloseForm={handleDeleteClose}
        Token={Token}
        menuId={menuId}
        fetchData={fetchData}
      />
    </>
  )
};

export default MenuManagement;