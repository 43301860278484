import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import { Modal, Form, Button, Row, Col, Spinner, InputGroup, FormGroup } from "react-bootstrap";
import InputMask from "react-input-mask";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUtensils, faMapMarkerAlt, faCity, faEnvelope, faGlobe, faMap, faLink, faCreditCard } from '@fortawesome/free-solid-svg-icons';
import { Country, State } from "country-state-city";

import useRestaurantServices from "../../services/restaurantService";


const CreateRestaurantModal = (props) => {
  const [restaurantName, setRestaurantName] = useState("");
  const [restaurantPlacesId, setRestaurantPlacesId] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [country, setCountry] = useState("");
  const [restaurantContact, setRestaurantContact] = useState("");
  const [paymentDetails, setPaymentDetails] = useState("");
  const [refundPolicy, setRefundPolicy] = useState("");
  const [tags, setTags] = useState("");
  const [description, setDescription] = useState("");
  const [addressLink, setAddressLink] = useState("");
  const [link, setLink] = useState("");
  const [restaurantLogo, setRestaurantLogo] = useState(null);
  const [gotFile, setGotFile] = useState(false);
  const [fileName, setFileName] = useState("");
  const [requestType, setRequestType] = useState("");
  const [restaurantId, setRestaurantId] = useState("");
  const [isBoarded, setIsBoarded] = useState(0);
  const [ratings, setRatings] = useState(0);
  const [reviews, setReviews] = useState([]);
  const [photoReferences, setPhotoReferences] = useState([]);
  const [photoFiles, setPhotoFiles] = useState([]);
  const [businessHours, setBusinessHours] = useState();
  const {
    manageRestaurant,
    success,
    clearSuccess,
    isSubmitting,
    clearIsSubmitting,

  } = useRestaurantServices();
  const [countries, setAllCountries] = useState([]);
  const [states, setAllStates] = useState([]);

  const resetForm = () => {
    setRestaurantName("");
    setRestaurantPlacesId("");
    setAddressLine1("");
    setAddressLine2("");
    setCity("");
    setState("");
    setZipCode("");
    setCountry("");
    setRestaurantContact("");
    setPaymentDetails("");
    setRefundPolicy("");
    setTags("");
    setDescription("");
    setAddressLink("");
    setIsBoarded(0);
    setGotFile(false);
    setRestaurantLogo(null);
    setRestaurantId("");
    setRatings(0);
    setReviews([]);
    setPhotoReferences([]);
    setPhotoFiles([]);
  };

  const handlePhoneNo = (e) => {
    const inputPhoneNo = e.target.value;
    const cleanedPhoneNo = inputPhoneNo.replace(/[ -]/g, "");
    setRestaurantContact(cleanedPhoneNo);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const submitData = {
      restaurantId,
      restaurantName,
      restaurantPlacesId,
      addressLine1,
      addressLine2,
      city,
      state,
      zipCode,
      country,
      restaurantContact,
      paymentDetails,
      refundPolicy,
      tags,
      description,
      addressLink,
      isBoarded,
      gotFile,
      restaurantLogo,
      requestType,
      ratings,
      reviews,
      photoReferences,
      photoFiles,
      businessHours,
    };

    // Call the createRestaurant function from the hook and pass the form data
    manageRestaurant(submitData);
  };

  useEffect(() => {
    // Check if there is success, then clear the form fields
    if (success) {
      props.handleCloseForm();
    }

    // if (props.requestType === "POST" && success) {
    //   props.handleCloseForm();
    //   resetForm();
    // }
    if (props.request === "POST" && success) {
      props.handleCloseForm();
      resetForm();
    }
    clearIsSubmitting();
    clearSuccess();
    props.getRestaurants()
  }, [success]);

  useEffect(() => {
    setRequestType(props.request);
    if (props.restaurant) {
      setRestaurantName(props.restaurant.restaurantName);
      setRestaurantPlacesId(props.restaurant.restaurantPlacesId);
      setAddressLine1(props.restaurant.addressLine1);
      setAddressLine2(props.restaurant.addressLine2);
      setCity(props.restaurant.city);
      setState(props.restaurant.state);
      setZipCode(props.restaurant.zipCode);
      setCountry(props.restaurant.country);
      setRestaurantContact(props.restaurant.restaurantContact);
      setPaymentDetails(props.restaurant.paymentDetails);
      setRefundPolicy(props.restaurant.refundPolicy);
      setTags(props.restaurant.tags);
      setDescription(props.restaurant.description);
      setAddressLink(props.restaurant.addressLink);
      setIsBoarded(props.restaurant.isBoarded);
      setLink(props.restaurant.addressLink);
      setFileName(props.restaurant.restaurantLogo);
      setRestaurantId(props.restaurant._id);
      setRatings(props.restaurant.ratings);
      setReviews(props.restaurant.reviews);
      setPhotoFiles(props.restaurant.photoFiles);
      setPhotoReferences(props.restaurant.photoReferences);
      setBusinessHours(props.restaurant.businessHours);
    } else {
      resetForm();
    }
    // setIsLoading(false);
  }, [props.restaurant, props.request]);

  useEffect(() => {
    const countries = Country.getAllCountries();
    setAllCountries(countries);
    if (!props.restaurant) {
      const malaysia = countries.find((country) => country.name === "Malaysia");
      setCountry(malaysia.isoCode);
    }
  }, [props.request]);

  useEffect(() => {
    if (country) {
      const statesForCountry = State.getStatesOfCountry(country);
      setAllStates(statesForCountry);
      if (!props.restaurant) {
        setState("");
      }
    }
  }, [country]);

  const handleCountryChange = (event) => {
    const newCountry = event.target.value;
    setCountry(newCountry);
  };

  const handleStateChange = (event) => {
    const newState = event.target.value;
    setState(newState);
  };

  // Custom description toolbar configuration
  const toolbarOptions = [
    [{ 'font': [] }], // Font family
    [{ 'size': ['small', false, 'large', 'huge'] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    [{ 'indent': '-1'}, { 'indent': '+1' }],
    [{ 'align': [] }],
  ];

  const modules = {
    toolbar: toolbarOptions
  };

  const maxCharacters = 500;
  const handleDescriptionChange = (value) => {
    if (value.length <= maxCharacters) {
      setDescription(value);
    }
  };

  return (
    <>
      <Modal show={props.showForm} onHide={props.handleCloseForm} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            {props.request === "PUT" ? "Edit Restaurant" : ""}
            {props.request === "POST" ? "Create Restaurant" : ""}
          </Modal.Title>
        </Modal.Header>
        {!props.restaurant && props.request === "PUT" ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "200px" }}
          >
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <Form onSubmit={handleSubmit}>
            <Modal.Body>
              <Row className="mb-2">
                <Form.Group controlId="createRestaurantName">
                  <Form.Label>Restaurant Name</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faUtensils} />
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      placeholder="Enter Restaurant Name"
                      value={restaurantName}
                      onChange={(e) => {
                        setRestaurantName(e.target.value);
                      }}
                      required
                    />
                  </InputGroup>
                </Form.Group>
              </Row>
              <Row className="mb-2">
                <Form.Group controlId="restaurantPlacesId">
                  <Form.Label>Restaurant Places Id</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faUtensils} />
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      placeholder="Enter Restaurant Places Id"
                      value={restaurantPlacesId}
                      onChange={(e) => {
                        setRestaurantPlacesId(e.target.value);
                      }}
                    />
                  </InputGroup>
                </Form.Group>
              </Row>
              <Row className="mb-2">
                <Form.Group controlId="restaurantAddressLine1">
                  <Form.Label>Address Line 1</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faMapMarkerAlt} />
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      placeholder="Enter Address Line 1 "
                      value={addressLine1}
                      onChange={(e) => {
                        setAddressLine1(e.target.value);
                      }}
                      required
                    />
                  </InputGroup>
                </Form.Group>
              </Row>
              <Row className="mb-2">
                <Form.Group controlId="restaurantAddressLine2">
                  <Form.Label>Address Line 2</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faMapMarkerAlt} />
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      placeholder="Enter Address Line 2 "
                      value={addressLine2}
                      onChange={(e) => {
                        setAddressLine2(e.target.value);
                      }}
                      required
                    />
                  </InputGroup>
                </Form.Group>
              </Row>
              <Row className="mb-2">
                <Form.Group as={Col} controlId="restaurantCity">
                  <Form.Label>City</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faCity} />
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      placeholder="City "
                      value={city}
                      onChange={(e) => {
                        setCity(e.target.value);
                      }}
                      required
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col} controlId="restaurantZipCode">
                  <Form.Label>Zip Code</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faEnvelope} />
                    </InputGroup.Text>
                    <Form.Control
                      type="number"
                      placeholder="Enter Zip Code "
                      value={zipCode}
                      onChange={(e) => {
                        setZipCode(e.target.value);
                      }}
                      required
                    />
                  </InputGroup>
                </Form.Group>
              </Row>
              <Row className="mb-2">
                <Form.Group as={Col} controlId="restaurantCountry">
                  <Form.Label>Country</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faGlobe} />
                    </InputGroup.Text>
                    <Form.Select
                      name="country"
                      onChange={handleCountryChange}
                      value={country}
                      required
                    >
                      <option value="">Choose...</option>
                      {countries.map((country) => (
                        <option key={country.isoCode} value={country.isoCode}>
                          {country.name}
                        </option>
                      ))}
                    </Form.Select>
                  </InputGroup>
                </Form.Group>

                <Form.Group as={Col} controlId="restaurantState">
                  <Form.Label>State</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faMap} />
                    </InputGroup.Text>
                    <Form.Select
                      name="state"
                      onChange={handleStateChange}
                      value={state}
                      required
                    >
                      <option value="">Choose...</option>
                      {states.map((state) => (
                        <option key={state.isoCode} value={state.isoCode}>
                          {state.name}
                        </option>
                      ))}
                    </Form.Select>
                  </InputGroup>
                </Form.Group>
              </Row>
              <Row className="mb-2">
                <Form.Group>
                  <Form.Label>Restaurant Contact</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>+60</InputGroup.Text>
                    <InputMask
                      id="restaurantContact"
                      className="form-control"
                      name="adminContact"
                      mask="99-99999999999999999999999"
                      maskChar={null}
                      value={restaurantContact}
                      onChange={handlePhoneNo}
                      required
                    />
                  </InputGroup>
                </Form.Group>
              </Row>
              <Row className="gx-3">
                <Col sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Payment Details for Deposit collection (optional)</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faCreditCard} />
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        placeholder="AccountName: Bank Name: Account Number"
                        value={paymentDetails}
                        onChange={(e) => {
                          setPaymentDetails(e.target.value);
                        }}
                        id="paymentDetails"
                      />
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="gx-3">
                <Col sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Refund Policy (Optional)</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Your Refund Policy"
                      value={refundPolicy}
                      onChange={(e) => setRefundPolicy(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="gx-3">
                <Col sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Relevant Tags/Labels for your restaurant (Optional - up to 6)</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="halal, japanese, omakase, sushi"
                      value={tags}
                      onChange={(e) => setTags(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="gx-3">
                <Col sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Restaurant Description (Optional - max 500 characters)</Form.Label>
                    <ReactQuill
                      placeholder="Description"
                      value={description}
                      onChange={handleDescriptionChange}
                      modules={modules}
                    />
                    <div style={{ fontSize: '14px', color: '#6c757d', marginTop: '5px' }}>
                      {description?.length}/{maxCharacters} characters
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-2">
                <Form.Group controlId="addressLink">
                  <Form.Label>Address Link</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faLink} />
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      placeholder="Enter Address Link"
                      value={addressLink}
                      onChange={(e) => {
                        setAddressLink(e.target.value);
                      }}
                    />
                  </InputGroup>
                  {props.request === "PUT" ? (
                    <span id="uploadedLink">
                    Uploaded Link: {link && typeof link === 'string'
                        ? link
                        : 'No link uploaded'}
                    </span>
                  ) : null}
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group controlId="restaurantLogo">
                  <Form.Label>Restaurant Logo</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <i className="fa fa-image" />
                    </InputGroup.Text>
                    <Form.Control
                      type="file"
                      accept="image/*"
                      name="restaurantLogo"
                      onChange={(e) => {
                        if (e.target.files.length > 0) {
                          setGotFile(true);
                          setRestaurantLogo(e.target.files[0]);
                        } else {
                          setGotFile(false);
                        }
                      }}
                    />
                  </InputGroup>
                  {props.request === "PUT" ? (
                    <span id="uploadedFileText">
                        Uploaded File: {fileName && typeof fileName === 'string'
                        ? fileName.split('/').pop()
                        : 'No file uploaded'}
                    </span>
                  ) : null}
                </Form.Group>
              </Row>
              {props.request === "PUT" && (
                <Row className="mb-3">
                  <Form.Group controlId="previewRestaurantLogo">
                    <Form.Label> Preview Uploaded Logo: </Form.Label>
                    <div className="d-flex flex-column">
                      {fileName ? (
                        <img
                          src={fileName}
                          alt="Preview"
                          className="preview-image img-fluid"
                          style={{
                            maxWidth: '50%', height: 'auto',
                          }}
                        />
                      ) : (
                        <span className="ms-3">
                          No file uploaded
                        </span>
                      )}
                    </div>
                  </Form.Group>
                </Row>
              )}
              <Row className="mb-4">
                <FormGroup controlId="isBoarded">
                  <Form.Check
                    label="Is Boarded?"
                    checked={isBoarded === 1}
                    onChange={(e) => setIsBoarded(e.target.checked ? 1 : 0)}
                  />
                </FormGroup>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="soft-primary"
                type="submit"
                id="createSubmitBtn"
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />{" "}
                    Submitting...
                  </>
                ) : (
                  "Submit"
                )}
              </Button>
              <Button
                variant="soft-secondary"
                onClick={props.handleCloseForm}
                id="closeCreateFormBtn"
              >
                Close
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Modal>
    </>
  );
};

export default CreateRestaurantModal;
