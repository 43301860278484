import { useState } from "react";


// eslint-disable-next-line no-undef
const backendUri = process.env.REACT_APP_BACKEND_DOMAIN;
const useRestaurantMenuServices = () => {
  const [restaurantsMenuData, setRestaurantsMenuData] = useState("");
  const [restaurantMenuInfo, setRestaurantMenuInfo] = useState("");

  const getRestaurantMenus = async (restaurantId) => {
    try {
      const response = await fetch(
        `${backendUri}/api/v0/public/restaurants-menu/${restaurantId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.ok) {
        const responseData = await response.json();
        setRestaurantsMenuData(responseData);
      }
    } catch (error) {
      console.error("Error fetching restaurants menu:", error);
    }
  };

  //   //Get restaurant package by id
  //   const fetchRestaurantPackageInfo = async (restaurantPackageId) => {
  //     try {

  //       const response = await fetch(`${backendUri}/api/v0/public/packages/${restaurantPackageId}`, {
  //         method: "GET",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //       });

  //       if (response.ok) {
  //         const restaurantPackageData = await response.json();
  //         setRestaurantMenuInfo(restaurantPackageData);

  //       }
  //     } catch (error) {
  //       console.error("Error fetching restaurant package information:", error);
  //     }
  //   };

  return {
    getRestaurantMenus,
    restaurantsMenuData,
    // fetchRestaurantPackageInfo,
    restaurantMenuInfo,
  };
};

export default useRestaurantMenuServices;
