import React, { useState } from "react";
import SimpleBar from "simplebar-react";
import DatePicker from "react-datepicker";
import { Calendar } from "react-feather";
import { Button } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";

import AddCategory from "./AddCategory";
import SetReminder from "./SetReminder";


const CalendarSidebar = ({
  showSidebar,
  toggleSidebar,
  createNewEvent,
  onDateSelect,
}) => {
  const [addCategory, setAddCategory] = useState(false);
  const [reminder, setReminder] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const onChange = (dates) => {
    const [start] = dates;
    setStartDate(start);
    onDateSelect(start);
  };
  return (
    <>
      <nav className="calendarapp-sidebar">
        <SimpleBar className="nicescroll-bar">
          <div className="menu-content-wrap">
            <Button
              id="createBtn"
              className="btn-rounded btn-block"
              variant="primary"
              onClick={createNewEvent}
              style={{ paddingTop: '11px', paddingBottom:'11px' }}
            >
              <span className="feather-icon dropdown-icon">
                <Calendar style={{ marginRight: '7px' }}/>Create an Event
              </span>
            </Button>
            <div className="text-center mt-4">
              <div id="inline_calendar" className="d-inline-block">
                <DatePicker
                  selected={startDate}
                  onChange={onChange}
                  dateFormatCalendar={"MMM yyyy"}
                  selectsRange
                  inline
                  minDate={new Date()} 
                />
              </div>
            </div>
            <div className="separator separator-light" />
          </div>
        </SimpleBar>
        {/*Sidebar Fixnav*/}
      </nav>

      {/* Add Category */}
      <AddCategory
        show={addCategory}
        hide={() => setAddCategory(!addCategory)}
      />
      {/* New Event */}
      <SetReminder show={reminder} hide={() => setReminder(!reminder)} />
    </>
  );
};

export default CalendarSidebar;
