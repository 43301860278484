import React from "react";
import { Row } from "react-bootstrap";

import PackagesDetails from "../Packages/RestaurantsPackages";
import MenuDetails from "../Menus/RestaurantsMenu";
import ReviewDetails from "../RatingsReviews/RestaurantsReviews";


const Body = ({activeTab, restaurantsMenuData, restaurantsPackageData, restaurantId, restaurantContactNumber, restaurantInfo}) => {
  return (
    <Row className="mt-3">
      {activeTab === "Packages" && (
        <PackagesDetails restaurantsPackageData={restaurantsPackageData} restaurantContactNumber ={restaurantContactNumber}/>
      )}

      {activeTab === "Menus" && (
        <MenuDetails restaurantsMenuData={restaurantsMenuData} restaurantId={restaurantId} restaurantContactNumber ={restaurantContactNumber}/>
      )}

      {activeTab === "Reviews" && (
        <ReviewDetails restaurantInfo={restaurantInfo}/>
      )}
    </Row>
  );
};

export default Body;
