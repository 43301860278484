// import firebase from "firebase/app";
// import "firebase/auth";

// const firebaseConfig = {
//   apiKey: "AIzaSyDXEjIQPYKNi7PnY6sa2dq8nCSX51xQIUo",
//   authDomain: "konfem-7c4a9.firebaseapp.com",
//   projectId: "konfem-7c4a9",
//   storageBucket: "konfem-7c4a9.appspot.com",
//   messagingSenderId: "323265213098",
//   appId: "1:323265213098:web:7cb305d8ffbe7c273416d3",
// };

// firebase.initializeApp(firebaseConfig);

// export const auth = firebase.auth();
// export default firebase;

import {initializeApp} from "firebase/app";
import {getAuth} from "firebase/auth"
import { getMessaging } from "firebase/messaging";


const firebaseConfig = {
  apiKey: "AIzaSyD7LAVYB5oP75ts3wohpqZY3BgPzC-vUSY",
  authDomain: "auth.konfem.com",
  projectId: "konfem---live",
  storageBucket: "konfem---live.appspot.com",
  messagingSenderId: "309260477828",
  appId: "1:309260477828:web:7a2ec980160ff1b20b29d1",
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth();
export const messaging = getMessaging(app);
export const VAPID_KEY = "BCpc_KTzk5ucQ9i2Y6nuF9_t_Ah5uwdfC6jLyPufQ5lVvkGRYhZW06JGHrUfYJnXVjgOEA5BS5OkGjSICtQePY8";

export default app; 
