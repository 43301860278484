import { useState } from "react";
import { toast } from "react-toastify";


// eslint-disable-next-line no-undef
const backendUri = process.env.REACT_APP_BACKEND_DOMAIN;
const useAuthServices = () => {
  const [success, setSuccess] = useState(null);
  const [userData, setUserData] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(null);

  const sendResetPasswordLink = async (email, role) => {
    await fetch(`${backendUri}/api/v0/auth/forget-password`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, role }),
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.error) {
          toast.error(res.message, {
            containerId: "toast-container",
          });
          setSuccess(false);
        } else {
          toast.success(res.message, {
            containerId: "toast-container",
          });
          setSuccess(true);
        }
      })
      .catch((err) => {
        console.log(err);
        console.error("Fetch API fail");
        setSuccess(false);
      });
  };

  const resetPassword = async (data) => {
    const newPassword = data.newPassword;
    const confirmPassword = data.confirmPassword;

    setIsSubmitting(true)
    await fetch(`${backendUri}/api/v0/auth/reset-password/${data.resetToken}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ newPassword, confirmPassword }),
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.error) {
          toast.error(res.message, {
            containerId: "toast-container",
          });
          setSuccess(false);
        } else {
          toast.success(res.message, {
            onClose: () => {
              setSuccess(true); // Set success to true after showing the toast message
            },
            containerId: "toast-container",
          });
        }
      })
      .catch((err) => {
        console.log(err);
        console.error("Fetch API fail");
        setSuccess(false);
      });
  };

  const getUser = async (userId) => {
    try {
      const response = await fetch(
        `${backendUri}/api/v0/auth/get-user/${userId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.ok) {
        const responseData = await response.json();
        setUserData(responseData);
      }
    } catch (error) {
      console.error("Error fetching restaurants menu:", error);
    }
  }

  const clearSuccess = () => {
    setSuccess(null);
  };

  const clearIsSubmitting = () => {
    setIsSubmitting(null);
  };



  return {
    sendResetPasswordLink,
    resetPassword,
    success,
    clearSuccess,
    clearIsSubmitting,
    isSubmitting,
    getUser,
    userData
  };
};

export default useAuthServices;
