import React, { useState, useEffect } from "react";
import {
  Card,
  Button,
  Dropdown,
  Collapse,
} from "react-bootstrap";
import { FaWhatsapp, FaMapMarkerAlt, FaClock } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp, faShareNodes } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

import defaultImage from "../../../assets/dist/img/default_photo.png";
import { useAuth } from "../../../services/authContext";
import EventsDrawer from "../../../layout/Header/EventsDrawer/EventsDrawer";
import Ratings from "../RatingsReviews/RestaurantsRating";
import Photos from "../RestaurantPhotos/RestaurantPhotos";
import MetaTags from "./Metatags";


// eslint-disable-next-line no-undef
const frontendUri = process.env.REACT_APP_FRONTEND_DOMAIN;
const ProfileIntro = ({ restaurantInfo }) => {
  const history = useHistory();
  const [createEvent, setCreateEvent] = useState(false);
  const handleGoToLoginClick = () => {
    history.push({
      pathname: "/auth/",
      state: { restaurantId: restaurantInfo._id },
    });
  };

  const { user } = useAuth();
  const drawerController = () => {
    setClientCreate(true);
    setCreateEvent(!createEvent);
  };

  const sunBusinessHours = restaurantInfo.businessHours?.find(
    (businessHour) => businessHour.day === 0
  );
  const monBusinessHours = restaurantInfo.businessHours?.find(
    (businessHour) => businessHour.day === 1
  );
  const tueBusinessHours = restaurantInfo.businessHours?.find(
    (businessHour) => businessHour.day === 2
  );
  const wedBusinessHours = restaurantInfo.businessHours?.find(
    (businessHour) => businessHour.day === 3
  );
  const thuBusinessHours = restaurantInfo.businessHours?.find(
    (businessHour) => businessHour.day === 4
  );
  const friBusinessHours = restaurantInfo.businessHours?.find(
    (businessHour) => businessHour.day === 5
  );
  const satBusinessHours = restaurantInfo.businessHours?.find(
    (businessHour) => businessHour.day === 6
  );

  const daysOfWeek = [
    { day: "Sunday", businessHours: sunBusinessHours },
    { day: "Monday", businessHours: monBusinessHours },
    { day: "Tuesday", businessHours: tueBusinessHours },
    { day: "Wednesday", businessHours: wedBusinessHours },
    { day: "Thursday", businessHours: thuBusinessHours },
    { day: "Friday", businessHours: friBusinessHours },
    { day: "Saturday", businessHours: satBusinessHours },
  ];

  const [open, setOpen] = useState(false);
  const [clientCreate, setClientCreate] = useState(false);
  
  const applySpecialStyles = () => {
    const wrapperElement = document.getElementById("wrapper");

    if (wrapperElement) {
      if (createEvent) {
        wrapperElement.style.zIndex = "90";
        wrapperElement.style.position = "relative";
      } else {
        wrapperElement.style.zIndex = "1";
        wrapperElement.style.position = "";
      }
    }
  };

  useEffect(() => {
    applySpecialStyles();

    if(!createEvent){
      setClientCreate(false);
    }
  }, [createEvent]);

  const [visibleChar, setVisibleChar] = useState(0);
  useEffect(() => {
    const stripHtmlTags = (html) => {
      const div = document.createElement('div');
      div.innerHTML = html;
      setVisibleChar((div.textContent || div.innerText || '').length);
    };
    stripHtmlTags(restaurantInfo.description);
  }, [restaurantInfo.description]);

  const shareRestaurant = () => {
    const message = `Check out this restaurant: ${restaurantInfo.restaurantName} - \n ${frontendUri}/restaurants/${restaurantInfo._id}`;
    const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(message)}`;
    window.open(whatsappUrl, '_blank');
  }

  return (
    <>
      <Helmet>
        <title>{`${restaurantInfo.restaurantName} | Konfem Reservation`}</title>
      </Helmet>
      <div className="profile-intro">
        <Card className="card-flush mw-800p bg-transparent">
          <MetaTags
            title={`${restaurantInfo.restaurantName} | Konfem Reservation`}
            description={restaurantInfo.description}
            image={restaurantInfo.restaurantLogo}
            url={`${frontendUri}/restaurants/${restaurantInfo._id}`}
          />
          <Button 
            onClick={shareRestaurant} 
            className="btn btn-sm" 
            style={{ 
              position: 'absolute',
              top: '0',
              right: '0',
              background: 'none', 
              border: '0.5px solid #ed9c00',
              borderRadius: '50%',
              width: '30px',
              height: '30px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              color: '#ed9c00'
              }}
          >
            <FontAwesomeIcon icon={faShareNodes} />
          </Button>
          <Card.Body>
            <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
              <div className="avatar avatar-xl avatar-rounded position-relative mb-4">
                <img
                  src={
                    restaurantInfo.restaurantLogo
                      ? restaurantInfo.restaurantLogo
                      : defaultImage
                  }
                  alt="user"
                  className="avatar-img border border-1 border-light"
                />
              </div>
              <div style={{ flex: 1, display: 'flex', flexDirection: 'column', marginLeft: '30px' }}>
                <h4>{restaurantInfo.restaurantName}</h4>
                <Ratings restaurantInfo={restaurantInfo} />
              </div>
            </div>
            <div>
              {restaurantInfo.addressLink ? (
                <a
                  href={restaurantInfo.addressLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ display: "flex", alignItems: "center" }}
                  id="addressLink"
                >
                  <div>
                    <FaMapMarkerAlt
                      style={{ marginRight: "5px", fontSize: "20px" }}
                    />
                  </div>
                  <div style={{ marginLeft: "10px", fontSize: '14px' }}>
                    <span className="contact-info text-decoration-underline">
                      {restaurantInfo.restaurantAddress}
                    </span>
                  </div>
                </a>
              ) : (
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                    restaurantInfo.restaurantAddress
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ display: "flex", alignItems: "center" }}
                  id="addressLink"
                >
                  <div>
                    <FaMapMarkerAlt
                      style={{ marginRight: "5px", fontSize: "20px" }}
                    />
                  </div>
                  <div style={{ marginLeft: "10px", fontSize: '14px' }}>
                    <span className="contact-info text-decoration-underline">
                      {restaurantInfo.restaurantAddress}
                    </span>
                  </div>
                </a>
              )}
            </div>
            <ul className="list-inline fs-7 mt-2 mb-0">
              <li className="list-inline-item d-sm-inline-block d-block mb-sm-0 mb-1 me-3">
                <a
                  href={`https://wa.me/+60${restaurantInfo.restaurantContact}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <div>
                    <FaWhatsapp
                      style={{ marginRight: "5px", fontSize: "20px" }}
                    />
                  </div>
                  <div style={{ marginLeft: "10px", fontSize: '14px' }}>
                    <span className="contact-info text-decoration-underline">
                    +60{restaurantInfo.restaurantContact}
                    </span>
                  </div>
                </a>
              </li>
            </ul>
            <br />
            <div>
              <Dropdown
                onClick={() => setOpen(!open)}
                aria-controls="business-hours-collapse"
                aria-expanded={open}
                style={{ marginTop: "-18px" }}
                id="defaultBusinessHour"
              >
                <div style={{ display: "flex", alignItems: "center", padding: "10px", cursor: "pointer"}}>
                  <div style={{ marginRight: "3px", marginLeft: "-8px", color: "#d18615"}}>
                    <FaClock />
                  </div>
                  <div style={{ marginRight: "5px", marginLeft: "15px" }}>
                    <span style={{ color: "#d18615" }}>
                      {`${daysOfWeek[new Date().getDay()].day}:`}
                    </span>
                  </div>
                  <div style={{ marginLeft: "30px" }}>
                    <span style={{ color: "#d18615" }}>
                      {daysOfWeek[new Date().getDay()].businessHours ? (
                        <>
                          {daysOfWeek[new Date().getDay()].businessHours.isRestDay === 0 ? (
                            `${moment(
                              daysOfWeek[new Date().getDay()].businessHours.startTime).format("hh:mm A")} - 
                            ${moment(
                              daysOfWeek[new Date().getDay()].businessHours.endTime).format("hh:mm A")}` +
                            (daysOfWeek[new Date().getDay()].businessHours.startTime2 ? 
                              `, ${moment(
                                daysOfWeek[new Date().getDay()].businessHours.startTime2).format("hh:mm A")} - 
                            ${moment(
                                daysOfWeek[new Date().getDay()].businessHours.endTime2).format("hh:mm A")}` : '')
                          ) : daysOfWeek[new Date().getDay()].businessHours.isRestDay === 1 ? (
                            <span style={{ color: "red" }}>Closed</span>
                          ) : (
                            <span style={{ color: "#d18615" }}>-</span>
                          )}
                        </>
                      ) : (
                        <span style={{ color: "#d18615" }}>-</span>
                      )}
                    </span>
                  </div>
                  <div style={{ marginLeft: "auto", color: "#d18615" }}>
                    <FontAwesomeIcon icon={open ? faAngleUp : faAngleDown} />
                  </div>
                </div>
              </Dropdown>
              <Collapse in={open} id="business-hours-collapse">
                <div>
                  {daysOfWeek.map(
                    (day, index) =>
                      index !== new Date().getDay() && (
                        <div
                          key={index}
                          style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}
                        >
                          <div style={{ minWidth: "100px", marginRight: "5px" }}>
                            <span>{day.day}:</span>
                          </div>
                          <div>
                            {day.businessHours ? (
                              <>
                                {day.businessHours.isRestDay === 0 ? (
                                  <span style={{ color: "#A9A9A9" }}>
                                    {moment(day.businessHours.startTime).format("hh:mm A")} {"- "}
                                    {moment(day.businessHours.endTime).format("hh:mm A")}
                                    <br />
                                    {day.businessHours.startTime2 && moment(day.businessHours.startTime2).format("hh:mm A")}
                                    {day.businessHours.startTime2 && " - "}
                                    {day.businessHours.endTime2 && moment(day.businessHours.endTime2).format("hh:mm A")}
                                  </span>
                                ) : day.businessHours.isRestDay === 1 ? (
                                  <span style={{ color: "red" }}>Closed</span>
                                ) : (
                                  <span style={{ color: "#A9A9A9" }}>-</span>
                                )}
                              </>
                            ) : (
                              <span style={{ color: "#A9A9A9" }}>-</span>
                            )}
                          </div>
                        </div>
                      )
                  )}
                </div>
              </Collapse>
            </div>
            {restaurantInfo.description && restaurantInfo.description !== "" && visibleChar !== 0 ? (
              <div 
                style={{
                  margin: '10px auto',
                  padding: '10px',
                  border: '1px solid #ddd',
                  borderRadius: '5px',
                  color: '#6c757d',
                }}
                dangerouslySetInnerHTML={{ __html: restaurantInfo.description }}>
              </div>
            ) : null}
            {user.role === "admin" || user.role === "superAdmin" ? null : user.role === "client" ? (
              <Button
                id="createEvent"
                className="mt-3"
                onClick={drawerController}
              >
                Reserve a table
              </Button>
            ) : (
              <Button
                id="goToLogin"
                className="mt-3"
                onClick={handleGoToLoginClick}
              >
                Reserve a Table
              </Button>
            )}
            <Photos restaurantInfo={restaurantInfo} />
          </Card.Body>
        </Card>
        <EventsDrawer
          show={createEvent}
          onClose={drawerController}
          setClientCreate={setClientCreate}
          create={clientCreate}
          restaurantId={restaurantInfo._id}
          isBoarded={restaurantInfo.isBoarded}
          restaurantContact={restaurantInfo.restaurantContact}
        />
      </div>
    </>
  );
};

export default ProfileIntro;
