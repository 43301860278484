/* eslint-disable no-useless-concat */
import React, { createRef, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Button, ButtonGroup, Dropdown, Modal } from "react-bootstrap";
import classNames from "classnames";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import { useWindowHeight, useWindowWidth } from "@react-hook/window-size";
import "bootstrap-icons/font/bootstrap-icons.css";
import moment from "moment";
import Cookies from "js-cookie";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ChevronDown, ChevronUp } from "react-feather";
//Redux
import { connect } from "react-redux";
import { toggleTopNav } from "../../../redux/action/Theme";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import "../../../assets/dist/css/FullCalendar.css";
import CalendarSidebar from "./CalendarSidebar";
import { getEvents, getRejectedEvents } from "./Events";
// import EventsDrawer from "./EventsDrawer";
import EventsDrawer from "../../../layout/Header/EventsDrawer/EventsDrawer";
import { useAuth } from "../../../services/authContext";
import { ToastContainer, toast } from "react-toastify";
import { getMessaging, getToken } from "firebase/messaging";
import { messaging } from "../../../services/firebaseService"
import useAdminService from "../../../services/adminService";
import useEventServices from "../../../services/eventService";


const backendUri = process.env.REACT_APP_BACKEND_DOMAIN;
const vapidKey = process.env.VAPID_KEY;

const Calendar = ({ topNavCollapsed, toggleTopNav }) => {
  const { user } = useAuth();
  const location = useLocation();
  const token = useState(Cookies.get("token"));
  let calendarRef = createRef();
  var curYear = moment().format("YYYY"),
    curMonth = moment().format("MM");

  const [Token, setToken] = useState(Cookies.get("token"));

  const [showSidebar, setShowSidebar] = useState(true);
  const [showEventInfo, setShowEventInfo] = useState(false);
  const [createEvent, setCreateEvent] = useState(false);
  const [targetEvent, setTargetEvent] = useState();
  const [date, setDate] = useState(curYear + "-" + curMonth + "-07");
  const [startDate, setStartDate] = useState(new Date());
  const [currentView, setCurrentView] = useState("month");
  const [showViewSelector, setShowViewSelector] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [events, setEvents] = useState({});
  const [konfemedEvents, setKonfemedEvents] = useState([]);
  const [rejectedEvents, setRejectedEvents] = useState([]);
  const [showAllEvents, setShowAllEvents] = useState(false);
  const [combineEvents, setCombineEvents] = useState([]);
  const [showNotificationDialog, setShowNotificationDialog] = useState(false);
  const {
    fetchProfileInfo,
    adminInfo,
  } = useAdminService();
  const { fetchEventInfo } = useEventServices();

  const width = useWindowWidth();
  const isMobile = width <= 768;

  const applySpecialStyles = () => {
    const wrapperElement = document.getElementById("wrapper");

    if (wrapperElement) {
      if (createEvent || showEventInfo) {
        wrapperElement.style.zIndex = "120";
        wrapperElement.style.position = "relative";
      } else {
        wrapperElement.style.zIndex = "1";
        wrapperElement.style.position = "";
      }
    }
  };


  const closeSideBar = () => {
    setCreateEvent(false)
    setShowEventInfo(!showEventInfo);
    setTargetEvent("");
  };

  const isApplyButtonClicked = (target, applyButtons) => {
    for (const applyButton of applyButtons) {
      if (applyButton.contains(target)) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    applySpecialStyles();
  }, [createEvent, showEventInfo]);

  useEffect(() => {
    const calApi = calendarRef.current.getApi();

    if (calApi) {
      setDate(moment(calApi.getDate()));
    }
  }, []);

  const fetchAllEvents = async () => {
    setKonfemedEvents(await getEvents());
    setRejectedEvents(await getRejectedEvents());
  };

  useEffect(() => {
    fetchAllEvents();
  }, []);

  useEffect(() => {
  
    if (token && user.role === "admin") {
      
      const interval = setInterval(async () => {
        await fetchAllEvents();
      }, 5000); // 10000 milliseconds = 10 seconds

      return () => clearInterval(interval);
    }
    // fetchData();
  }, []);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      const applyButtons = document.querySelectorAll(".daterangepicker");

      if (showEventInfo) {
        const eventDrawer = document.getElementById("events-drawer");
        if (
          eventDrawer &&
          !eventDrawer.contains(event.target) &&
          !isApplyButtonClicked(event.target, applyButtons)
        ) {
          setShowEventInfo(false);
        }
      }

      if (createEvent) {
        const createEventDrawer = document.getElementById(
          "create-events-drawer"
        );
        if (
          createEventDrawer &&
          !createEventDrawer.contains(event.target) &&
          !isApplyButtonClicked(event.target, applyButtons)
        ) {
          setCreateEvent(false);
        }
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [showEventInfo, createEvent]);

  //Function for date change
  const handleChange = (action) => {
    let calendarApi = calendarRef.current.getApi();
    if (calendarApi) {
      if (action === "prev") {
        calendarApi.prev();
      } else if (action === "next") {
        calendarApi.next();
      } else {
        calendarApi.today();
      }

      setDate(moment(calendarApi.getDate()));
    }
  };

  //Function for Calendar View Changes
  const handleView = (view) => {
    let calendarApi = calendarRef.current.getApi();
    if (calendarApi) {
      if (view === "week") {
        calendarApi.changeView("timeGridWeek");
      } else if (view === "day") {
        calendarApi.changeView("dayGridWeek");
      } else if (view === "list") {
        calendarApi.changeView("listWeek");
      } else {
        calendarApi.changeView("dayGridMonth");
      }

      setDate(moment(calendarApi.getDate()));
      setCurrentView(view);
    }
  };

  const renderDropdownToggle = () => (
    <Dropdown.Toggle variant="outline-light" id="dropdown-basic">
      {currentView}
    </Dropdown.Toggle>
  );

  const renderDropdownItems = () => (
    <>
      <Dropdown.Item
        onClick={() => handleView("month")}
        active={currentView === "month"}
      >
        Month
      </Dropdown.Item>
      <Dropdown.Item
        onClick={() => handleView("week")}
        active={currentView === "week"}
      >
        Week
      </Dropdown.Item>
      <Dropdown.Item
        onClick={() => handleView("day")}
        active={currentView === "day"}
      >
        Day
      </Dropdown.Item>
      <Dropdown.Item
        onClick={() => handleView("list")}
        active={currentView === "list"}
      >
        List
      </Dropdown.Item>
    </>
  );

  const handleButtonClick = () => {
    setTooltipOpen(!tooltipOpen);
  };

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 500);
  };

  const handleDateSelection = (date) => {
    setStartDate(date);
  };
  const Calender_height = useWindowHeight();

  const handleSwitchShowEvents = (event) => {
    setShowAllEvents(event.target.checked);
  };

  const combineArray = async () => {
 
    if (Array.isArray(rejectedEvents) && Array.isArray(konfemedEvents)) {
      setCombineEvents(rejectedEvents.concat(konfemedEvents));
    } else if (
      Array.isArray(konfemedEvents) &&
      !Array.isArray(rejectedEvents)
    ) {
      setCombineEvents(konfemedEvents);
    } else if (
      Array.isArray(rejectedEvents) &&
      !Array.isArray(konfemedEvents)
    ) {
      setCombineEvents(rejectedEvents);
    } else if (
      !Array.isArray(konfemedEvents) &&
      !Array.isArray(rejectedEvents)
    ) {
      setCombineEvents([]);
    }
  };

  useEffect(() => {
    combineArray();
  }, [rejectedEvents, konfemedEvents]);

  useEffect(() => {
    if (showAllEvents) {
      !Array.isArray(konfemedEvents) && !Array.isArray(rejectedEvents)
        ? setEvents([])
        : setEvents(combineEvents);
    } else {
      setEvents(konfemedEvents);
    }
  }, [showAllEvents, konfemedEvents, rejectedEvents]);

  useEffect(() => {
    fetchProfileInfo();
  }, []);

  const requestNotificationPermission = async (uid) => {
    const permission = await Notification.requestPermission();

    if (permission === 'granted') {
      await saveDeviceToken(uid);
    } else {
      console.log('Unable to get permission');
    }
  };

  const saveDeviceToken = async (uid) => {
    const fcmToken = await getToken(messaging, {
      vapidKey: "BCpc_KTzk5ucQ9i2Y6nuF9_t_Ah5uwdfC6jLyPufQ5lVvkGRYhZW06JGHrUfYJnXVjgOEA5BS5OkGjSICtQePY8"
    });
    try {
      fetch(`${backendUri}/api/v0/admin/deviceToken/${uid}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
        body: JSON.stringify({fcmToken})
      })
        .then((res) => {
          return res.json();
        })
        .then((res) => {
          if (res.error) {
            toast.error(res.message, {
              containerId: "toast-container",
            });
          }
        })
    } catch (error) {
      console.error("Error updating device token:", error);
    }
  };

  const handleAgreeNotification = () => {
    requestNotificationPermission(adminInfo._id);
    setShowNotificationDialog(false);
  };

  useEffect(() => {
    if (adminInfo) {
      if (adminInfo.deviceToken === null || adminInfo.deviceToken === undefined || adminInfo.deviceToken === "") {
        setShowNotificationDialog(true);
      }
    }
  }, [adminInfo]);

  return (
    <>
      <h1 id="adminDashboardView" style={{ display: "none" }}>
        Admin Dashboard View
      </h1>
      <div className="hk-pg-body py-0">
        <div
          className={classNames("calendarapp-wrap", {
            "calendarapp-sidebar-toggle": !showSidebar,
          })}
        >
          <CalendarSidebar
            showSidebar={showSidebar}
            toggleSidebar={() => setShowSidebar(!showSidebar)}
            createNewEvent={() => {
              setCreateEvent(true);
              setShowEventInfo(true);
            }}
            onDateSelect={handleDateSelection}
          />
          <div className="calendarapp-content">
            <div id="calendar" className="w-100">
              <header className="cd-header">
                <div className="d-flex flex-1 justify-content-start">
                  <Button
                    variant="outline-light me-3"
                    onClick={() => handleChange("today")}
                  >
                    Today
                  </Button>
                  <Button
                    variant="flush-dark"
                    className="btn-icon btn-rounded flush-soft-hover"
                    onClick={() => handleChange("prev")}
                    style={{ marginLeft: "-10px" }}
                  >
                    <span className="icon">
                      <FontAwesomeIcon icon={faChevronLeft} size="sm" />
                    </span>
                  </Button>
                  <Button
                    variant="flush-dark"
                    className="btn-icon btn-rounded flush-soft-hover"
                    onClick={() => handleChange("next")}
                    style={{ marginLeft: "-15px" }}
                  >
                    <span className="icon">
                      <FontAwesomeIcon icon={faChevronRight} size="sm" />
                    </span>
                  </Button>
                </div>
                <div className="d-flex flex-1 justify-content-center">
                  <h4 className="mb-0">
                    {moment(date).format("MMMM" + " " + "YYYY")}
                  </h4>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Switch
                    onChange={handleSwitchShowEvents}
                    size="small"
                    sx={{
                      "& .MuiSwitch-track": {
                        backgroundColor: "#000000",
                      },
                      "& .MuiSwitch-thumb": {
                        color: "#d18615",
                      },
                      "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                        {
                          backgroundColor: "#d18615",
                        },
                    }}
                    disabled={
                      rejectedEvents.length === 0 || konfemedEvents.length === 0
                    }
                  />
                  <Tooltip
                    title="Show All Event"
                    arrow
                    open={tooltipOpen}
                    onClose={() => setTooltipOpen(false)}
                  >
                    <IconButton aria-label="info" onClick={handleButtonClick}>
                      <InfoOutlinedIcon
                        style={{
                          fontSize: "18px",
                          marginLeft: isMobile ? "-12px" : "0px",
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </div>
                <div className="cd-options-wrap d-flex flex-1 justify-content-end">
                  {width > 768 ? (
                    <ButtonGroup className="d-none d-md-flex">
                      <Button
                        variant="outline-light"
                        onClick={() => handleView("month")}
                        active={currentView === "month"}
                      >
                        month
                      </Button>
                      <Button
                        variant="outline-light"
                        onClick={() => handleView("week")}
                        active={currentView === "week"}
                      >
                        week
                      </Button>
                      <Button
                        variant="outline-light"
                        onClick={() => handleView("day")}
                        active={currentView === "day"}
                      >
                        day
                      </Button>
                      <Button
                        variant="outline-light"
                        onClick={() => handleView("list")}
                        active={currentView === "list"}
                      >
                        list
                      </Button>
                    </ButtonGroup>
                  ) : (
                    <Dropdown>
                      {renderDropdownToggle()}
                      <Dropdown.Menu>{renderDropdownItems()}</Dropdown.Menu>
                    </Dropdown>
                  )}
                  <Button
                    as="a"
                    variant="flush-dark"
                    className="btn-icon btn-rounded flush-soft-hover hk-navbar-togglable"
                    onClick={() => toggleTopNav(!topNavCollapsed)}
                  >
                    <span className="icon">
                      <span className="feather-icon">
                        {topNavCollapsed ? <ChevronDown /> : <ChevronUp />}
                      </span>
                    </span>
                  </Button>
                </div>
                <div
                  className={classNames("hk-sidebar-togglable", {
                    active: !showSidebar,
                  })}
                  onClick={toggleSidebar}
                />
              </header>

              <FullCalendar
                ref={calendarRef}
                plugins={[
                  dayGridPlugin,
                  timeGridPlugin,
                  listPlugin,
                  interactionPlugin,
                ]}
                initialView="dayGridMonth"
                initialDate={date}
                headerToolbar={false}
                themeSystem="bootstrap"
                height={Calender_height - 130}
                windowResizeDelay={500}
                droppable={true}
                editable={true}
                events={events}
                eventTimeFormat={{
                  hour: "numeric",
                  minute: "2-digit",
                  meridiem: "short",
                }}
                eventDidMount={function (info) {
                  const eventId = info.event.id;
                  const eventElement = info.el;
                  eventElement.setAttribute("id", `event-${eventId}`);
                }}
                eventContent={function (arg) {
                  if (arg.event.extendedProps.toHtml) {
                    return { html: arg.event.title };
                  }
                }}
                eventClick={function (info) {
                  console.log("Event info", info.event)
                  setTargetEvent(info.event);
                  setShowEventInfo(true);
                  setCreateEvent(false);
                }}
                dateClick={function (arg) {
                  const currentDate = new Date();
                  currentDate.setHours(0, 0, 0, 0);

                  const selectedDate = new Date(arg.date);
                  selectedDate.setHours(0, 0, 0, 0);

                  if (selectedDate >= currentDate) {
                    setShowEventInfo(true)
                    setCreateEvent(true);
                    // setShowEventInfo(false);
                    setStartDate(arg.date);
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
      
      {showEventInfo ? (
        <EventsDrawer
          show={showEventInfo}
          event={targetEvent}
          onClose={closeSideBar}
          token={token}
          fetchAllEvents={fetchAllEvents}
          combineArray = {combineArray}
          adminDashboard = {true}
          startDate={startDate}
          adminCreateEvent = {createEvent}
          // create = {true}
          // getPendingEvents={getPendingEvents}
        />
      ) : null}

      <Modal show={showNotificationDialog} onHide={() => setShowNotificationDialog(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Notification Permission</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Do you want to receive updates for incoming reservations?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button id="no" variant="secondary" onClick={() => setShowNotificationDialog(false)}>
            No
          </Button>
          <Button id="yes" variant="primary" onClick={handleAgreeNotification}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      <ToastContainer
        position="top-right"
        style={{ top: "70px", right: "20px" }}
        enableMultiContainer
        containerId="error-container"
      />
      <ToastContainer
        position="top-right"
        style={{ top: "70px", right: "20px" }}
        enableMultiContainer
        containerId="success-container"
      />
    </>
  );
};

const mapStateToProps = ({ theme }) => {
  const { topNavCollapsed } = theme;
  return { topNavCollapsed };
};

export default connect(mapStateToProps, { toggleTopNav })(Calendar);
