import React, { useEffect, useState } from "react";
import {
  Col,
  InputGroup,
  Form,
  Row,
  Button,
  Dropdown,
  CloseButton,
  Card,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import SimpleBar from "simplebar-react";
import * as Icons from "tabler-icons-react";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";

import defaultImage from "../../../../assets/dist/img/default_photo.png";
import useRestaurantServices from "../../../../services/restaurantService";
import useClientServices from "../../../../services/clientService";
import RestaurantCardFooter from "../RestaurantCardsFooter";


const backendUri = process.env.REACT_APP_BACKEND_DOMAIN;
const FavRestaurantCardsBody = () => {
  const { fetchClientInfo, clientInfo } = useClientServices();
  const { restaurantsData } = useRestaurantServices();
  const [lists, setList] = useState([]);
  const [token, setToken] = useState(Cookies.get("token"));
  const [decodedToken, setDecodedToken] = useState(null);
  // Infinite Scroll
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;
  //Search Variable
  const [searchTerm, setSearchTerm] = useState("");
  const [clickedTags, setClickedTags] = useState([]);
  const [filteredList, setFilteredLists] = useState([...lists]);
  //Update the render to use filteredLists
  const [currentItems, setCurrentItems] = useState(filteredList.slice(0, currentPage * itemsPerPage));
  
  useEffect(() => {
    if (token) {
      const decoded = jwtDecode(token);
      setDecodedToken(decoded);
    }
  }, [token]);

  useEffect(() => {
    if (decodedToken) {
      fetchClientInfo(decodedToken.userId);
    }
  }, [decodedToken]);
  
  useEffect(() => {
    if (clientInfo && clientInfo.favRestaurants && clientInfo.favRestaurants.length !== 0) {
      const restaurantsArray = Array.isArray(restaurantsData) ? restaurantsData : [];
      const filteredRestaurantsData = restaurantsArray.filter(restaurant => clientInfo.favRestaurants.includes(restaurant._id));

      if (filteredRestaurantsData.length > 0) {
        setList(filteredRestaurantsData);
      }
    }
  }, [restaurantsData, clientInfo]);

  useEffect(() => {
    const filterItems = lists.filter((item) => {
      const matchesSearchTerm = searchTerm
        ? item.restaurantName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.restaurantAddress.toLowerCase().includes(searchTerm.toLowerCase()) ||
          (item.tags && item.tags.length > 0 && item.tags.toLowerCase().includes(searchTerm.toLowerCase()))
        : true;
  
      const matchesClickedTags = clickedTags.length > 0
        ? clickedTags.every((selectedFilter) =>
          item.tags && item.tags.length > 0 && item.tags.toLowerCase().includes(selectedFilter.toLowerCase())
        ) : true;
      return matchesSearchTerm && matchesClickedTags;
    });
  
    setFilteredLists(filterItems);
    setCurrentPage(1);
  }, [lists, searchTerm, clickedTags]);

  useEffect(() => {
    setCurrentItems(filteredList.slice(0, currentPage * itemsPerPage));
  }, [currentPage, filteredList]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerHeight + document.documentElement.scrollTop + 1 >= document.documentElement.scrollHeight) {
        setCurrentPage(prev => prev + 1);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleTagClick = (tag) => {
    const updatedClickedTags = clickedTags.includes(tag)
      ? clickedTags.filter((t) => t !== tag)
      : [...clickedTags, tag];

    setClickedTags(updatedClickedTags);
  };

  const clearSearch = () => {
    setSearchTerm('');
  }

  const tagColorMapping = {
    'halal': '#f4b084',
    'vegetarian': '#9fc5e8',
    'western': '#FFD900',
    'asian': '#E9DCC9',
    'french': '#A7C7E7',
    'indian': '#9FE2BF',
    'italian': '#FFFF8F',
    'indonesian': '#FFB6C1',
    'japanese': '#BDB5D5',
    'korean': '#D8BFD8',
    'mediterranean': '#E5AA70',
    'malaysian': '#FAA0A0',
    'mexican': '#B6D0E2',
    'thai': '#C1E1C1',
    'vietnamese': '#FAD5A5',
    'nyonya': '#E6E6FA'
  }

  const handleClickCounter = async (id) => {
    // Update click counter for each restaurant
    const timestamp = new Date();
    await fetch(`${backendUri}/api/v0/public/restaurants/counter/${id}?timestamp=${encodeURIComponent(timestamp)}`)
  }

  return (
    <>
      <div className="contact-body">
        {!clientInfo || !clientInfo.favRestaurants || clientInfo.favRestaurants.length === 0 ? (
          <div className="d-flex align-items-center justify-content-center vh-100">
            <h3>There is no favourite restaurants added yet. </h3>
          </div>
        ) : (
          <>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '5px', position: 'fixed', top: '10', left: '80', width: '86%', backgroundColor: 'white', zIndex: '1' }}>
              <InputGroup className="mb-4" style={{ height: '1.25rem' }} >
                <InputGroup.Text className="feather-icon">
                  <Icons.Search/>
                </InputGroup.Text>
                <div style={{ flex: 1, position: 'relative' }}>
                  <Form.Control
                    className="table-search custom-placeholder"
                    type="text"
                    placeholder="Search by Location, Tags or Restaurant Name"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    style={{ fontSize: '0.75rem' }}
                  />
                  {searchTerm && (
                    <CloseButton
                      variant="outline-primary"
                      onClick={clearSearch}
                      className="bg-transparent"
                      style={{
                        position: 'absolute',
                        right: '0.5rem',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        fontSize: '0.55rem',
                        padding: '0 0.5rem',
                      }}
                    />
                  )}
                </div>
              </InputGroup>
              <Dropdown className="mb-4" style={{ height: '1.25rem' }}>
                <Dropdown.Toggle variant="outline-primary" style={{ fontSize: '0.75rem', padding: '0.3125rem 0.625rem' }}>
                  <Icons.AdjustmentsHorizontal size={16}/>
                </Dropdown.Toggle>
                <Dropdown.Menu style={{ width: '20rem' }}>
                  {Object.keys(tagColorMapping).map((tag) => (
                    <Button
                      key={tag}
                      style={{
                        backgroundColor: clickedTags.includes(tag) ? '#FFAE42' : '#f0f0f0',
                        borderRadius: '1rem', 
                        padding: '2px 6px', 
                        color: '#333', 
                        fontSize: '0.7rem', 
                        fontStyle: 'italic',
                        border: 'none',
                        outline: 'none',
                        margin: '0.1rem'
                      }}
                      onClick={() => handleTagClick(tag)}
                    >
                      {tag.charAt(0).toUpperCase() + tag.slice(1)}
                    </Button>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <SimpleBar className="nicescroll-bar">
              {currentItems.length === 0 ? (
                <div
                  className="d-flex justify-content-center align-items-center text-center"
                  style={{ height: "200px" }}
                >
                  No favourite restaurants found
                </div>
              ) : (
                <Row style={{ marginTop: '50px', overflow: 'hidden' }}>
                  {currentItems.map((item) => (
                    <Col key={item._id}>
                      <Card className="card-border card-margin" style={{ width: '320px', height: '100px', display: 'flex' }}>
                        <Row>
                          <Col className="col-6 col-md-5 avatar avatar-xl avatar-rounded" style={{ marginTop: '10px', marginLeft: '20px', alignItems: 'center', justifyContent: 'center', position: 'relative' }}>
                            <Link 
                              to={`/restaurants/${item._id}`}
                              onClick = {() => handleClickCounter(item._id)}
                            >
                              <img
                                className="brand-img d-inline-block img-fluid rounded-circle border border-1 border-light"
                                src={item.restaurantLogo !== null ? item.restaurantLogo : defaultImage}
                                alt="brand"
                                style={{ width: "80px", height: "80px", objectFit: "cover" }}
                              />
                            </Link>
                          </Col>
                          <Col className="col-6 col-md-7">
                            <div className="restaurant-name" style={{ marginTop: '10px', flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center'  }}>
                              <Link 
                                style={{ fontSize: '14px', marginLeft: '5px', marginBottom: '5px' }}
                                onClick = {() => handleClickCounter(item._id)}
                                to={`/restaurants/${item._id}`}
                              >
                                <strong>{item.restaurantName}</strong>
                              </Link>
                              <Link 
                                style={{ fontSize: '10px', marginLeft: '5px', marginBottom: '5px' }}
                                onClick = {() => handleClickCounter(item._id)}
                                to={`/restaurants/${item._id}`}
                              >
                                {item.restaurantCity + ', ' + item.restaurantState}
                              </Link>
                              <div className="restaurant-tags fw-bold">
                                {item.isBoarded ? (
                                  <span 
                                    className="label fw-bold text-default" 
                                    style= {{
                                      background: '#FF7518',
                                      borderRadius: '16px',
                                      padding: '2px 12px',
                                      fontSize: '9px',
                                      fontFamily: 'Beanco',
                                      fontStyle: 'italic',
                                      marginLeft: '5px',
                                    }}
                                  > 
                                    {'#FEATURED'}
                                  </span>
                                ) : (
                                  <span 
                                    className="label fw-bold text-default" 
                                    style={{
                                      visibility: 'hidden',
                                      borderRadius: '16px',
                                      padding: '2px 12px',
                                      fontSize: '9px',
                                    }}
                                  > 
                                    {'#NONFEATURED'}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div style={{ position: 'absolute', bottom: '40px', right:'2px' }}>
                              <RestaurantCardFooter item={item} disableMoreDetails={true}/>
                            </div>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  ))}
                </Row>
              )}
            </SimpleBar>  
          </>
        )}
      </div>
    </>
  );
};

export default FavRestaurantCardsBody;
