import React, { useState } from "react";
import {
  Modal,
  Form,
  Button,
  Row,
  InputGroup,
} from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"

const backendUri = process.env.REACT_APP_BACKEND_DOMAIN;
const CreateMenuModal = (props) => {
  const [menuName, setMenuName] = useState("");
  const [menuFile, setMenuFile] = useState("");
  const [gotFile, setGotFile] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const formData = new FormData();

    formData.append('menuName', menuName);
    formData.append('gotFile', gotFile);
    if (menuFile) {
      formData.append('file', menuFile[0]);
    }

    let isTimedOut = false;

    const timeout = setTimeout(() => {
      isTimedOut = true;
      setIsSubmitting(false);
      toast.error("Request timed out. Please try again later.", {
        containerId: "error-container",
      });
      props.handleCloseForm();
    }, 5000);

    fetch(`${backendUri}/api/v0/admin/menu`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${props.Token}`,
      },
      body: formData
    })
      .then((res) => {
        clearTimeout(timeout);
        return res.json();
      })
      .then((res) => {
        console.log(res.error);
        if (res.error) {
          toast.error(res.message, {
            containerId: "error-container",
          });
        } else {
          toast.success(res.message, {
            containerId: "success-container",
          });

          setMenuName("");
          setMenuFile(null);
          setGotFile(false);

          props.fetchData(props.Token);
          props.handleCloseForm();
        }
      })
      .catch((error) => {
        // Handle fetch errors
        console.error("Fetch error:", error);
        toast.error("An error occurred while processing your request.", {
          containerId: "error-container",
        });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <>
      <Modal show={props.showForm} onHide={props.handleCloseForm} size="lg">
        <Modal.Header closeButton>
          <Modal.Title> Create New Menu </Modal.Title>
        </Modal.Header>
        <Form style={{ margin:3}} onSubmit={(e) => handleSubmit(e)}>
          <Modal.Body>
            <Row className="mb-3">
              <Form.Group controlId="addMenuName">
                <Form.Label> Menu Name* </Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <i className="fa fa-tag" />
                  </InputGroup.Text>
                  <Form.Control 
                    required
                    type="text"
                    placeholder="Enter Menu Name"
                    value={menuName}
                    onChange={(e) => {
                      setMenuName(e.target.value);
                    }}
                  />
                </InputGroup>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group controlId="addMenuFile">
                <Form.Label> Menu Image (Accepted file format : .pdf .jpeg .jpg .png .svg .gif) </Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <i className="fa fa-file" />
                  </InputGroup.Text>
                  <Form.Control
                    type="file"
                    accept="image/*, .pdf"
                    onChange={(e) => {
                      if (e.target.files.length > 0) {
                        setMenuFile(e.target.files);
                        setGotFile(true);
                      } else {
                        setMenuFile("");
                        setGotFile(false);
                      }
                    }}
                  />
                </InputGroup>
              </Form.Group>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="soft-primary" type="submit" id="addMenuSubmitBtn" disabled={isSubmitting}>
              {isSubmitting ? (
                <>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  <span className="visually-hidden">Submitting...</span>
                </>
              ) : (
                "Submit"
              )}
            </Button>
            <Button variant="soft-secondary" onClick={props.handleCloseForm} id="createCloseBtn">
              Close
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
};

export default CreateMenuModal;