import React, { useState } from "react";
import {
  Modal,
  Form,
  Button,
  Row,
  InputGroup,
} from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"


const backendUri = process.env.REACT_APP_BACKEND_DOMAIN;
const CreatePackageModal = (props) => {
  const [packageName, setPackageName] = useState("");
  const [packagePrice, setPackagePrice] = useState("");
  const [packageImage, setPackageImage] = useState(null);
  const [gotFile, setGotFile] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [depositRequired, setDepositRequired] = useState(false);
  const [depositAmount, setDepositAmount] = useState("");
  
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const formData = new FormData();

    formData.append('packageName', packageName);
    formData.append('packagePrice', packagePrice);
    formData.append('gotFile', gotFile);
    formData.append('depositRequired', depositRequired);
    formData.append('depositAmount', depositAmount);
    if (gotFile === true) {
      formData.append('file', packageImage[0]);
    }

    let isTimedOut = false;

    const timeout = setTimeout(() => {
      isTimedOut = true;
      setIsSubmitting(false);
      toast.error("Request timed out. Please try again later.", {
        containerId: "error-container",
      });
      props.handleCloseForm();
    }, 5000);

    fetch(`${backendUri}/api/v0/admin/packages`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${props.Token}`,
      },
      body: formData
    })
      .then((res) => {
        clearTimeout(timeout);
        return res.json();
      })
      .then((res) => {
        console.log(res.error);
        if (res.error) {
          toast.error(res.message, {
            containerId: "error-container",
          });
        } else {
          toast.success(res.message, {
            containerId: "success-container",
          });

          setPackageName("");
          setPackagePrice("");
          setPackageImage(null);
          setGotFile(false);

          props.fetchData(props.Token);
          props.handleCloseForm();
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error("An error occurred while processing your request.", {
          containerId: "error-container",
        });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <>
      <Modal show={props.showForm} onHide={props.handleCloseForm} size="lg">
        <Modal.Header closeButton>
          <Modal.Title> Create New Package </Modal.Title>
        </Modal.Header>
        <Form style={{ margin:3}} onSubmit={(e) => handleSubmit(e)}>
          <Modal.Body>
            <Row className="mb-3">
              <Form.Group controlId="addPackageName">
                <Form.Label> Package Name* </Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <i className="fa fa-tag" />
                  </InputGroup.Text>
                  <Form.Control 
                    required
                    type="text"
                    placeholder="Enter Package Name"
                    value={packageName}
                    onChange={(e) => {
                      setPackageName(e.target.value);
                    }}
                  />
                </InputGroup>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group controlId="addPackagePrice">
                <Form.Label> Package Price* </Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    RM
                  </InputGroup.Text>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Enter Package Price"
                    value={packagePrice}
                    onChange={(e) => {
                      setPackagePrice(e.target.value);
                    }}
                  />
                </InputGroup>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group controlId="addPackageImage">
                <Form.Label> Package Image </Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <i className="fa fa-image" />
                  </InputGroup.Text>
                  <Form.Control
                    type="file"
                    accept="image/*"
                    onChange={(e) => {
                      if (e.target.files.length > 0) {
                        setPackageImage(e.target.files);
                        setGotFile(true);
                      } else {
                        setPackageImage(null);
                        setGotFile(false);
                      }
                    }}
                  />
                </InputGroup>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group controlId="addDepositRequired">
                <Form.Check 
                  type="checkbox"
                  label="Deposit Required"
                  checked={depositRequired}
                  onChange={(e) => setDepositRequired(e.target.checked)}
                />
              </Form.Group>
              {depositRequired && (
                <Form.Group controlId="addDepositAmount">
                  <Form.Label>Deposit Amount</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      RM
                    </InputGroup.Text>
                    <Form.Control 
                      required
                      type="text"
                      placeholder="Enter Package Deposit Amount"
                      value={depositAmount}
                      onChange={(e) => {
                        setDepositAmount(e.target.value);
                      }}
                    />
                  </InputGroup>
                </Form.Group>
              )}
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="soft-primary" type="submit" id="addPackageSubmitBtn" disabled={isSubmitting}>
              {isSubmitting ? (
                <>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  <span className="visually-hidden">Submitting...</span>
                </>
              ) : (
                "Submit"
              )}
            </Button>
            <Button variant="soft-secondary" onClick={props.handleCloseForm} id="createCloseBtn">
              Close
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}

export default CreatePackageModal;